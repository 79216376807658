import React, { Component } from "react";
// images
import article2 from "../../../img/articles/article2_slide2_main.jpg";
import article1 from "../../../img/articles/article1_slide2.jpg";
import { Link } from "react-router-dom";
import Nav from "../../components/nav";

import twitter_icon from "../../../img/twitter_icon.png";
import facebook_icon from "../../../img/facebook_icon.png";
import Footer from "../../components/Footer";
import logo from "../../../img/logo.png";

class Article2 extends Component {
 state = {};
 render() {
  window.scrollTo(0, 0);
  return (
   <>
    <Nav />
    <div className="article-parent">
     <div className="article-header position-relative">
      <img
       src={article2}
       alt=""
       className="position-absolute h-100 w-100 article-header-image"
      />
      <div className="container d-flex flex-column flex-fill justify-content-between h-100 text-white">
       <div className="w-100 text-center mt-5">
        <img src={logo} alt="" className="main-logo" />
       </div>

       <div>
        <h1 className="font-weight-bold">What kind of spender are you?</h1>

        <div className="text-white align-items-center mb-5">
         <div className="font-weight-normal d-flex align-items-center flex-row">
          <small className="font-weight-normal">23 March 2019</small>{" "}
          <div className="d-inline-block mx-2">|</div>
          <small className="font-weight-normal">Share</small>
          <div className="d-flex flex-row ml-2">
           <a href="https://twitter.com/AmanaCapitalKE" className="mr-3">
            <img src={twitter_icon} alt="" className="social-icon" />
           </a>

           <a
            href="https://www.facebook.com/amanacapital2003/"
            className="mr-3"
           >
            <img src={facebook_icon} alt="" className="social-icon" />
           </a>
          </div>
         </div>
        </div>
       </div>
      </div>
     </div>

     {/* content */}

     <div className="container pb-5">
      <div className="text-muted mt-3 mb-5">
       <small>Home / Blog / What kind of spender are you?</small>
      </div>

      <div className="row">
       <div className="col-md-9">
        <div className="text-dark article-text">
         <p>
          In Step 2 of the 7 Steps to wealth you will learn how you can raise
          some quick extra cash to put towards growing your savings pot by
          getting rid of any unnecessary items in your home.
         </p>
         <p>
          This however, is but one side of the raising chumz equation. The other
          side involves learning how to control your spending patterns on a day
          to day basis. Learning how to control these spending patterns depends
          upon understanding what type of a spender you are. Once you understand
          what type of a spender you are, controlling how and what you spend
          your money on becomes much much easier.
         </p>

         <b className="mb-2 d-block">Type 1: The Consolation Spender</b>
         <p>
          Life can be tough, we all know this, and as a result we all seek ways
          to make the journey a little bit easier. One of the most common,
          though unrecognized, ways that we do this is by consolation spending.
          This means simply buying things in order to make ourselves feel better
          in and about ourselves in the moment. We have all done it many times,
          be it a pair of shoes, a treat to eat or even a weekend away. Whilst
          these expenditures do make us feel better about ourselves in the short
          run, they actually move us backwards in the journey towards long-term
          fulfillment.
         </p>
         <p>
          If you recognize yourself to be a Consloation Spender, the way to
          alter your spending habits is to practice focusing on your long term
          goals, and to continually remind yourself of what and what you are
          saving for. If you remind yourself of this every time that you feel
          the urge to go out and buy something to make yourself feel better, you
          will find with time that the act of focusing on your savings goals
          itself will make you feel better about yourself than any pair of
          shoes, sugary treat or weekend away ever could.
         </p>
         <b className="mb-2 d-block">Type 2: The Deal Hunter</b>
         <p>
          Marketers are the masters of convincing us to purchase products that
          we don’t really need by positioning them to us as discounted or on
          offer. This tactic preys on our deep seated fear of missing out on a
          good deal. Now sometimes the deal on offer is a good deal, if it is
          90% off then yes, that is a lot cheaper than you could normally buy it
          for.
         </p>
         <p>
          However, even if the product is priced at 99% off, the fact that it is
          99% off is not a good reason to buy it. The only good reason to buy it
          is because you actually really need it. If you are someone who is
          allways on the look out for the latest best deal in the shops or the
          market, you need to try to change your thinking to focus instead on
          being on the look out for the best deals only on the things that you
          actually need. You need to learn to ask yourself not “How good a deal
          can I get on this” but rather “how much do I really need this?” You
          will be surprised at how often the answer to this last question is no,
          regardless of the discount.
         </p>
         <b className="mb-2 d-block">Type 3: The Life-ist</b>
         <p>
          We all know people like this, and we are all guilty of it in our own
          lives – the temptation to spend money to try and keep up with the
          lifestyle of the people around us, our family, friends and work
          colleagues. This desire to maintain a lifestyle drives us to make
          foolish spending decisions on a regular basis, from the car we drive
          to the phone we use to what we drink when we go out to where we choose
          to live. Whilst you should not deny yourself the pleasures of life
          that you can afford, you must remember that the people whose lifestyle
          you are trying to match will continue with their lifestyle when you
          have long since run out of money.
         </p>
         <p>
          It is therefore very important that you keep it real with yourself
          about the lifestyle that you can actually afford whilst at the same
          time saving enough to ensure that your lifestyle improves gradually
          over time. You must be honest with yourself and understand also that
          your measure as a man or a woman is not based on how much money you
          can spend, where you buy your clothes or where you go on holiday, but
          rather on your ability to maintain the discipline and self-honesty
          required to build your wealth step by step over time.
         </p>

         <b className="mb-2 d-block">Type 4: The FOMO-holic</b>
         <p>
          Similar to the Life-ist, the FOMO-holic is one hundred percent driven
          by peer pressure and the fear of social exclusion. This drives him or
          her to spend money that they can’t really afford to in order to
          maintain their position in or membership of a particular social group.
          Again, we have all been guilty of this, attending functions and going
          on trips and spending money just so that we can be with the boys or
          the girls.
         </p>
         <p>
          Whilst friendship is real, you must also remember that at the end of
          the day only you are responsible for your future financial security,
          and the friends whose company you are so scared of missing out on will
          not be able to, nor should they have to, sort out your finances for
          you if you have not sorted them out for yourself. When you sense peer
          pressure to spend money you must be an immovable object, focused
          firmly on your future. Your true friends will be with you all the way
          to the end of your journey anyway.
         </p>
         <b className="mb-2 d-block">Type 5: The Impulse Buyer</b>
         <p>
          Last but not least, this one is the most common of the lot. An impulse
          purchase is any purchase that you make that you had not planned to
          make, but rather have made purely because you liked the look, or
          thought, or smell, or taste of the product that you bought. From
          chocolates to handbags to soft furnishings, we have all made this kind
          of purchase, time and time and time again.
         </p>
         <p>
          Luckily, there is a very simple technique that you can use to
          completely eliminate impulse buying from your life – it’s called
          making a list! Before you ever go shopping, write down a list of
          exactly what you are going to buy, and when you get to the shops,
          don’t buy a single thing else. Stick to the list like glue, and watch
          your savings grow faster and faster and faster.
         </p>
        </div>
       </div>
       <div className="col-md-3">
        <b>Related Articles</b>

        <Link to="/slide2/article1">
         <div className="bg-light my-5 position-relative">
          <img src={article1} alt="" className="w-100" />
          <div className="red-text p-3">
           <small>7 tips to help you raise more cash</small>
          </div>
         </div>
        </Link>
       </div>
      </div>
     </div>
    </div>
    <Footer />
   </>
  );
 }
}

export default Article2;
