import React, { Component } from "react";
import logo from "../../img/logo.png";
import { Link } from "react-router-dom";

import carret_down from "../../img/icons/carret_down.svg";
import arrow_right from "../../img/arrow_right.png";
import arrow_left from "../../img/icons/arrow_left.svg";
import LoginModal from "../components/loginModal";

class S2 extends Component {
 state = {
  needs: {},
  wants: {},
  modalVisible: false,
  savings: window.calc.savings,
  future: 0
 };
 render() {
  return (
   <>
    <div
     className="d-flex flex-fill slide flex-column align-items-center justify-content-center step-1 default-bg"
     style={{}}
    >
     <LoginModal path="/slide2/step2" modalVisible={this.state.loginVisible} />

     <div className="d-flex flex-column align-items-center text-white text-center">
      <img src={logo} alt="" className="main-logo" />

      <div className="intro-savings mt-4">
       <h5>
        <b>
         To know how much you can earn, we first need to know how much you save
         a month
        </b>
       </h5>
       <div className="my-4">
        <input
         className="py-1 text-center text-bronze card-input d-flex flex-fill w-100 undefined"
         value={this.state.savings ? this.state.savings.toLocaleString() : ""}
         placeholder="Enter savings"
         onChange={event => {
          let savings = parseInt(event.target.value.replace(/,/g, ""));
          console.log(savings);
          if (!isNaN(savings)) {
           //    window.calc.savings = savings;
           this.setState({ savings: savings });
           setTimeout(() => {
            this.calculateFuture();
           }, 0);
          }
         }}
        />
       </div>
      </div>
      <div className="py-3 total-anim text-center text-gold position-relative">
       <div className="center_carret">
        <img src={carret_down} alt="" />
       </div>
       <h3> {this.state.future.toLocaleString()} </h3>
       <small className="text-white">
        This is what your Savings will earn you in 5 years.
       </small>
      </div>

      <div className="mt-3 bottom-text">
       <small>Choose an option below to earn more from your savings</small>
      </div>

      <div className="mt-5">
       <div className="d-md-flex d-none flex-row flex-wrap align-items-center">
        <div className="">
         <Link
          to="/slide2/step2_5"
          className="btn bg-white py-2 px-3  mx-2 d-inline-block d-flex flex-row align-items-center justify-content-between  btn-next"
         >
          <img src={arrow_left} alt="" className="mr-2 opacity-0" />
          <small className="text-gold text-sm text-uppercase">
           <b>LEARN TO SPEND</b>
          </small>
          <div />
         </Link>
        </div>
        <div className="">
         <Link
          className="btn bg-gold py-2 px-3  mx-2 d-inline-block d-flex flex-row align-items-center justify-content-between  btn-next"
          to="/slide2/step3"
         >
          <div />
          <small className="text-white text-sm ">
           <b>LEARN TO SELL</b>
          </small>
          <img src={arrow_right} alt="" className="ml-2 opacity-0" />
         </Link>
        </div>
       </div>
      </div>
     </div>
    </div>
    <div className="navigation-buttons d-md-none d-flex flex-row">
     <div className="">
      <Link className="btn btn-sm-back " to="/slide2/step2_5">
       <small className="text-white text-uppercase font-weight-bold">
        LEARN TO SPEND
       </small>
      </Link>
     </div>
     <div className="d-flex flex-fill ">
      <Link className="btn btn-sm-next " to="/slide2/step3">
       <small className="text-white text-uppercase">
        <b>LEARN TO SELL</b>
       </small>
      </Link>
     </div>
    </div>
   </>
  );
 }

 componentDidMount() {
  this.calculateFuture();
 }

 componentWillUnmount() {
  window.calc.savings = this.state.savings;
  window.calc.future = this.state.future;
  console.log("unmounting ", window.calc.savings, window.calc.future);
 }

 calculateFuture() {
  // y = 12;
  let p = this.state.savings;
  let r = 0.12;
  let i = 1 + r / 12;

  let G = (i * (Math.pow(i, 60) - 1)) / (i - 1);

  this.setState({ future: parseInt(G * p) });
 }
}

export default S2;
