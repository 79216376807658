import React, { Component } from "react";
import logo from "../../img/logo.png";
import Card from "../components/card";
import Button from "../components/button";
import playIcon from "../../img/play_icon.png";
import indicator from "../../img/indicator.png";
import { Link } from "react-router-dom";
import back_icon from "../../img/icon_left.png";
import Modal from "../components/modal";
import VideoModal from "../components/videoModal";
import ButtonSm from "../components/buttonSm";

class S1 extends Component {
 state = { needs: {}, wants: {}, modalVisible: false };
 render() {
  return (
   <>
    <div className="d-flex flex-fill slide flex-column align-items-center justify-content-center step-1 step1-bg">
     <VideoModal modalVisible={this.state.modalVisible} link="iKMHB7w_BLU" />
     <div className="d-flex flex-column align-items-center text-white text-center">
      <img src={logo} alt="" className="main-logo" />
      <div className="mt-4 mb-3 position-relative">
       <div className="d-inline-block text-gold indicator-text">STEP 03</div>
       <img src={indicator} className="indicator" alt="" />
      </div>

      <div className="intro-text mt-1">
       <h3 className="text-center">
        <b className="title-text tf intro-title">
         Turn your dream into a financial goal
        </b>
       </h3>
       <div className="mt-3 intro-text px-0">
        <span>
         <span className="skip-text">
          Its great to have a goal to work towards but do you know how much you
          need to save to achieve it ?
         </span>
        </span>
       </div>
      </div>

      <div className="mt-4 play-next">
       <Link
        to="/slide3/video"
        className="btn d-flex flex-row align-items-center"
        onClick={() => this.setState({ modalVisible: true })}
       >
        <img src={playIcon} className="play-icon" alt="" />
        <small className="font-weight-bold ml-2 text-white">
         Watch the lesson
        </small>
       </Link>
      </div>
      <div className="mt-5">
       <Button back={false} to="/slide3/step2/register" />
      </div>
     </div>
    </div>
    <ButtonSm back={false} to="/slide3/step2/register" />
   </>
  );
 }

 componentDidMount() {
  window.scrollTo(0, 0);
 }
}

export default S1;
