import React, { Component } from "react";
import Button from "../components/button";
import logo from "../../img/logo.png";
import Modal from "../components/modal";
import carret_down from "../../img/icons/carret_down.svg";

import { Link } from "react-router-dom";
import Counter from "../components/counter";

import hex1 from "../../img/hex1.png";
import hex2 from "../../img/hex2.png";

import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  TelegramShareButton,
  WhatsappShareButton
} from "react-share";

import {
  FacebookIcon,
  TwitterIcon,
  TelegramIcon,
  WhatsappIcon
} from "react-share";
import Lessons7 from "../components/lessons7";

class S5 extends Component {
  state = { phoneNumber: "", shareModalVisible: false };
  render() {
    window.scroll4();
    // update database
    window.updatePersistence();
    let shareUrl = "https://7steps.amanacapital.co.ke";
    return (
      <>
        <div className="slide d-flex flex-fill flex-column text-white align-items-center justify-content-center step-1 default-bg pt-3">
          <Modal show={this.state.modalVisible}>
            <div className="hover-card-center position-relative text-white">
              <div
                className={
                  "layer2 px-4 py-5 " +
                  (this.state.disabled ? "disable-card" : "")
                }>
                <i
                  class="fas fa-times text-white position-absolute hover-card-clear"
                  onClick={() => this.setState({ modalVisible: false })}
                />
                <small className="d-block">
                  <b className="d-block text-white">
                    BOOK A FREE CONSULTATION WITH A WEALTH EXPERT
                  </b>
                  No sales pitch , no strings attached, just good and honest
                  advice.
                </small>

                <input
                  type="text"
                  className="py-1 my-3 text-center text-bronze card-input d-flex flex-fill w-100 "
                  placeholder="Enter your phone number"
                  value={this.state.phoneNumber}
                  onChange={evt =>
                    this.setState({ phoneNumber: evt.target.value })
                  }
                />

                <button
                  className="btn w-100 text-center w-100 btn-primary"
                  onClick={() => {
                    this.send();
                  }}>
                  <small className="font-weight-bold text-white">
                    Let's talk
                  </small>
                </button>
              </div>
            </div>
          </Modal>

          <Modal show={this.state.shareModalVisible}>
            <div className="hover-card-center position-relative text-white">
              <i
                class="fas fa-times text-white position-absolute hover-card-clear"
                onClick={() => this.setState({ shareModalVisible: false })}
              />
              <FacebookShareButton
                url={shareUrl}
                className="m-2 cursor-pointer">
                <div className="btn pr-5 pl-2 d-flex flex-row align-items-center">
                  <FacebookIcon size={40}></FacebookIcon>
                  <div className="ml-3 text-white">Facebook</div>
                </div>
              </FacebookShareButton>

              <TwitterShareButton url={shareUrl} className="m-2 cursor-pointer">
                <div className="btn pr-5 pl-2 d-flex flex-row align-items-center">
                  <TwitterIcon size={40}></TwitterIcon>
                  <div className="ml-3 text-white">Twitter</div>
                </div>
              </TwitterShareButton>

              <WhatsappShareButton
                url={shareUrl}
                className="m-2 cursor-pointer">
                <div className="btn pr-5 pl-2 d-flex flex-row align-items-center">
                  <WhatsappIcon size={40}></WhatsappIcon>
                  <div className="ml-3 text-white">Whatsapp</div>
                </div>
              </WhatsappShareButton>

              <TelegramShareButton
                url={shareUrl}
                className="m-2 cursor-pointer">
                <div className="btn pr-5 pl-2 d-flex flex-row align-items-center">
                  <TelegramIcon size={40}></TelegramIcon>
                  <div className="ml-3 text-white"> Telegram</div>
                </div>
              </TelegramShareButton>
            </div>
          </Modal>

          <div className="p-3 text-center">
            <img src={logo} className="main-logo " alt="" />

            <div className="my-4 mt-md-5 desc-text">
              <h5 className="lts font-weight-bold">
                Congratulations You have finished the 7 steps to wealth!
              </h5>
              <span className="text-white mx-3 d-inline-block mt-2">
                Now that you are done. Its time to talk to an expert and share
                your knowledge
              </span>
            </div>

            <div className="d-flex flex-row justify-content-center mt-5">
              <div
                className="mr-2 cursor-pointer d-flex text-center align-items-center justify-content-center"
                onClick={() => this.setState({ modalVisible: true })}>
                <div className="position-absolute d-flex text-white">
                  Talk to an
                  <br /> Advisor
                </div>
                <img src={hex1} alt="" />
              </div>

              <div
                to=""
                className="ml-2 cursor-pointer d-flex text-center align-items-center justify-content-center"
                onClick={() => this.setState({ shareModalVisible: true })}>
                <div className="position-absolute d-flex text-white">
                  Share <br /> with a <br /> friend
                </div>
                <img src={hex2} alt="" />
              </div>
            </div>
          </div>

          <div className="flex-column justify-content-center d-flex mt-5">
            <small className="text-primary mb-2 font-weight-bold text-sm">
              LEARN MORE
            </small>
            <img src={carret_down} alt="" />
          </div>
        </div>
        <Lessons7 />
      </>
    );
  }

  getBalance = () => {
    let { calc } = window;
    let total = calc.earnings - (calc.needs + calc.wants);
    return total;
  };

  calculateFuture(y) {
    let p = this.getBalance();
    let r = 0.12;
    let i = 1 + r / 12;

    let G = (i * (Math.pow(i, y) - 1)) / (i - 1);

    return parseInt(G * p);
  }

  send = () => {
    if (this.state.phoneNumber === "") {
      alert("please enter a Phone number to proceed");
      return false;
    }
    this.setState({ disabled: true });

    fetch(window.server + "saveRequest.php", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/x-www-form-urlencoded"
      },
      body: `user=${localStorage.id}&phone=${this.state.phoneNumber}`
    })
      .then(response => response.text())
      .then(response => {
        this.setState({ disabled: false, modalVisible: false });
        console.log(response);
        // this.props.history.push("/slide1/thankyou");
        window.location = "/page/thankyou.html";
      });
  };
}

export default S5;
