import React, { Component } from "react";
// images
import article2 from "../../../img/articles/slide3_article2_small.jpg";
import article1 from "../../../img/articles/slide3_article1.jpg";
import { Link } from "react-router-dom";
import Nav from "../../components/nav";

import twitter_icon from "../../../img/twitter_icon.png";
import facebook_icon from "../../../img/facebook_icon.png";
import Footer from "../../components/Footer";
import logo from "../../../img/logo.png";

class Article1 extends Component {
  state = {};
  render() {
    window.scrollTo(0, 0);
    return (
      <>
        <Nav />
        <div className="article-parent">
          <div className="article-header position-relative">
            <img
              src={article1}
              alt=""
              className="position-absolute h-100 w-100 article-header-image"
            />
            <div className="container d-flex flex-column flex-fill justify-content-between h-100 text-white">
              <div className="w-100 text-center mt-5">
                <img src={logo} alt="" className="main-logo" />
              </div>

              <div>
                <h1 className="font-weight-bold">
                  Weighing your savings options
                </h1>

                <div className="text-white align-items-center mb-5">
                  <div className="font-weight-normal d-flex align-items-center flex-row">
                    <small className="font-weight-normal">23 March 2019</small>
                    <div className="d-inline-block mx-2">|</div>
                    <small className="font-weight-normal">Share</small>
                    <div className="d-flex flex-row ml-2">
                      <a
                        href="https://twitter.com/AmanaCapitalKE"
                        className="mr-3">
                        <img
                          src={twitter_icon}
                          alt=""
                          className="social-icon"
                        />
                      </a>

                      <a
                        href="https://www.facebook.com/amanacapital2003/"
                        className="mr-3">
                        <img
                          src={facebook_icon}
                          alt=""
                          className="social-icon"
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* content */}

          <div className="container pb-5">
            <div className="text-muted mt-3 mb-5">
              <small>Home / Blog / Weighing your savings options</small>
            </div>

            <div className="row">
              <div className="col-md-9">
                <div className="text-dark article-text">
                  <p>
                    In Step 4 of the 7 Steps to Wealth, we take an in-depth look
                    at the various common savings options available to Kenyans.
                    Each of these options carry within them various elements of
                    both risk and reward. In this Step we shall learn how to
                    balance these two crucial elements so as to arrive at a
                    savings plan for ourselves that best fits our particular
                    goals, financial status, life stage and timeline.
                  </p>
                  <b className="mb-2 d-block">1. A Savings Account</b>

                  <p>
                    This is of course the most common and frequently used
                    savings option for most Kenyans. A savings account offers
                    two key benefits to the saver. Firstly, though with a few
                    exceptions, putting your money into a bank savings account
                    is by and large a very safe option for your money. Unless
                    the bank collapses, i.e. unless you have put your money into
                    a bank which rests on shaky ground, you are pretty much
                    guaranteed that your money will be there when you need it.
                    Secondly, with your money in a savings account you are
                    guaranteed a steady rate of return on your money by virtue
                    of the interest that you are earning.
                  </p>
                  <p>
                    Balanced against this security and stability however one
                    must weigh the fact that the interest rates in Kenya at
                    present, especially under the terms of the interest rate
                    cap, are not as healthy as they may at first look. Remember
                    that the true interest rate, or rather your true rate of
                    return, is how much your money has grown after inflation. It
                    is all well and good to earn 6% interest on your savings,
                    but if inflation was 5% for the year, meaning that the total
                    amount that you could purchase with your money has fallen by
                    5% over the course of the year as prices have gone up, your
                    actual growth in your money is just 1%. At the low a level
                    of “real” returns, it is unlikely that you will achieve your
                    savings goals in this lifetime.
                  </p>
                  <b className="mb-2 d-block">2. Land</b>
                  <p>
                    One of the most popular “savings” options for Kenyans is to
                    put our surplus income into the purchase of land. There is a
                    lot of obvious logic to this decision. Kenya is a growing
                    economy with a growing population, meaning an ever
                    increasing demand for land meaning a steadily increasing
                    price of land.
                  </p>
                  <p>
                    However, there are many other factors that affect the future
                    price of land. We have all seen over the last few years that
                    there are an increasing number of areas, in Nairobi in
                    particular, where land and house price are beginning to
                    stagnate, if not drop. Land and real estate prices have a
                    tendency globally to be subject to bubbles, which always
                    eventually burst, and Kenya is not immune to this truth.
                  </p>
                  <p>
                    Furthermore, there are plenty of legal and regulatory issues
                    around land which can make what seems like a simple
                    transaction turn into a major legal headache. Deep due
                    diligence before a purchase is key to ensuring the
                    “cleanliness” of the deal/title, brokers abound, and cases
                    of fraud involving land transactions are sadly all too
                    common.
                  </p>
                  <p>
                    Finally, the most important risk to bear in mind when
                    considering land as a savings vehicle is the issue of
                    liquidity, or lack thereof. It is all well and good having a
                    plot of land valued at Kshs. 10 Million, but if you suddenly
                    need to access that money, first of all there is absolutely
                    no guarantee that you will find a buyer at the price you
                    want, in the time you want, or conclude the transaction as
                    quickly as you want – often land transactions in Kenya can
                    take up to 9 good months. Buyer beware.
                  </p>
                  <b className="mb-2 d-block">3. Setting up a business</b>
                  <p>
                    A standard operating procedure for many Kenyans when they
                    want to put their savings into an income earning activity is
                    to set up a business or “side hustle”. This can be anything
                    from importing perfumes to “telephone farming” to opening a
                    shop, hair salon or fast food job.
                  </p>
                  <p>
                    Whilst the entrepreneurial spirit is to be encouraged and
                    respected, the facts of business need to be viewed with very
                    clear eyes. More new business fail than succeed. This is not
                    necessarily an indictment upon the business owner – there
                    are many reason why a business can fail that are beyond the
                    control of the owner, regardless of how hard he or she works
                    or how dedicated he or she is to it’s success.
                  </p>
                  <p>
                    The most common mistake most people make when setting up a
                    business is under-estimating the amount of cash that they
                    will have to put into it, and over-estimating both how much
                    and how quickly it will start earning for them. This
                    inevitably results in people having to dig into their “own
                    pocket”, pulling funds from other personal requirements, to
                    keep the business afloat. Often the longer that this goes on
                    the harder it becomes for us to accept the reality that our
                    business may not be going in the way that we want it to.
                    Yes, opening your own business could lead to fame and
                    fortune, but be aware that the one thing that is guaranteed
                    is that it is going to cost you a lot more, and take a lot
                    longer, than you may think at first.
                  </p>
                  <b className="mb-2 d-block">4. Shares</b>
                  <p>
                    As the Nairobi Stock Exchange matures, so does our
                    understanding as investing Kenyans of the reality of stock
                    markets everywhere – upward movement is nowhere guaranteed.
                  </p>
                  <p>
                    The history of stock markets the world over is one of boom
                    and bust. This is because stock markets are the produce of
                    human emotions fundamentally – people get both over-excited
                    and over-despondent, meaning that prices of stocks can go
                    both way above and way below their true value.
                  </p>
                  <p>
                    Investing in blue chip stocks is generally a safe idea for
                    the long run, but investing in a share due to rumors on the
                    streets that it is going to quadruple in value over the next
                    12 months is generally a fool’s game. Stock investing is a
                    professional enterprise that requires both access to
                    detailed information about the companies, their markets and
                    the wider economy, as well as the tools and skills required
                    to pass accurate judgment on the true or fair price of a
                    share. You may seek to pass these judgments in your personal
                    capacity, which is very risky, or you can entrust them to
                    your stock broker, which is costly. All in all shares are a
                    usefull component of a long term savings strategy, but what
                    they will almost certainly never be is a get rich quick
                    scheme.
                  </p>
                </div>
              </div>
              <div className="col-md-3">
                <b>Related Articles</b>

                <Link to="/slide4/article2">
                  <div className="bg-light my-5 position-relative">
                    <img src={article2} alt="" className="w-100" />
                    <div className="red-text p-3">
                      <small>
                        The 7 Rules of choosing your savings options
                      </small>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

export default Article1;
