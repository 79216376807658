import React, { Component } from "react";
// images
import article2 from "../../../img/articles/slide3_article2_small.jpg";
import article1 from "../../../img/articles/slide3_article1.jpg";
import { Link } from "react-router-dom";
import Nav from "../../components/nav";

import twitter_icon from "../../../img/twitter_icon.png";
import facebook_icon from "../../../img/facebook_icon.png";
import Footer from "../../components/Footer";
import logo from "../../../img/logo.png";

class Article1 extends Component {
 state = {};
 render() {
  window.scrollTo(0, 0);
  return (
   <>
    <Nav />
    <div className="article-parent">
     <div className="article-header position-relative">
      <img
       src={article1}
       alt=""
       className="position-absolute h-100 w-100 article-header-image"
      />
      <div className="container d-flex flex-column flex-fill justify-content-between h-100 text-white">
       <div className="w-100 text-center mt-5">
        <img src={logo} alt="" className="main-logo" />
       </div>

       <div>
        <h1 className="font-weight-bold">Set your Goals</h1>

        <div className="text-white align-items-center mb-5">
         <div className="font-weight-normal d-flex align-items-center flex-row">
          <small className="font-weight-normal">23 March 2019</small>
          <div className="d-inline-block mx-2">|</div>
          <small className="font-weight-normal">Share</small>
          <div className="d-flex flex-row ml-2">
           <a href="https://twitter.com/AmanaCapitalKE" className="mr-3">
            <img src={twitter_icon} alt="" className="social-icon" />
           </a>

           <a
            href="https://www.facebook.com/amanacapital2003/"
            className="mr-3"
           >
            <img src={facebook_icon} alt="" className="social-icon" />
           </a>
          </div>
         </div>
        </div>
       </div>
      </div>
     </div>

     {/* content */}

     <div className="container pb-5">
      <div className="text-muted mt-3 mb-5">
       <small>Home / Blog / Set your Goals</small>
      </div>

      <div className="row">
       <div className="col-md-9">
        <div className="text-dark article-text">
         <p>
          So far in the 7 Steps to Wealth, we have learned how to make and stick
          to a monthly spending and savings budget, as well as how to raise
          extra money towards our savings, both by turning our unused items into
          cash and by applying useful savings strategies to our daily financial
          lives.
         </p>
         <p>
          We now have to address the key question in all our financial lives:
          what are we saving for?
         </p>
         <p>
          In Step 3 of the 7 Steps to Wealth, you will learn how to turn your
          life dreams and goals into a clear monthly financial savings goal. At
          the heart of this step is the understanding that there is a financial
          element to every life dream that we have, and that the only way to
          turn your life dream into reality is to address the financial
          consequences of that dream.
         </p>
         <p>
          Especially when we are younger, we tend to have clear aspirations
          regarding the life that we want to be living later on in our lives. We
          know, or have and idea, as to where we ant to live, what sort of house
          we shall have, what car we shall drive, how big our family will be,
          where our children shall go to school and so on and so forth.
         </p>
         <p>
          For those of us more advanced in years, our future financial plans are
          often linked to major costs such as educating our children and
          planning for an adequate income to sustain us in our old age.
         </p>
         <p>
          There is no right or wrong to the dreams that we have for ourselves,
          for everyone it is different, and each of us has a different vision of
          what constitutes financial freedom and independence. The key to Step 3
          therefore is to figure out what we have to do today in order to
          achieve our desired life tomorrow.
         </p>
         <p>
          The best way to set your monthly financial savings goals in the
          present is to ask yourself a very simple question: how much will the
          life that I wish to live in the future cost me? Imagine yourself 20
          years in the future, for example. Work out for yourself how much your
          dream home will cost to build or buy come then. Ask yourself how much
          money you would like to or need to be earning each month in order to
          sustain your desired lifestyle. Ask yourself how much money you will
          need to put aside each month towards education fees, if you have or
          plan to have children. Ask yourself how you envision earning your
          money each month, what assets will you have or business will you do
          that generates a steady and reliable income for you? Go into as much
          detail as you can, how often would you like to go on holiday, and
          where, and how much will that cost you again for example.
         </p>
        </div>
       </div>
       <div className="col-md-3">
        <b>Related Articles</b>

        <Link to="/slide3/article2">
         <div className="bg-light my-5 position-relative">
          <img src={article2} alt="" className="w-100" />
          <div className="red-text p-3">
           <small>Breaking down your financial goals?</small>
          </div>
         </div>
        </Link>
       </div>
      </div>
     </div>
    </div>
    <Footer />
   </>
  );
 }
}

export default Article1;
