import React, { Component } from "react";
import logo from "../../img/logo.png";
import { Link } from "react-router-dom";

import bg_ring from "../../img/icons/Results/bg_ring.svg";
import arrow from "../../img/icons/carret_up.svg";
import LoginModal from "../components/loginModal";

class S2 extends Component {
  state = {
    options: window.slide3.options
  };
  render() {
    return (
      <>
        <div
          className="d-flex flex-fill slide flex-column align-items-center justify-content-center step-1 default-bg"
          style={{}}>
          <LoginModal
            path="/slide3/step2"
            modalVisible={this.state.loginVisible}
          />

          <div className="d-flex flex-column align-items-center text-white text-center">
            <img src={logo} alt="" className="main-logo" />

            <div className="mt-4 mt-md-5">
              <h5 className="lts font-weight-bold">Choose your goal</h5>
              <span className="text-white px-3 d-inline-block desc-text">
                Choose from the options below
              </span>
            </div>

            <div className="habits container d-flex flex-row flex-wrap justify-content-around my-4 my-md5">
              {this.state.options.map((d, i) => (
                <Link
                  to="/slide3/step2_5"
                  className="postion-relative my-3 mx-1 mx-md-3"
                  onClick={() => {
                    window.slide3.active = i;
                    localStorage.slide3 = JSON.stringify(window.slide3);
                  }}>
                  <div className="d-flex justify-content-center flex-column align-items-center position-relative">
                    <img
                      src={bg_ring}
                      alt=""
                      className="align-self-center text-gold"
                    />
                    <img
                      src={d.icon}
                      alt=""
                      className="position-absolute align-self-center icon text-dark"
                    />
                  </div>
                  <div className=" habit-title text-center mt-3 text-white">
                    {d.name}
                  </div>
                </Link>
              ))}
            </div>

            <div className="flex-column justify-content-center d-flex mt-0">
              <img src={arrow} alt="" />
              <small className="mt-2 font-weight-bold text-sm">
                CHOOSE TO CONTINUE
              </small>
            </div>

            {/* <Button back={false} to="/slide3/step3" text="continue" /> */}
          </div>
        </div>
        {/* <ButtonSm back={false} to="/slide3/step3" text="continue" /> */}
      </>
    );
  }
}

export default S2;
