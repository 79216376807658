import React, { Component } from "react";

class Counter extends Component {
 state = {};
 render() {
  return (
   <div className="text-center text-gold mb-3 d-none">
    <b>
     STEP {this.props.current} OF {this.props.total}
    </b>
   </div>
  );
 }
}

export default Counter;
