import React, { Component } from "react";
import { Route, BrowserRouter as Router } from "react-router-dom";
import amana_logo from "../../img/amana-logo.png";
import Nav from "../components/nav";

import S1 from "./s1";
import S2 from "./s2";
import S3 from "./s3";
import S4 from "./s4";
import S5 from "./s5";
import S6 from "./s6";
// import Parent from "./parent";
import S7 from "./s7";
import S8 from "./s8";
import Article1 from "./articles/article1";
// import Article1 from "./articles/article1";
// import Article2 from "./articles/article2";

class Slide5Home extends Component {
  state = {};
  render() {
    return (
      <div>
        <Nav />
        <main className="main-page d-flex bg-dark flex-column">
          <div className="cover">
            <Route path="/(slide5|slide5/video)/" exact component={S1} />
            <Route path="/slide5/step2" component={S2} />
            <Route path="/slide5/step3" component={S3} />
            <Route path="/slide5/step4/:index" component={S4} />
            <Route path="/slide5/step5/:index" component={S5} />
            <Route path="/slide5/step6" component={S6} />
            <Route path="/slide5/step7" component={S7} />
            <Route path="/slide5/step8" component={S8} />
            <Route path="/slide5/article1" component={Article1} />
          </div>
        </main>
      </div>
    );
  }
}

export default Slide5Home;
