import React, { Component } from "react";

import Modal from "../components/modal";
import { Route, BrowserRouter as Router, Switch, Link } from "react-router-dom";
import ModalCard from "../components/modalCard";
import arrow_right from "../../img/arrow_right.png";

let path;
class LoginModal extends Component {
 state = { showModal: localStorage.id === undefined };

 render() {
  return (
   <Modal show={this.state.showModal}>
    <Route
     path={`${this.props.path}/login`}
     render={routeProps => (
      <Login
       {...routeProps}
       hideModal={() => this.setState({ showModal: false })}
      />
     )}
    />
    <Route
     path={`${this.props.path}/register`}
     render={routeProps => (
      <Register
       {...routeProps}
       hideModal={() => this.setState({ showModal: false })}
      />
     )}
    />
    <Route
     path={`${this.props.path}/forgotPassword`}
     component={ForgotPassword}
    />
    <Route
     path={`${this.props.path}/resetPassword`}
     component={ResetPassword}
    />
   </Modal>
  );
 }
 componentWillMount() {
  path = this.props.path;
 }

 componentWillReceiveProps(props) {
  this.setState({ modalVisible: props.modalVisible });
 }
}

export default LoginModal;

class Login extends Component {
 state = { email: window.tmpEmail ? window.tmpEmail : "", password: "" };
 render() {
  return (
   <ModalCard title="Hi There!" description="Were hapy to have you here again!">
    <div className={this.state.disabled ? "disabled" : ""}>
     <div className="my-2">
      <small className="text-sm input-label">E-MAIL ADDRESS</small>
      <div>
       <input
        type="email"
        className="text-sm p-2 border w-100 mt-1"
        value={this.state.email}
        onChange={e => {
         this.setState({ email: e.target.value });
        }}
       />
      </div>
     </div>

     <div className="my-2">
      <small className="text-sm input-label">PASSWORD</small>
      <div>
       <input
        type="password"
        className="text-sm p-2 border w-100 mt-1"
        onChange={e => {
         this.setState({ password: e.target.value });
        }}
        value={this.state.value}
       />
      </div>
     </div>
     <button
      onClick={this.login}
      className="btn btn-sm btn-primary my-3 p-2 px-3 w-100 d-flex flex-row align-items-center justify-content-between"
     >
      <div className="mx-2" />
      <div className="text-white text-">
       <b>LOGIN</b>
      </div>
      <img src={arrow_right} alt="" className="ml-1" />
     </button>
     <div className="text-center">
      <small className="d-block">
       Dont have an account yet? &nbsp;
       <Link to={`${path}/register`} className="text-primary">
        Register now
       </Link>
      </small>
      <div className="text-center">
       <Link to={`${path}/forgotPassword`} className="text-primary mt-3">
        <small>Forgot password?</small>
       </Link>
      </div>
     </div>
    </div>
   </ModalCard>
  );
 }

 login = () => {
  let { email } = this.state;
  let { password } = this.state;
  console.log(this.state);

  if (!(email !== "" && password !== "")) {
   alert("Please fill in all the required values");
   return false;
  }

  this.setState({ disabled: true });
  // let data = { name, email, password };

  fetch(window.server + "login.php", {
   method: "POST",
   headers: {
    // "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/x-www-form-urlencoded"
   },
   body: `email=${email}&password=${password}`
  })
   .then(response => response.text())
   .then(response => {
    response = JSON.parse(response);
    console.log(response);
    this.setState({ disabled: false });

    if (response.code === "200") {
     localStorage.id = response.data.id;
     localStorage.user = JSON.stringify(response.data);
     window.user = response.data;
     //  this.setState({ showModal: false });
     window.fetchPersistence();
     this.props.hideModal();
     return false;
    }

    alert("Wrong Email/password. please try again");
    this.setState({ password: "" });
   });
 };
}

class Register extends Component {
 state = { name: "", email: "", password: "", passwordRepeat: "" };
 render() {
  return (
   <ModalCard
    title="Begin your 7 steps to wealth here"
    description="Create an account so you can save your progress "
   >
    <div className={this.state.disabled ? "disabled" : ""}>
     <div className="my-2">
      {/* <small className="text-sm input-label">Name</small> */}
      <div>
       <input
        type="text"
        className="text-sm p-2 border w-100 mt-1"
        name="name"
        placeholder="Enter your name"
        onChange={e => {
         this.setState({ name: e.target.value });
        }}
       />
      </div>
     </div>

     <div className="my-2">
      {/* <small className="text-sm input-label">E-MAIL ADDRESS</small> */}
      <div>
       <input
        type="email"
        className="text-sm p-2 border w-100 mt-1"
        placeholder="Enter your Email"
        onChange={e => {
         this.setState({ email: e.target.value });
        }}
       />
      </div>
     </div>

     <div className="my-2">
      {/* <small className="text-sm input-label">CONFIRM PASSWORD</small> */}
      <div>
       <input
        type="tel"
        className="text-sm p-2 border w-100 mt-1"
        name="phone"
        onChange={e => {
         this.setState({ phone: e.target.value });
        }}
        placeholder="Enter your Phone number"
       />
      </div>
     </div>

     <div className="my-2">
      {/* <small className="text-sm input-label">PASSWORD</small> */}
      <div>
       <input
        type="password"
        className="text-sm p-2 border w-100 mt-1"
        name="password"
        onChange={e => {
         this.setState({ password: e.target.value });
        }}
        placeholder="Enter your Password"
       />
      </div>
     </div>

     {/* <div className="my-2">
      <small className="text-sm input-label">CONFIRM PASSWORD</small>
      <div>
       <input
        type="password"
        className="text-sm p-2 border w-100 mt-1"
        name="password"
        onChange={e => {
         this.setState({ passwordRepeat: e.target.value });
        }}
        placeholder="password"
       />
      </div>
     </div> */}

     <button
      onClick={this.register}
      className="btn btn-sm btn-primary my-3 p-2 px-3 w-100 d-flex flex-row align-items-center justify-content-between"
     >
      <div className="mx-2" />
      <div className="text-white text-">
       {!this.state.disabled && <b>REGISTER</b>}
       {this.state.disabled && <b>Loading</b>}
      </div>
      <img src={arrow_right} alt="" className="ml-1" />
     </button>
     <div className="text-center">
      <small className="d-block">
       Already have an account?
       <Link to={`${path}/login`} className="text-primary">
        Login
       </Link>
      </small>
      {/* <Link to={`${path}/forgotPassword`}>
       <small className="text-primary mt-2 d-block">Forgot password?</small>
      </Link> */}
      {/* <div
       className="cursor-pointer pt-2"
       onClick={() => this.props.hideModal()}
      >
       Skip >>
      </div> */}
     </div>
    </div>
   </ModalCard>
  );
 }

 register = () => {
  let { name } = this.state;
  let { email } = this.state;
  let { password } = this.state;
  let { passwordRepeat } = this.state;
  let { phone } = this.state;
  console.log(this.state);

  if (!(name !== "" && email !== "" && password !== "" && phone !== "")) {
   alert("Please fill in all the required values");
   return false;
  }

  // if (password !== passwordRepeat) {
  //  alert("The passwords do not match.");
  //  return false;
  // }

  this.setState({ disabled: true });
  // let data = { name, email, password };

  fetch(window.server + "register.php", {
   method: "POST",
   headers: {
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/x-www-form-urlencoded"
   },
   body: `name=${name}&email=${email}&password=${password}&phone=${phone}`
  })
   .then(response => response.text())
   .then(response => {
    console.log(response);
    response = JSON.parse(response);

    if (response.code === "200") {
     response.name = name;
     response.email = email;
     localStorage.id = response.id;
     localStorage.user = JSON.stringify(response);
     window.user = response;
     this.props.hideModal();

     return false;
    }

    window.tmpEmail = email;
    this.setState({ disabled: false });
    alert("The account already exists. Please login");
    this.props.history.push(`${path}/login`);
   });
 };
}

class ForgotPassword extends Component {
 state = { email: "" };
 render() {
  return (
   <ModalCard
    title="Forgot Password"
    description="Enter email address linked to your account"
   >
    <div className={this.state.disabled ? "disabled my-2" : "my-2"}>
     <small className="text-sm input-label">E-MAIL ADDRESS</small>
     <div>
      <input
       type="email"
       className="text-sm p-2 border w-100 mt-1"
       placeholder="Enter email address"
       onChange={e => {
        this.setState({ email: e.target.value });
       }}
      />
     </div>
    </div>

    <button
     onClick={this.send}
     className="btn btn-sm btn-primary my-3 p-2 px-3 w-100 d-flex flex-row align-items-center justify-content-between"
    >
     <div className="mx-2" />
     <div className="text-white text-">
      <b>SEND ME THE LINK</b>
     </div>
     <img src={arrow_right} alt="" className="ml-1" />
    </button>
    <div className="text-center">
     <small className="d-block">
      Already have an account?&nbsp;
      <Link to={`${path}/login`} className="text-primary">
       Login
      </Link>
     </small>
     {/* <Link to={`${path}/forgotPassword`}>
      <small className="text-primary mt-2 d-block">Forgot password?</small>
     </Link> */}
    </div>
   </ModalCard>
  );
 }

 send = () => {
  let { email } = this.state;

  if (email === "") {
   alert("Please fill in all the required values");
   return false;
  }

  this.setState({ disabled: true });
  // let data = { name, email, password };

  fetch(window.server + "forgotPassword.php", {
   method: "POST",
   headers: {
    // "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/x-www-form-urlencoded"
   },
   body: `email=${email}`
  })
   .then(response => response.text())
   .then(response => {
    this.setState({ disabled: false });
    console.log(response);
    response = JSON.parse(response);
    if (response.code === "200") {
     alert("check your email for the reset link");
     //  this.setState({ showModal: false });
     //  this.props.hideModal();
     return false;
    }

    alert(response.message);
   });
 };
}

class ResetPassword extends Component {
 state = { password: "", passwordRepeat: "" };
 render() {
  return (
   <ModalCard
    title="Reset Password"
    description="Almost done, set your new password to proceed"
   >
    <div className={this.state.disabled ? "disabled my-2" : "my-2"}>
     <small className="text-sm input-label">NEW PASSWORD</small>
     <div>
      <input
       type="password"
       className="text-sm p-2 border w-100 mt-1"
       placeholder=""
       onChange={e => {
        this.setState({ password: e.target.value });
       }}
      />
     </div>
    </div>

    <div className="my-2">
     <small className="text-sm input-label">CONFIRM PASSWORD</small>
     <div>
      <input
       type="password"
       className="text-sm p-2 border w-100 mt-1"
       placeholder=""
       onChange={e => {
        this.setState({ passwordRepeat: e.target.value });
       }}
      />
     </div>
    </div>

    <button
     className="btn btn-sm btn-primary my-3 p-2 px-3 w-100 d-flex flex-row align-items-center justify-content-between"
     onClick={this.send}
    >
     <div className="mx-2" />
     <div className="text-white text-">
      <b>SEND ME THE LINK</b>
     </div>
     <img src={arrow_right} alt="" className="ml-1" />
    </button>
    <div className="text-center">
     <small className="d-block">
      Already have an account? <span className="text-primary">Login</span>
     </small>
     <Link to={`${path}/forgotPassword`}>
      <small className="text-primary mt-2 d-block">Forgot password?</small>
     </Link>
    </div>
   </ModalCard>
  );
 }

 send = () => {
  let { password } = this.state;
  let { passwordRepeat } = this.state;

  if (password === "" || passwordRepeat == "") {
   alert("Please fill in all the required values");
   return false;
  }

  if (password !== passwordRepeat) {
   alert("The passwords do not match!!");
   return false;
  }

  this.setState({ disabled: true });
  // let data = { name, email, password };

  fetch(window.server + "changePassword.php", {
   method: "POST",
   headers: {
    // "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/x-www-form-urlencoded"
   },
   body: `token=${window.location.search.split("=")[1]}&password=${password}`
  })
   .then(response => response.text())
   .then(response => {
    this.setState({ disabled: false });

    if (response === "ok") {
     alert("Your password has been reset successfully.");
     this.props.history.push(`${path}/login`);
     return false;
    }

    alert("There was a problem sending your email. Please try again.");
    this.setState({ email: "" });
   });
 };
}
