import React, { Component } from "react";
import { Route, BrowserRouter as Router } from "react-router-dom";
import Nav from "../components/nav";

import consolation_spender from "../../img/icons/Results/consolation_spender.svg";
import Deal_hunter from "../../img/icons/Results/Deal_hunter.svg";
import Focused from "../../img/icons/Results/Focused.svg";
import Impulse_buyer from "../../img/icons/Results/Impulse_buyer.svg";
import keeping_itreal from "../../img/icons/Results/keeping_itreal.svg";
import list_maker from "../../img/icons/Results/list_maker.svg";
import livingitup from "../../img/icons/Results/livingitup.svg";
import long_arrow from "../../img/icons/Results/long_arrow.svg";
import necessity_spender from "../../img/icons/Results/necessity_spender.svg";

import arrow from "../../img/icons/options/arrow.svg";
import chill from "../../img/icons/options/chill.svg";
import drink from "../../img/icons/options/drink.svg";
import Impact from "../../img/icons/options/Impact.svg";
import list from "../../img/icons/options/list.svg";
import moments from "../../img/icons/options/moments.svg";
import nightout from "../../img/icons/options/nightout.svg";
import old_phone from "../../img/icons/options/old_phone.svg";
import Shopping from "../../img/icons/options/Shopping.svg";
import Smartphone from "../../img/icons/options/Smartphone.svg";
import Stay_home from "../../img/icons/options/Stay_home.svg";

import Cash from "../../img/step6/Cash.svg";
import Sale from "../../img/step6/Sale.svg";
import Shares from "../../img/step6/Shares.svg";
import time from "../../img/step6/time.svg";
import Waiting from "../../img/step6/Waiting.svg";
import Wallet from "../../img/step6/Wallet.svg";

import S1 from "./s1";
import S2 from "./s2";
import S3 from "./s3";
import S5 from "./s5";
import Article1 from "./articles/article1";
import Article2 from "./articles/article2";
import S4 from "./s4";

window.slide6 = {
  scenarios: [
    {
      desc:
        "One year into the journey you notice that the fund interest dropped by 5%. Whats your choice?",
      year: "year=years*0.2",
      options: [
        {
          icon: Cash,
          title: "Withdraw your money from the unit trust and put into shares",
          year: "year+=1",
          outcome: {
            title:
              "A few months later the interest rate of the fund climbs back up to be much higher than before.",
            desc:
              "Always remember that interest rates  for unit trusts fluctuate over the short term but when managed by an expert will almost always rise in the long term."
          },
          award: {
            icon: consolation_spender,
            title: "DON’T PANIC",
            desc:
              "Unit trusts may fluctuate over months but over the course of the years they will generally rise. Stay the course reap the rewards"
          }
        },
        {
          icon: time,
          title:
            "Wait to see if the sitation improves if you withdraw from the unit trust",
          year: "year+=1",
          outcome: {
            title:
              "A few months later the interest rate of the fund climbs back up to be much higher than before",
            desc:
              "Always remember that interest rates  for unit trusts fluctuate over the short term but when managed by an expert will almost always rise in the long term."
          },
          award: {
            icon: Focused,
            title: "KEEP CALM AND PROSPER",
            desc:
              "You aren’t swayed by fluctuations and that’s good. Keep your level head and you should weather any storms."
          }
        }
      ]
    },

    {
      desc:
        "Fuel prices are rising again and everything got more expensive. You need to cut down expenses. What will it be?",
      year: "year=years*0.2",
      options: [
        {
          icon: Wallet,
          title:
            "Reduce the amount you spend on everything including your savings",
          year: "year+=1",
          outcome: {
            title:
              "The cost of living will always keep rising. Eventually you are forced to cut down on your expenses dreams again",
            desc:
              "The money you save in a unit trust is protected against inflation. This makes it a safe bet to keep your money growing as things get more expensive."
          },
          award: {
            icon: consolation_spender,
            title: "STAY FOCUSED",
            desc:
              "Keep investing in a unit trust even as things get more expensive. Its one of the few ways to keep earning money in hard times."
          }
        },
        {
          icon: time,
          title: "Cut down on your wants but keep your savings stable",
          year: "year+=1",
          outcome: {
            title:
              "As time passes you notice that you are making more money from your unit trust than you removed from your spending.",
            desc:
              "The money you save in a unit trust is protected against inflation. This makes it a safe bet to keep your money growing as things get more expensive."
          },
          award: {
            icon: Focused,
            title: "KEEP THE DISCIPLINE",
            desc:
              "Sacrificing today for a better tomorrow will see you a much happier person when it counts the most."
          }
        }
      ]
    },

    {
      desc:
        "You find a plot of land that you can sell in less than a year. The sale amount is only KES $futuresm while saving for $timeleft years will earn you KES $future",
      year: "year=years*0.2",
      options: [
        {
          icon: time,
          title:
            "Let the opportunity pass and wait for your saving goal to reach",
          year: "year+=0",
          outcome: {
            title:
              "You continue saving and because you are protected from inflation you end up with a lot more value than if you sold early.",
            desc:
              "Patience is a virtue.  By having a long term vision you will always end up getting the more than if you rushed things."
          },
          award: {
            icon: consolation_spender,
            title: "BE PATIENT",
            desc:
              "If time is money then patience is wealth. Don’t shortchange your future because of the temptation of reward today."
          }
        },
        {
          icon: Sale,
          title: "Don’t let the opportunity pass and take the land now",
          year: "year+=0",
          outcome: {
            title:
              "You get the KES $futuresm but after inflation for the next $timeleft years It will be worth less by $timeleft",
            desc:
              "When it comes to investing it is always better to think long term.  Don’t sacrifice your future ambitions for easier money today"
          },
          award: {
            icon: Focused,
            title: "KEEP THE FOCUS",
            desc:
              "With a long term vision you will always end up getting the more than if you rushed things."
          }
        }
      ]
    },

    {
      desc:
        "There is a huge buzz about a new stock  that is starting cheap but looking like it will rise. Your contacts are all saying buy buy buy",
      year: "year=years*0.2",
      options: [
        {
          icon: time,
          title:
            "This is your moment go for it with most of what you have saved",
          year: "year+=1",
          outcome: {
            title:
              "After a while the share price stays low. The only people who make money are the brokers who made a commission on all the selling and buying",
            desc:
              "It is important to do your due diligence and thoroughly research  all they hype and trends. Most often the real reasons for a stock being ‘hot’ may not be transparent to you."
          },
          award: {
            icon: consolation_spender,
            title: "LOOK BEFORE YOU LEAP",
            desc:
              "Its easy To get caught in the hype when you really want to get rich. Do your research and listen to the experts before you make a big decision."
          }
        },
        {
          icon: Sale,
          title:
            "Wait a bit and see if the trend will continue or is it just fake hype",
          year: "year+=0",
          outcome: {
            title:
              "After a while the share price stays low. The only people who make money are the brokers who made a commission on all the selling and buying",
            desc:
              "It is important to do your due diligence and thoroughly research  all they hype and trends. Most often the real reasons for a stock being ‘hot’ may not be transparent to you."
          },
          award: {
            icon: Focused,
            title: "KEEP YOUR EYE ON THE GAME",
            desc:
              "Keep up the discipline of checking on the market trends and don’t rush unless you absolutely know for sure it will pay off."
          }
        }
      ]
    },

    {
      desc:
        "Congrats because of good fund performance you have reached your goal of KES $future 6 months early. What do you do?",
      year: "year=years*0.2",
      options: [
        {
          icon: Shares,
          title: "Finish the 6 months. A little more money wont hurt anyone",
          year: "year+=1",
          outcome: {
            title:
              "Checking on your plan you realise that the price of your dream has also increased over the years. Its worth about 6 months of saving",
            desc:
              "Always remember to keep updating the cost of your dreams.  It will help you know whether you need to keep saving and prevent you coming short of your goals."
          },
          award: {
            icon: consolation_spender,
            title: "SAVING NEVER ENDS",
            desc:
              "Even if you reach a goal, it good that you don’t stop and start building for the next one."
          }
        },
        {
          icon: Waiting,
          title:
            "The time has come!! You have waited so long  and sacrificed enough.",
          year: "year+=1",
          outcome: {
            title:
              "Checking on your plan you realise that the price of your dream has also increased over the years. Its worth about 6 months of saving",
            desc:
              "Always remember to keep updating the cost of your dreams.  It will help you know whether you need to keep saving and prevent you coming short of your goals."
          },
          award: {
            icon: Focused,
            title: "STAY THE COURSE!!",
            desc:
              "Don’t stop saving once you reach a goal. Life is full of suprises and you will always have another milestone to achieve."
          }
        }
      ]
    }
  ],
  results: [],
  total: 0,
  totals: {},
  years: 5,
  totalYears: 0
};

class Slide6Home extends Component {
  state = {};
  render() {
    return (
      <div>
        <Nav />
        <main className="main-page d-flex bg-dark flex-column">
          <div className="cover">
            <Route path="/(slide6|slide6/video)/" exact component={S1} />
            <Route path="/slide6/step2" component={S2} />
            <Route path="/slide6/step3" component={S3} />
            <Route path="/slide6/step4" component={S4} />
            <Route path="/slide6/step5" component={S5} />
            <Route path="/slide6/article1" component={Article1} />
            <Route path="/slide6/article2" component={Article2} />
          </div>
        </main>
      </div>
    );
  }
}

export default Slide6Home;
