import React, { Component } from "react";
// images
import article1 from "../../../img/articles/slide3_article2.jpg";
import article2 from "../../../img/articles/slide3_article1_small.jpg";
import { Link } from "react-router-dom";
import Nav from "../../components/nav";

import twitter_icon from "../../../img/twitter_icon.png";
import facebook_icon from "../../../img/facebook_icon.png";
import Footer from "../../components/Footer";
import logo from "../../../img/logo.png";

class Article2 extends Component {
  state = {};
  render() {
    window.scrollTo(0, 0);
    return (
      <>
        <Nav />
        <div className="article-parent">
          <div className="article-header position-relative">
            <img
              src={article1}
              alt=""
              className="position-absolute h-100 w-100 article-header-image"
            />
            <div className="container d-flex flex-column flex-fill justify-content-between h-100 text-white">
              <div className="w-100 text-center mt-5">
                <img src={logo} alt="" className="main-logo" />
              </div>

              <div>
                <h1 className="font-weight-bold">Learn from the best</h1>

                <div className="text-white align-items-center mb-5">
                  <div className="font-weight-normal d-flex align-items-center flex-row">
                    <small className="font-weight-normal">23 March 2019</small>
                    <div className="d-inline-block mx-2">|</div>
                    <small className="font-weight-normal">Share</small>
                    <div className="d-flex flex-row ml-2">
                      <a
                        href="https://twitter.com/AmanaCapitalKE"
                        className="mr-3">
                        <img
                          src={twitter_icon}
                          alt=""
                          className="social-icon"
                        />
                      </a>

                      <a
                        href="https://www.facebook.com/amanacapital2003/"
                        className="mr-3">
                        <img
                          src={facebook_icon}
                          alt=""
                          className="social-icon"
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="container pb-5">
            <div className="text-muted mt-3 mb-5">
              <small>Home / Blog / Learn from the best</small>
            </div>

            <div className="row">
              <div className="col-md-9">
                <div className="text-dark article-text">
                  <ol>
                    <li>
                      <b>Time</b> - investing is a long term game {" "}
                    </li>
                    <li>
                      <b> Consistency</b> - not one off  investment, but there
                      is benefit in consistency buying units periodically take
                      advantage of lower prices
                    </li>
                    <li>
                      <b>Tax efficiency</b> - make sure your money is invested
                      in tax efficient ways, like getting deducted before you
                      are charged PAYE
                    </li>
                    <li>
                      <b>Compounding</b> - reinvest your income
                    </li>
                    <li>
                      <b>Diversify source of income</b> - dividends, interest,
                      capital gains etc
                    </li>
                  </ol>
                  <h5 className="mb-2 mt-5 font-weight-bold d-block">
                    Warren Buffet
                  </h5>
                  <a
                    className="d-non"
                    href="https://www.investopedia.com/articles/stocks/08/buffett-best-buys.asp">
                    www.investopedia.com
                  </a>
                  <p>
                    <b className="d-block mt-2">Who is:</b>
                    <p>
                      Buffett's holding company, Berkshire Hathaway
                      (NYSE:BRK.A), has a portfolio that contains both wholly
                      owned subsidiaries, such as Geico Auto Insurance and
                      Benjamin Moore & Co., and sizable blocks of shares in
                      publicly traded corporations. For example, Berkshire
                      Hathaway is the largest shareholder of both Coca Cola
                      (NYSE:KO) and Kraft Foods
                    </p>
                  </p>
                  <b> Time: Investing is a long term game</b>
                  <p>
                    <blockquote>
                      “I know what markets are going to do over a long period of
                      time: They’re going to go up. But in terms of what’s going
                      to happen in a day or a week or a month or a year even,
                      I’ve never felt that I knew it and I’ve never felt that
                      was important,” WB
                    </blockquote>
                    In 1989 Gillette was suffering losses as newly introduced
                    disposable razors cut into their business. Several takeovers
                    were attempted. It was suprising that he then invested $600
                    million into the company. His choice was sound as the
                    company began to see profits and his investment became worth
                    850 million in 5 years. A lot of other investors sold their
                    Gillette shares because they felt they could finally make a
                    profit. Buffet held on to his investment for another 10
                    years and in 2005 he sold his stake to P&G for $5 billion
                  </p>
                  <b>Compounding: Reinvest your income</b>
                  <p>
                    <blockquote>
                      “ My wealth has come from lying in America, lucky genes
                      and compound interest”
                    </blockquote>
                    <b>Here’s the power of compound interest:</b>
                    Imagine you invested $1 that compounded at 1% a day.  In 5
                    years your $1 would grow to over $77 million.  You would be
                    the richest person in the world by year 7. For Warren buffet
                    the strategy or holding on to investments for the long term
                    and reinvesting the money has meant that he made more money
                    in the last 10 years than he did in his last 70. Keep your
                    investment growing and don’t spend your returns .
                  </p>
                  <h5 className="mb-2 mt-5 font-weight-bold d-block">
                    George Soros
                  </h5>
                  <b className="d-block mt-2">Who is:</b>
                  <p>
                    Soros turned an original seed funding of $12 million into
                    $20 billion by the first decade of the 21st century. If you
                    had invested $1,000 in Soros’ Quantum Fund in 1969, you
                    would have earned $4 million by 2000 – at an annual growth
                    rate of 30%.
                  </p>
                  <b>Lesson: Don’t always jump with the trend</b>
                  <blockquote>
                    “Markets are constantly in a state of uncertainty and flux,
                    and money is made by discounting the obvious and betting on
                    the unexpected.” – George Soros.
                  </blockquote>
                  <p>
                    In 1992, George Soros wagered $10 billion against the
                    currency policy of the Bank of England, and its underlying
                    currency, the pound. Essentially, Soros' bet the pound would
                    flounder in global currency markets. On September 16, 1992 -
                    a day known as "Black Wednesday" among currency traders -
                    the British pound cratered against the German mark and the
                    U.S. dollar, earning Soros $1.2 billion in profits over the
                    next few weeks - a bet that went down in history as the day
                    George Soros broke the Bank of England.
                  </p>
                  <h5 className="mb-2 mt-5 font-weight-bold d-block">
                    Peter Lynch
                  </h5>
                  <a
                    className="d-non"
                    href="https://www.investopedia.com/articles/stocks/06/peterlynch.asp">
                    www.investopedia.com
                  </a>
                  <p>
                    <blockquote>
                      “Markets are constantly in a state of uncertainty and
                      flux, and money is made by discounting the obvious and
                      betting on the unexpected.” – George Soros.
                    </blockquote>
                    <b className="d-block mt-2">Who is:</b>
                    <p>
                      Lynch ran the Fidelity Magellan Fund from 1977 to 1990.
                      During that time, he beat the pants off the market. He
                      outpaced the S&P 500 11 out of 13 years, averaging a 29%
                      annual return. From start to finish, that’s a 2700%
                      return!
                    </p>
                    <p>
                      <b className="d-block">Lesson: Know what you own</b>
                      “Only buy what you understand” This is perhaps the most
                      famous of all of Lynch's blockquotes, but it's often
                      misunderstood. Lynch is not advising you to buy stock in a
                      company simply because it makes your favorite product or
                      service. This can be an excellent starting point, but
                      Lynch also recommends diving into the business's
                      competitive position, financials, and growth prospects
                      before investing in its stock. When Lynch says to buy what
                      you know, he's referring to staying within your circle of
                      competence and investing in industries that you understand
                      well.
                    </p>
                  </p>
                </div>
              </div>

              <div className="col-md-3">
                <b>Related Articles</b>

                <Link to="/slide7/article1">
                  <div className="bg-light my-5 position-relative">
                    <img src={article2} alt="" className="w-100" />
                    <div className="red-text p-3">
                      <small>The final step</small>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

export default Article2;
