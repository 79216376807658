import React, { Component } from "react";

class Input2 extends Component {
 state = { value: this.props.value ? this.props.value : 0 };
 render() {
  return (
   <div>
    <input
     type={this.props.type ? this.props.type : "text"}
     name=""
     value={this.state.value}
     onChange={evt => {
      let { value } = evt.target;
      let v1 = value.replace(/,/g, "");
      if (v1 === "" || v1 === "NaN") v1 = "0";
      let v2 = parseInt(v1);
      if (v2 === NaN) return false;

      this.setState({ value: v2.toLocaleString() });
      if (this.props.getValue) this.props.getValue(v1);
     }}
     onFocus={() => {
      if (this.state.value === 0) this.setState({ value: "" });
     }}
     className={
      "py-1 text-center text-bronze card-input2 d-flex flex-fill w-100 " +
      this.props.className
     }
     placeholder="enter value"
    />
   </div>
  );
 }

 componentWillReceiveProps(nextProps) {
  if (nextProps.value && nextProps.value !== "NaN")
   this.setState({ value: nextProps.value });
 }
}

export default Input2;
