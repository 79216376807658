import React, { Component } from "react";
// images
import article2 from "../../../img/articles/slide3_article2_small.jpg";
import article1 from "../../../img/articles/slide3_article1.jpg";
import { Link } from "react-router-dom";
import Nav from "../../components/nav";

import twitter_icon from "../../../img/twitter_icon.png";
import facebook_icon from "../../../img/facebook_icon.png";
import Footer from "../../components/Footer";
import logo from "../../../img/logo.png";

class Article1 extends Component {
  state = {};
  render() {
    window.scrollTo(0, 0);
    return (
      <>
        <Nav />
        <div className="article-parent">
          <div className="article-header position-relative">
            <img
              src={article1}
              alt=""
              className="position-absolute h-100 w-100 article-header-image"
            />
            <div className="container d-flex flex-column flex-fill justify-content-between h-100 text-white">
              <div className="w-100 text-center mt-5">
                <img src={logo} alt="" className="main-logo" />
              </div>

              <div>
                <h1 className="font-weight-bold">
                  The 7 How to stick to the plan
                </h1>

                <div className="text-white align-items-center mb-5">
                  <div className="font-weight-normal d-flex align-items-center flex-row">
                    <small className="font-weight-normal">23 March 2019</small>
                    <div className="d-inline-block mx-2">|</div>
                    <small className="font-weight-normal">Share</small>
                    <div className="d-flex flex-row ml-2">
                      <a
                        href="https://twitter.com/AmanaCapitalKE"
                        className="mr-3">
                        <img
                          src={twitter_icon}
                          alt=""
                          className="social-icon"
                        />
                      </a>

                      <a
                        href="https://www.facebook.com/amanacapital2003/"
                        className="mr-3">
                        <img
                          src={facebook_icon}
                          alt=""
                          className="social-icon"
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* content */}

          <div className="container pb-5">
            <div className="text-muted mt-3 mb-5">
              <small>Home / Blog / The 7 How to stick to the plan</small>
            </div>

            <div className="row">
              <div className="col-md-9">
                <div className="text-dark article-text">
                  <p>
                    In the previous 5 steps of the 7 Steps to Wealth, we have
                    learned how to go through the process of developing a
                    relevant and appropriate savings plan for ourselves based
                    upon our particular goals. We have learned how to make a
                    monthly budget that allows for adequate savings, how to
                    raise some extra money towards your savings, how to set
                    realistic financial goals, how to weigh the various savings
                    options available to you, and how to seek professional
                    savings advice from the experts.
                  </p>
                  <p>
                    Once you have made your savings plan, the key to success of
                    course is to subsequently stick to it. This, however, is
                    often easier said than done. Life being as it is, things
                    crop up, bills come along and temptations raise their head.
                    The secret to avoiding the many pitfalls that can upend your
                    savings regime is discipline. It is only by sticking
                    religiously to your savings regime that it will deliver for
                    you the secure and independent financial future that you are
                    working towards. There are no short cuts, there are no real
                    “get rich quick" schemes, there is only perseverance and
                    patience.
                  </p>
                  <p>
                    One of the most common mistakes that we make when pursuing
                    the path to wealth is self-doubt After a while we begin to
                    question our decisions. We question of we have invested
                    correctly. We question if we are getting the returns that we
                    should be. We question of we will ever get to where we are
                    going, especially when times are tough, personally and
                    economically.
                  </p>
                  <p>
                    The point of the 7 Steps to Wealth course is to guide you
                    logically and sequentially through the decision making
                    process. Take your time with the process, do your homework,
                    investigate all the options. However, once you have arrived
                    at a decision, you must hold tight to the reasons behind it
                    in your mind. You must trust yourself, and trust that your
                    thinking process was correct, and ultimately trust that the
                    timeless combination of time and rate of returns will cause
                    your savings pot to grow and grow and grow. It may not
                    always grow at the rate at which you wish it to grow, but
                    know that for every year where it does not do as well as
                    expected, there shall be a year where it far exceeds your
                    expectations.
                  </p>
                  <p>
                    One common temptation, especially once your savings pot has
                    begun to look sufficiently “healthy", is to “cash-in" on
                    your hard earned monies. This is a very beguiling
                    temptation. You haven't quite yet reached your initial
                    financial goals, but the pot that you have accumulated is
                    begining to look more and more appealing. Resist this
                    temptation. Remember the very precise number that you set
                    for yourself at the beginning of the process. That is the
                    number that you are aiming for. That is the number that you
                    have calculated shall give you the financial freedom that
                    you desire. Do not deviate from the plan until you have
                    achieved it.
                  </p>
                  <p>
                    Yet another common mistake that we make, again especially
                    once our savings pot has grown quite healthy, is to succumb
                    to the temptation to seek a miraculously faster rate of
                    return so as to achieve our goal in a shorter period of
                    time. Again, this is, nine times out of ten, an illusion.
                    You have saved up 10 million shillings, your goal being 20
                    million, and someone or something comes along that makes you
                    think you can turn the 10 into 20 in a matter of months or a
                    few short years.
                  </p>
                  <p>
                    Remember remember rememeber, if there genuinely were any
                    ways to easily get rich quickly, everybody would be getting
                    very rich very quickly. The fact that we are not tells us
                    everything that we need to k ow about the purveyors of such
                    schemes. This is one of the reasons why it is so important
                    to entrust your savings to seasoned and trusted
                    professionals such as Amana Capital. The only true
                    investment advisor is the advisor who preaches prudence and
                    patience. As your savings pot grows ever larger don't
                    succumb to the temptation to “turbo-charge" it's growth.
                    More often than not you shall end up loosing it all.
                  </p>
                  <p>
                    In terms of the month in month out reality of sticking to
                    your savings plan, what often trips up most of us is the
                    temptation to “miss a month" here or there. “I saved last
                    month so this month I can skip it" is a common feeling or
                    thought. This is not to say that some months aren't
                    financially tougher than others, they are, and there may be
                    some months where it really isn't feasible for you to meet
                    your monthly savings target. However, you must do everything
                    in your power to keep such months to a minimum. Future
                    financial success is utterly dependant on daily and monthly
                    financial discipline. Your rate of return is based upon
                    interest working it's magic upon a steadily increasing
                    principle. It is a virtuous circle, the more you put into
                    the pot each month, and the better the rate of return, the
                    faster you shall reach your goal of financial freedom for
                    life.
                  </p>
                  <p>
                    Sticking to the plan is not easy. Sticking to a plan over a
                    period of 10 to 20 years, if not longer, is not easy.
                    However, and this is the oldest rule in the world of money,
                    it is only by sticking to the plan that you will eventually
                    see the plan work out, just as you planned it! The road to
                    wealth can be a long and hard one, but just like with
                    everything else in life, one step at a time, one day at a
                    time, one year at a time and you shall get there. Keep your
                    eyes on the prize and stick to the plan like glue!
                  </p>
                </div>
              </div>
              <div className="col-md-3 d-none">
                <b>Related Articles</b>

                <Link to="/slide4/article2">
                  <div className="bg-light my-5 position-relative">
                    <img src={article2} alt="" className="w-100" />
                    <div className="red-text p-3">
                      <small>
                        The 7 Rules of choosing your savings options
                      </small>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

export default Article1;
