import React, { Component } from "react";
import "./css/font.css";
import "./css/bootstrap.min.css";
import "./css/landingPage.css";
import "./css/style.css";
import "./css/responsive.css";
import Home from "./pages/slide1/home";
import {
  Route,
  BrowserRouter as Router,
  Switch,
  withRouter
} from "react-router-dom";
import Slide2Home from "./pages/slide2/slide2home";
import LandingPage from "./pages";
import Nav from "./pages/components/nav";
import Article from "./pages/slide1/articles/article1";
import Article2 from "./pages/slide1/articles/article2";
import Menu from "./pages/components/menu";
import ReactGA from "react-ga";
// import Article from "./pages/article";
import ReactPixel from "react-facebook-pixel";
import TagManager from "react-gtm-module";

import "@fortawesome/fontawesome-free/css/all.css";
import Slide3Home from "./pages/slide3/slide3home";

import Africa from "./img/icons/goals/Africa.svg";
import Apartment from "./img/icons/goals/Apartment.svg";
import By_youself from "./img/icons/goals/By_youself.svg";
import Celebrity from "./img/icons/goals/Celebrity.svg";
import Comfortable from "./img/icons/goals/Comfortable.svg";
import Europe from "./img/icons/goals/Europe.svg";
import House from "./img/icons/goals/House.svg";
import Livingitup from "./img/icons/goals/Livingitup.svg";
import Local from "./img/icons/goals/Local.svg";
import Mansion from "./img/icons/goals/Mansion.svg";
import money from "./img/icons/goals/money.svg";
import Office from "./img/icons/goals/Office.svg";
import StartUp from "./img/icons/goals/StartUp.svg";
import team from "./img/icons/goals/team.svg";
import Townhouse from "./img/icons/goals/Townhouse.svg";
import Travel from "./img/icons/goals/Travel.svg";
import University from "./img/icons/goals/University.svg";
import Slide4Home from "./pages/slide4/slide4home";
import Slide5Home from "./pages/slide5/slide5home";
import Slide6Home from "./pages/slide6/slide6home";
import Slide7Home from "./pages/slide7/slide7home";

const tagManagerArgs = {
  gtmId: "GTM-PSGPHZF"
};

TagManager.initialize(tagManagerArgs);

const advancedMatching = {}; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/pixel-with-ads/conversion-tracking#advanced_match
const options = {
  autoConfig: true, // set pixel's autoConfig
  debug: false // enable logs
};

ReactPixel.init("311915359723106", advancedMatching, options);
ReactGA.initialize("UA-141730495-1");

class App extends Component {
  state = {};
  render() {
    return (
      <>
        <Menu />
        <Switch>
          <Route path="/(|video)" exact component={LandingPage} />
          <Route path="/slide1/" component={Home} />
          <Route path="/slide2/" component={Slide2Home} />
          <Route path="/slide3/" component={Slide3Home} />
          <Route path="/slide4/" component={Slide4Home} />
          <Route path="/slide5/" component={Slide5Home} />
          <Route path="/slide6/" component={Slide6Home} />
          <Route path="/slide7/" component={Slide7Home} />
          <Route path="/article1" exact component={Article} />
          <Route path="/article2" exact component={Article2} />
        </Switch>
      </>
    );
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      ReactGA.pageview(window.location.pathname);
      ReactPixel.pageView();
    }
  }

  componentWillMount() {
    // set server
    if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
      window.server = "http://localhost/Legibra/12steps/";
    } else {
      window.server = "/php/";
    }

    window.calc = { needs: 0, wants: 0, earnings: 1, savings: 0, future: 0 };
    window.store = {
      savings: {
        SAVINGS: 0
      },
      needs: {
        RENT: 0,
        HOUSEHOLD: 0,
        FAMILY: 0,
        UTILITIES: 0
      },
      wants: {
        PERSONAL: 0,
        ENTERTAINMENT: 0,
        SHOPPING: 0
      }
    };

    if (localStorage.calc) {
      window.calc = { ...JSON.parse(localStorage.calc) };
    }
    if (localStorage.step1Options) {
      let s1 = JSON.parse(localStorage.step1Options);
      let s2 = window.store;
      window.store = {
        needs: { ...s2.needs, ...s1.needs },
        wants: { ...s2.wants, ...s1.wants },
        savings: { ...s2.savings, ...s1.savings }
      };
    }

    window.slide3 = {
      options: [
        {
          name: "University",
          icon: University,
          data: [
            {
              title: "EUROPE / US",
              icon: Europe,
              value: 0,
              lowerLimit: 9000000,
              upperLimit: 12000000
            },
            {
              title: "SOUTH AFRICA",
              icon: Africa,
              value: 0,
              lowerLimit: 4000000,
              upperLimit: 6000000
            },
            {
              title: "KENYA",
              icon: Local,
              value: 0,
              lowerLimit: 1500000,
              upperLimit: 3000000
            }
          ]
        },
        {
          name: "Buying Property",
          icon: House,
          data: [
            {
              title: "Buy An Apartment",
              icon: Apartment,
              value: 0,
              lowerLimit: 5000000,
              upperLimit: 10000000
            },
            {
              title: "A Townhouse",
              icon: Townhouse,
              value: 0,
              lowerLimit: 15000000,
              upperLimit: 35000000
            },
            {
              title: "A Mansion",
              icon: Mansion,
              value: 0,
              lowerLimit: 60000000,
              upperLimit: 100000000
            }
          ]
        },
        {
          name: "Starting a Business",
          icon: StartUp,
          data: [
            {
              title: "By Yourself",
              icon: By_youself,
              value: 0,
              lowerLimit: 4000000,
              upperLimit: 7000000
            },
            {
              title: "With a Team",
              icon: team,
              value: 0,
              lowerLimit: 8000000,
              upperLimit: 12000000
            },
            {
              title: "With an Office",
              icon: Office,
              value: 0,
              lowerLimit: 12000000,
              upperLimit: 20000000
            }
          ]
        },
        {
          name: "Just The Money",
          icon: money,
          data: [
            {
              title: "Comfortable",
              icon: Comfortable,
              value: 0,
              lowerLimit: 10000000,
              upperLimit: 2000000
            },
            {
              title: "Living it up",
              icon: Livingitup,
              value: 0,
              lowerLimit: 30000000,
              upperLimit: 45000000
            },
            {
              title: "Celebrity",
              icon: Celebrity,
              value: 0,
              lowerLimit: 50000000,
              upperLimit: 75000000
            }
          ]
        }
      ],
      years: 10,
      monthlyTotal: 0
    };

    window.slide4 = {
      data: [
        {
          name: "Property",
          icon: House,
          goal: 0,
          years: 1,
          steps: [
            {
              copy:
                "You find a great deal for  5,000,000 . The seller requires a deposit of 1,000,000 or you will have to pay a hefty mortgage. How do you begin?",

              options: [
                {
                  title: "If you have 1,000,000 Ksh pay it now.",
                  to: 1,
                  risk: [
                    {
                      title: "1000,000 KSH for deposit"
                    }
                  ],
                  reward: [{ title: "LOWER MONTHLY MORTGAGE." }]
                },
                {
                  title: "Invest for 5 years to get the deposit",
                  to: 1,
                  years: 5,
                  risk: [{ title: "HIGHER PRICES 5 YEARS LATER." }],
                  reward: [{ title: " EARNINGS FOR 5 YEARS FROM INVESTING." }]
                },
                {
                  title: "Pay a 60,000/- mortgage.",
                  to: 1,
                  risk: [{ title: "HIGH MORTGAGE WITH NO DEPOSIT" }],
                  reward: []
                }
              ]
            },
            {
              copy:
                "Who is going to check all your documents, to make sure there are no suprises.",

              options: [
                {
                  title: "Hire a lawyer",
                  to: 2,
                  risk: [
                    {
                      title: "LAWYER FEES"
                    }
                  ],
                  reward: [
                    {
                      title: "LEGAL AND ORIGINAL DOCUMENTS"
                    }
                  ]
                },
                {
                  title: "Use a 'guy with connections' ",
                  to: 2,
                  risk: [
                    { title: "RISK OF FAKE DOCUMENTS" },
                    { title: "EXPENSIVE FAVOURS" }
                  ],
                  reward: []
                },
                {
                  title: "Do it yourself",
                  to: 2,
                  risk: [
                    { title: "LONG WAIT AND PROCESSING TIMES" },
                    { title: "RISK OF FAKE TITLE DEEDS" },
                    { title: "EXPENSIVE FAVOURS" }
                  ],
                  reward: []
                }
              ]
            },
            {
              copy:
                "Unfortunately a family emergency has come up and you are the only one  who can settle the bill of the earnings for 2yrs. What will you do?",
              skip: true,
              options: [
                {
                  title: "Sell your property for 50% cheaper than you expected",
                  reward: [],
                  years: "Math.ceil(yrs*0.3)",
                  to: 3,
                  risk: []
                },
                {
                  title: "Start a Harambee",
                  years: "Math.ceil(yrs*0.3)",
                  to: 4,
                  risk: [],
                  reward: []
                },
                {
                  title: "Take another loan on top of your mortgage.",
                  to: 5,
                  years: "Math.ceil(yrs*0.3)",
                  risk: [],
                  reward: []
                }
              ]
            },
            {
              copy:
                "It took 6 months to find a buyer and complete the sale.  In that time you still had the pressure of paying the bill and you did not meet your target.",
              options: [
                {
                  title: "Continue",
                  reward: [],
                  to: false,
                  years: 1,
                  risk: [
                    { title: "LOW LIQUIDITY" },
                    { title: "DEBT COLLECTORS" },
                    { title: "50% LESS EARNINGS." }
                  ]
                }
              ]
            },
            {
              copy:
                "It took 3 months to organise and since times are hard for everyone you only managed to get 65% of the bill paid.  Your debt still needs paying.",
              options: [
                {
                  title: "Continue",
                  to: false,
                  years: 1,
                  risk: [
                    { title: "LOW LIQUIDITY DURING EMERGENCIES" },
                    { title: "DEBT COLLECTORS" }
                  ],
                  reward: [{ title: "SELL PROPERTY FOR HIGH RETURNS" }]
                }
              ]
            },
            {
              copy:
                "You take another loan but you cant stop paying your mortgage or they will take the property.  Life just became a lot harder.",
              options: [
                {
                  title: "Continue",
                  to: false,
                  years: "Math.ceil(yrs*0.5)",
                  risk: [
                    { title: "LOAN REPAYMENTS" },
                    { title: "LOW LIQUIDITY DURING EMERGENCIES" },
                    { title: "LOSE HOUSE IF PAYMENTS ARE MISSED" }
                  ],
                  reward: []
                }
              ]
            }
          ]
        },
        {
          name: "Shares",
          icon: money,
          goal: 0,
          years: 1,
          steps: [
            {
              copy:
                "While doing research you hear about a promising company is about to list their shares. How will you begin?",

              options: [
                {
                  title: "Do it yourself through an online portal",
                  to: 1,
                  risk: [],
                  reward: []
                },
                {
                  title: "Pay a trader to do the buying for you?",
                  to: 2,
                  risk: [],
                  reward: [{ title: "STABLE INVESTMENT" }]
                }
              ]
            },
            {
              copy:
                "Even though you do your research the forces  that control the  prices of shares are difficut to understand  without trading.  Untrained decisions may lead to losses",
              options: [
                {
                  title: "Continue",
                  to: 3,
                  // years: 3,
                  risk: [
                    {
                      title: "LACK OF EXPERT KNOWLEDGE"
                    }
                  ],
                  reward: []
                }
              ]
            },
            {
              copy:
                "Traders are well trained on how to make the right decisions but they come at the price of high commissions and deductions",
              options: [
                {
                  title: "Continue",
                  to: 3,
                  // years: 3,
                  risk: [
                    {
                      title: "HIGH COMMISSIONS"
                    }
                  ],
                  reward: [
                    {
                      title: "EXPERT KNOWLEDGE"
                    }
                  ]
                }
              ]
            },
            {
              copy:
                "A few years pass and the company posts great results and the share price increases rapidly. ",
              options: [
                {
                  title: "Keep the shares and wait for them to rise again",
                  years: "Math.ceil(yrs*0.4)",
                  to: 4,
                  risk: [],
                  reward: []
                },
                {
                  title: "Sell them while they are high",
                  years: "Math.ceil(yrs*0.4)",
                  to: 5,
                  risk: [],
                  reward: []
                }
              ]
            },
            {
              copy:
                "It is good advice to hold on to good shares . If the company does well then the value of your stock will continue to rise. If it doesn’t you could be in problem.",
              options: [
                {
                  title: "Continue",
                  to: 6,
                  // years: 3,
                  risk: [
                    {
                      title: "FUTURE BAD PERFORMANCE"
                    }
                  ],
                  reward: [
                    {
                      title: "HIGH RATE OF RETURNS"
                    }
                  ]
                }
              ]
            },
            {
              copy:
                "Selling when high ensures you always end up with a gain, You can however lose our on future returns or you might save yourself from future losses",
              options: [
                {
                  title: "Continue",
                  to: 6,
                  // years: 3,
                  risk: [
                    {
                      title: "LOSING OUT ON FUTURE EARNINGS"
                    }
                  ],
                  reward: [
                    {
                      title: "SAFE FROM BAD COMPANY PERFORMANCE"
                    }
                  ]
                }
              ]
            },
            {
              copy:
                "While doing research you hear about a promising company is about to list their shares. How will you begin?",

              options: [
                {
                  title: "Stick with it and hope things get better",
                  to: 7,
                  risk: [],
                  reward: []
                },
                {
                  title: "Sell the shares quickly before things become bad",
                  to: 8,
                  risk: [],
                  reward: []
                }
              ]
            },
            {
              copy:
                "Disaster. The combination of factors results in the share price  dropping rapidly till it is lower than when you bought it.",
              options: [
                {
                  title: "Continue",
                  to: false,
                  // years: 3,
                  risk: [
                    {
                      title: "SHARE PRICE DROPPING DUE TO OUTSIDE FACTORS"
                    }
                  ],
                  reward: []
                }
              ]
            },
            {
              copy:
                "That was close.  You manage to sell quickly but at a price lower than what you would have liked.",
              options: [
                {
                  title: "Continue",
                  to: false,
                  // years: 3,
                  risk: [
                    {
                      title: "SHARE PRICE DROPPING DUE TO OUTSIDE FACTORS"
                    }
                  ],
                  reward: []
                }
              ]
            }
          ]
        },
        {
          name: "Banking",
          icon: money,
          goal: 0,
          years: 1,
          steps: [
            {
              copy:
                "after asking around and researching online you hear that the buzz ",

              options: [
                {
                  title: "Open an account for 10% interest.",
                  to: 1,
                  risk: [],
                  reward: []
                },
                {
                  title: "Stay with the standard 7%",
                  to: 2,
                  risk: [],
                  reward: [{ title: "STABLE INVESTMENT" }]
                }
              ]
            },
            {
              copy:
                "Remember, the greater the promise the higher the risk. Your smaller bank goes the way of Imperial and Chase Bank.",
              options: [
                {
                  title: "Continue",
                  to: false,
                  years: 3,
                  risk: [
                    {
                      title: "BANK CLOSES DOWN WITH YOUR MONEY!!LAWYER FEES"
                    }
                  ],
                  reward: []
                }
              ]
            },
            {
              copy:
                "You read in the news that inflation is going to rise by 2% in the next 4 years",
              options: [
                {
                  title: "Withdraw and save in a unit trust for the period",
                  to: 4,
                  risk: [],
                  reward: []
                },
                {
                  title: "Keep saving in your account at 7%",
                  to: 3,
                  risk: [],
                  reward: []
                }
              ]
            },
            {
              copy:
                "Ksh 1000 today isnt as useful today as it was 5 years ago. With inflation at 9% and interest at 7% your money becomes less  valuable every year.",
              options: [
                {
                  title: "Continue",
                  reward: [],
                  to: 5,
                  risk: [
                    { title: "SAVINGS LOSE VALUE OVER TIME." },
                    { title: " (5 YEARS EARNINGS] KSH" },
                    { title: "INFLATION PROOF INVESTMENT" }
                  ]
                }
              ]
            },
            {
              copy:
                "Good choice. Unit trusts are secured against inflation. So your money grows and doesn’t lose value.",
              options: [
                {
                  title: "Continue",
                  to: false,
                  risk: [],
                  reward: [
                    { title: " [EARNINGS FOR YRS] " },
                    { title: "INFLATION PROOF INVESTMENT" },
                    { title: "2 DAY WITHDRAWAL." }
                  ]
                }
              ]
            },
            {
              copy:
                "An unforseen family emergency occurs and you desperately need to withdraw your savings for medical fees.",
              options: [
                {
                  title: "Withdraw your savings from the fixed deposit",
                  to: 6,
                  risk: [],
                  reward: []
                },
                {
                  title: "Take a loan and use your savings to pay it off.",
                  to: 7,
                  risk: [],
                  reward: []
                }
              ]
            },
            {
              copy:
                "The bank penalises you and you lose your intererst. All that time for nothing.",
              options: [
                {
                  title: "Continue",
                  to: false,
                  risk: [
                    { title: "WITHDRAW EARLY AND LOSE INTEREST" },
                    { title: "PENALTIES & FINES" }
                  ],
                  reward: []
                }
              ]
            },
            {
              copy:
                "You can increase your savings because you are burdenend with a loan.",
              options: [
                {
                  title: "Continue",
                  to: false,
                  risk: [{ title: "NEED LOANS FOR EMERGENCIES." }],
                  reward: []
                }
              ]
            }
          ]
        }
      ],

      results: [],
      years: 0
    };

    window.slide5 = {};

    if (localStorage.slide3) {
      window.slide3 = { ...window.slide3, ...JSON.parse(localStorage.slide3) };
    }

    window.fetchPersistence = () => {
      if (!localStorage.id) return false;
      fetch(window.server + "fetchData.php", {
        method: "POST",
        headers: {
          // "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/x-www-form-urlencoded"
        },
        body: `id=${localStorage.id}`
      })
        .then(response => response.text())
        .then(response => {
          console.log(response);
          if (response === "null") return false;
          if (response === null) return false;
          response = JSON.parse(JSON.parse(response));
          window.calc = response.calc;
          window.store = response.store;
          localStorage.calc = JSON.stringify(response.calc);
          localStorage.step1Options = JSON.stringify(response.store);
        })
        .catch(e => {
          console.log(e);
        });
    };

    window.fetchPersistence();

    window.updatePersistence = () => {
      if (!localStorage.id) return false;
      let data = JSON.stringify({
        calc: window.calc,
        store: window.store,
        slide3: window.slide3
      });
      console.log(data);
      fetch(window.server + "updateData.php", {
        method: "POST",
        headers: {
          // "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/x-www-form-urlencoded"
        },
        body: `id=${localStorage.id}&needs=${window.calc.needs}&wants=${window.calc.wants}&savings=${window.calc.savings}&balance=${window.calc.balance}&earnings=${window.calc.earnings}&data=${data}`
      })
        .then(response => response.text())
        .then(response => {
          //  console.log("updated");
        });
    };
  }
}

function App2() {
  let basename = "";
  // localStorage.id = 1;
  // basename = "7steps";
  return (
    <Router basename={basename}>
      <Route path="/" component={App} />
    </Router>
  );
}

export default App2;
