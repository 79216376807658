import React, { Component } from "react";
import logo from "../../img/logo.png";
import { Link } from "react-router-dom";

import bg_ring from "../../img/icons/Results/bg_ring.svg";

import carret from "../../img/icons/arrow.svg";
import ButtonSm from "../components/buttonSm";
import pig from "../../img/step5/pig.svg";
import target from "../../img/step5/target.svg";
import land from "../../img/step5/land.svg";
import fixedDeposit from "../../img/step5/fixedDeposit.svg";
import shares from "../../img/step5/shares.svg";
import trust from "../../img/step5/trust.svg";
import { TweenMax, Power4 } from "gsap/TweenMax";
import Button from "../components/button";

class S6 extends Component {
  state = {
    ...window.slide4,
    savings: window.calc.savings,
    future: 0,
    day: new Date().getMonth()
  };
  render() {
    return (
      <>
        <div className="d-flex flex-fill slide flex-column align-items-center justify-content-center step-1 default-bg">
          <div className="d-flex flex-column align-items-center text-white text-center">
            <img
              src={logo}
              alt=""
              className="main-logo d-none d-md-block mb-md-4"
            />

            <div className="d-flex flex-row align-items-center tab-title-main">
              <div className="tab-sm d-flex flex-row justify-content-center align-items-center flex-row mr-3">
                <span className="font-weight-bold">04</span>
              </div>
              <div className="text-left">
                YOU CAN WITHDRAW AT ANY TIME WITHOUT PENALTIES
              </div>
            </div>

            <div className="w-250 mt-4 ">
              <div className="custom-divider d-flex flex-row justify-content-center mt-2 mb-2">
                <img src={carret} alt="" />
              </div>

              <div className="mt-4">
                <small>
                  A Unit trust is flexible and allows you to react to lifes ups
                  and downs
                </small>
              </div>

              <div className="pt-3 w-100 total-anim text-center text-gold mt-4 border-bottom-0 mb-0 d-inline-block" />
            </div>

            <div class="mt-2 desc-text">
              <h5 class="">See how fast you can get your savings back</h5>
            </div>

            <div className="my-2 my-md-4 w-100 contain-500">
              <div className="d-flex flex-row justify-content-around w-100 align-items-start">
                <div
                  className="postion-relative my-3 mx-1 mx-md-3"
                  onClick={() =>
                    this.setState({
                      day: new Date().getMonth() + 6,
                      dateText: "3 - 6 MONTHS ON AVERAGE"
                    })
                  }>
                  <div className="d-flex habit-icon justify-content-center flex-column align-items-center position-relative">
                    <img
                      src={bg_ring}
                      alt=""
                      className="align-self-center text-gold habit-ring w-100"
                    />
                    <img
                      src={land}
                      alt=""
                      className="position-absolute align-self-center icon text-dark"
                    />
                  </div>
                  <div className=" habit-title-2 text-center mt-2 text-white">
                    <small>Land</small>
                  </div>
                </div>

                <div
                  onClick={() =>
                    this.setState({
                      day: new Date().getMonth() + 12,
                      dateText: "1 YEAR ON AVERAGE"
                    })
                  }
                  className="postion-relative my-3 mx-1 mx-md-3 d-flex flex-column justify-content-start">
                  <div className="d-flex habit-icon justify-content-center flex-column align-items-center position-relative">
                    <img
                      src={bg_ring}
                      alt=""
                      className="align-self-center text-gold habit-ring w-100"
                    />
                    <img
                      src={fixedDeposit}
                      alt=""
                      className="position-absolute align-self-center icon text-dark"
                    />
                  </div>
                  <div className=" habit-title-2 text-center mt-2 text-white">
                    <small>
                      Fixed <br /> Deposit
                    </small>
                  </div>
                </div>

                <div
                  onClick={() =>
                    this.setState({
                      day: new Date().getMonth() + 12,
                      dateText: "ANNUAL DIVIDENDS IF THE COMPANY DOES WELL"
                    })
                  }
                  className="postion-relative my-3 mx-1 mx-md-3">
                  <div className="d-flex habit-icon justify-content-center flex-column align-items-center position-relative">
                    <img
                      src={bg_ring}
                      alt=""
                      className="align-self-center text-gold habit-ring w-100"
                    />
                    <img
                      src={shares}
                      alt=""
                      className="position-absolute align-self-center icon text-dark"
                    />
                  </div>
                  <div className=" habit-title-2 text-center mt-2 text-white">
                    <small>Shares</small>
                  </div>
                </div>

                <div
                  className="postion-relative my-3 mx-1 mx-md-3"
                  onClick={() =>
                    this.setState({
                      day: new Date().getMonth(),
                      dateText: "MAXIMUM 3 DAYS"
                    })
                  }>
                  <div className="d-flex habit-icon justify-content-center flex-column align-items-center position-relative">
                    <img
                      src={bg_ring}
                      alt=""
                      className="align-self-center text-gold habit-ring w-100"
                    />
                    <img
                      src={trust}
                      alt=""
                      className="position-absolute align-self-center icon text-dark"
                    />
                  </div>
                  <div className=" habit-title-2 text-center mt-2 text-white">
                    <small>Unit trust</small>
                  </div>
                </div>
              </div>
            </div>

            <div className="yearSlider w-100 position-relative">
              <div className="container px-0 position-relative">
                <div className="calendar-marker text-gold d-flex flex-column align-items-center justify-content-between">
                  <span className="marker-text">MONTH</span>
                  <div className="mark" />
                </div>
                <div className="calendar-slider flex-row d-flex">
                  {this.getCalendar(this.state.day)}
                </div>
              </div>
            </div>

            <div className="desc-text text-gold mt-3">
              {this.state.dateText}&nbsp;
            </div>

            <Button
              back={false}
              to="/slide5/step8"
              text="continue"
              className="mt-md-4"
            />
          </div>
        </div>
        <ButtonSm back={false} to="/slide5/step8" text="continue" />
      </>
    );
  }

  componentWillUnmount() {
    window.calc.savings = this.state.savings;
  }

  componentDidMount() {
    // this.calculateFuture();
    this.slide(false);
  }

  getCalendar(active) {
    let items = [];
    for (let i = 0; i < 36; i++) {
      items.push(
        <div
          key={i}
          className={"slider-item " + (i === active ? "active" : "")}>
          {this.months[i]}
        </div>
      );
    }
    this.slide();
    return items;
  }

  slide(animate) {
    let day = this.state.day;
    console.log(day);
    let animTime = 2;
    if (animate === false) {
      animTime = 0;
    }
    TweenMax.to(".calendar-slider", animTime, {
      x: (1 + (0 - day)) * 60,
      ease: Power4.easeInOut
    });
  }

  calculateFuture() {
    // y = 12;
    let p = this.state.savings;
    let r = 0.12;
    let i = 1 + r / 12;

    let G = (i * (Math.pow(i, 60) - 1)) / (i - 1);

    this.setState({ future: parseInt(G * p) });
  }

  months = [
    "JAN",
    "FEB",
    "MAR",
    "APR",
    "MAY",
    "JUN",
    "JUL",
    "AUG",
    "SEP",
    "OCT",
    "NOV",
    "DEC",
    "JAN",
    "FEB",
    "MAR",
    "APR",
    "MAY",
    "JUN",
    "JUL",
    "AUG",
    "SEP",
    "OCT",
    "NOV",
    "DEC",
    "JAN",
    "FEB",
    "MAR",
    "APR",
    "MAY",
    "JUN",
    "JUL",
    "AUG",
    "SEP",
    "OCT",
    "NOV",
    "DEC",
    "JAN",
    "FEB",
    "MAR",
    "APR",
    "MAY",
    "JUN",
    "JUL",
    "AUG",
    "SEP",
    "OCT",
    "NOV",
    "DEC",
    "JAN",
    "FEB",
    "MAR",
    "APR",
    "MAY",
    "JUN",
    "JUL",
    "AUG",
    "SEP",
    "OCT",
    "NOV",
    "DEC",
    "JAN",
    "FEB",
    "MAR",
    "APR",
    "MAY",
    "JUN",
    "JUL",
    "AUG",
    "SEP",
    "OCT",
    "NOV",
    "DEC",
    "JAN",
    "FEB",
    "MAR",
    "APR",
    "MAY",
    "JUN",
    "JUL",
    "AUG",
    "SEP",
    "OCT",
    "NOV",
    "DEC",
    "JAN",
    "FEB",
    "MAR",
    "APR",
    "MAY",
    "JUN",
    "JUL",
    "AUG",
    "SEP",
    "OCT",
    "NOV",
    "DEC"
  ];
}

export default S6;
