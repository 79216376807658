import React, { Component } from "react";
import logo from "../../img/logo.png";
import { Link } from "react-router-dom";

import carret_down from "../../img/icons/carret_down.svg";
import arrow_right from "../../img/arrow_right.png";
import arrow_left from "../../img/icons/arrow_left.svg";
import LoginModal from "../components/loginModal";
import Button from "../components/button";
import ButtonSm from "../components/buttonSm";

class S2 extends Component {
  state = {
    needs: {},
    wants: {},
    modalVisible: false,
    savings: window.calc.savings,
    future: 0,
    input: { amount: window.slide3.years ? window.slide3.years : 5 }
  };
  render() {
    window.slide6.years = window.slide3.years ? window.slide3.years : 5;
    return (
      <>
        <div
          className="d-flex flex-fill slide flex-column align-items-center justify-content-center step-1 default-bg"
          style={{}}>
          <LoginModal
            path="/slide6/step2"
            modalVisible={this.state.loginVisible}
          />

          <div className="d-flex flex-column align-items-center text-white text-center">
            <img src={logo} alt="" className="main-logo" />

            <div className="intro-savings mt-4">
              <h3 class="text-center second-intro-text d-inline-block mb-3">
                <b class="title-text tf">What is your investment journey?</b>
              </h3>
              <div>
                <b>
                  Lets find out what your investment journey will look like once
                  you are done
                </b>
              </div>

              <div className="my-4 d-flex flex-column align-items-center">
                <div className="mb-3">
                  <span>How much can you save a month</span>
                </div>
                <input
                  className="py-2 w-300 text-center text-bronze card-input d-flex flex-fill w-100 undefined"
                  value={
                    this.state.savings
                      ? this.state.savings.toLocaleString()
                      : ""
                  }
                  placeholder="Enter savings"
                  onChange={event => {
                    let savings = parseInt(
                      event.target.value.replace(/,/g, "")
                    );
                    console.log(savings);
                    if (!isNaN(savings)) {
                      //    window.calc.savings = savings;
                      this.setState({ savings: savings });
                    }
                  }}
                />
              </div>
            </div>

            <div className="mb-3">
              <span>For how long</span>
            </div>

            <div className="d-flex flex-row align-items-center col-md-6 mt-md-0 year-input w-300">
              <input
                type="text"
                name=""
                class="py-2 d-flex amt-i flex-fill px-3 text-left text-bronze card-input no-anim d-flex input w-100"
                value={this.state.input.amount.toLocaleString() + " Years"}
                placeholder="How much was it?"
                disabled
                // onChange={event => {
                //  let amount = parseInt(event.target.value.replace(/,/g, ""));
                //  // console.log(amount);
                //  if (!isNaN(amount)) {
                //   this.setState({ input: { ...this.state.input, amount } });
                //  }
                //  if (event.target.value === "") {
                //   this.setState({ input: { ...this.state.input, amount: 0 } });
                //  }
                // }}
              />
              <div className="d-flex flex-row bg-white toggle-button-parent">
                <button
                  className="btn toggle-button"
                  onClick={() => {
                    let amount = this.state.input.amount;
                    if (amount === "") amount = 0;
                    amount -= 1;
                    if (amount < 0 && amount < 0) return false;
                    window.slide3.years = amount;
                    this.setState({ input: { ...this.state.input, amount } });
                  }}>
                  -
                </button>
                <button
                  className="btn toggle-button"
                  onClick={() => {
                    let amount = this.state.input.amount;
                    if (amount === "") amount = 0;
                    amount += 1;
                    if (amount < 0) return false;
                    window.slide3.years = amount;
                    this.setState({ input: { ...this.state.input, amount } });
                  }}>
                  +
                </button>
              </div>
            </div>

            <div className="mt-md-5">
              <Button back={false} to="/slide6/step3/0" />
            </div>
          </div>
        </div>
        <ButtonSm back={false} to={"/slide6/step3/0"} />
      </>
    );
  }

  componentDidMount() {
    this.calculateFuture();
  }

  componentWillUnmount() {
    window.calc.savings = this.state.savings;
    window.calc.future = this.state.future;
    window.slide6.years = this.state.input.amount;
    console.log("unmounting ", window.calc.savings, window.calc.future);
  }

  calculateFuture() {
    // y = 12;
    let p = this.state.savings;
    let r = 0.12;
    let i = 1 + r / 12;

    let G = (i * (Math.pow(i, 60) - 1)) / (i - 1);

    this.setState({ future: parseInt(G * p) });
  }
}

export default S2;
