import React, { Component } from "react";
import { Link } from "react-router-dom";
import arrow_bottom from "../img/arrow_bottom.svg";

// images import
import logo from "../img/logo.png";
import twitter_icon from "../img/twitter_icon.png";
import facebook_icon from "../img/facebook_icon.png";
import youtube_icon from "../img/youtube_icon.png";
import weigh_icon from "../img/weigh.png";
import coins_icon from "../img/coins.png";
import book_icon from "../img/book.png";
import play_icon from "../img/play_icon.png";
import amana_logo from "../img/amana-logo.png";
import VideoModal from "./components/videoModal";

import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Nav from "./components/nav";
import Footer from "./components/Footer";

class LandingPage extends Component {
 state = {};
 render() {
  return (
   <main>
    <Nav responsiveLogo={true} />
    <VideoModal modalVisible={this.state.modalVisible} link="Rhm9ztAoSVk" />
    <section className="section position-relative bg-dark banner-section d-flex flex-column justify-content-center">
     <div className="d-flex flex-column justify-content-center landing-page-bg">
      <div className="container my-md-5">
       <div className="d-flex flex-column text-white text-center align-items-center">
        <img src={logo} alt="" className="main-logo mb-3" />
        <div>
         <h1 className="text-white my-3 header-title d-inline-block landing-title">
          <b>Start your journey with us now</b>
         </h1>
         <p className="header-content font-weight-bold dxx-2/">
          In tough economic times it can be hard to think of being financially
          financially stable. Join us as we take you on a journey to discover 7
          lessons that will help you achieve financial freedom.
         </p>
         <div className="d-inline-block hover-anim">
          <Link
           to="./video"
           className="btn d-flex flex-row align-items-center"
           onClick={() => this.setState({ modalVisible: true })}
          >
           <img src={play_icon} className="play-icon" alt="" />
           <small className="font-weight-bold ml-2 text-white">
            WATCH VIDEO
           </small>
          </Link>
         </div>
         <div className="pt-4">
          <Link to="/slide1/" className="btn btn-primary px-3 py-1">
           <div className="text-white  my-1">
            <small className="font-weight-bold">START NOW</small>
           </div>
          </Link>
         </div>
        </div>

        {/* <img src={logo} alt="" className="main-logo" style={{ opacity: 0 }} /> */}
       </div>
      </div>
      <img src={arrow_bottom} className="imageB d-md-none" alt="" />
     </div>
    </section>

    <section className="section">
     <div className="container text-center my-0 py-5">
      <div className="d-inline-block">
       <h2 className="font-weight-light">What are the 7 steps</h2>

       <p className="steps-text mt-3 mb-2">
        As one of the top 3 investment managers in Kenya we have observed 7 key
        steps that successful individuals use to become wealthy.
       </p>
       <p className="steps-text mb-3">
        Now its your turn to join Willis and Mary, a normal hustling Kenyan
        family as they learn their 7 steps to the life they desire.
       </p>
       <div
        className="d-inline-block"
        onClick={() => {
         window.scrollTo(0, 0);
        }}
       >
        <Link to="/slide1/" className="btn btn-primary px-5 py-2">
         <div className="text-white  my-1">
          <small className="font-weight-bold">TAKE THE FIRST STEP</small>
         </div>
        </Link>
       </div>
      </div>
     </div>
    </section>

    <section id="schedule">
     <div className="bg-secondary overflow-hidden">
      <div className="container text-white">
       <div className="_row ">
        <OwlCarousel
         className="owl-theme"
         margin={0}
         responsive={{
          0: {
           items: 1,
           nav: true
          },
          600: {
           items: 3,
           nav: false
          }
         }}
         nav={false}
        >
         <div className="_col-md-4 p-3 py-5">
          <div className="d-flex flex-row align-items-center mt-4">
           <small className="">
            <b>LESSON &nbsp; 1 </b>
           </small>
           <div className="separator mx-3  bg-white" />
           <small className="">
            <b>2</b>
           </small>
          </div>
          <div className="my-2">
           <span className="text-primary-light d-block">
            <b>PREPARE TO SAVE</b>
           </span>
           <div className="my-2">
            Analyse your financial habits and discover how to make the most out
            of what you have.
            <br />
            <br />
           </div>
           <div className="list my-3">
            <div className="my-2 list-text">
             <i className="fas fa-check mr-3 check-icon" />
             Create a bugdet that balances your priorities
            </div>
            <div className="my-2 list-text">
             <i className="fas fa-check mr-3 check-icon" />
             Discover how to raise extra cash for savings
            </div>
            <div className="my-2 list-text">
             <i className="fas fa-check mr-3 check-icon" />
             Learn how to spend wisely
             <br />
             <br />
            </div>
           </div>

           <Link
            to="/slide1"
            className="btn btn-primary px-3 pr-5 py-2 mt-3 bg-white border-0"
           >
            <div className="mr-5 text-white ml-2 my-1">
             <b className="text-sm text-primary-light border-0">START NOW</b>
            </div>
           </Link>
          </div>
         </div>

         <div className="_col-md-4 p-3 py-5">
          <div className="muted py-4">
           <div className="">
            <div className="d-flex flex-row align-items-center">
             <small className="">
              <b>LESSON &nbsp; 3 </b>
             </small>
             <div className="separator mx-3  bg-white" />
             <small className="">
              <b>5</b>
             </small>
            </div>
            <div className="my-2">
             <span className="text-primary-light d-block text-uppercase">
              <b>How to make your money work</b>
             </span>
             <div className="my-2">
              Learn about all the financial options available to you and learn
              how to choose what suits your goal.
             </div>
             <div className="list my-3">
              <div className="my-2 list-text">
               <i className="fas fa-check mr-3 check-icon" />
               Learn how to turn your life goals into financial goals.
              </div>
              <div className="my-2 list-text">
               <i className="fas fa-check mr-3 check-icon" />
               Discover the investment options that work best for achieving your
               goal.
              </div>
              <div className="my-2 list-text">
               <i className="fas fa-check mr-3 check-icon" />
               Learn how to balance your risk and reward.
              </div>
             </div>
            </div>
           </div>

           <Link
            to="/slide3"
            className="btn btn-primary px-3 pr-5 py-2 mt-3 bg-white border-0"
           >
            <div className="mr-5 text-white ml-2 my-1">
             <b className="text-sm text-primary-light border-0">START NOW</b>
            </div>
           </Link>
          </div>
         </div>

         <div className="_col-md-4 p-3 py-5 overflow-x-visible">
          <div className="muted py-4">
           <div className="">
            <div className="d-flex flex-row align-items-center">
             <small className="">
              <b>LESSON &nbsp; 6 </b>
             </small>
             <div className="separator mx-3  bg-white" />
             <small className="">
              <b>7</b>
             </small>
            </div>
            <div className="my-2">
             <span className="text-primary-light d-block">
              <b className="text-uppercase">Stay the course to wealth</b>
             </span>
             <div className="my-2">
              Its not enough to just save we need to understand how to make it
              the top and stay there.
             </div>
             <div className="list my-3">
              <div className="my-2 list-text">
               <i className="fas fa-check mr-3 check-icon" />
               Discover the habits that keep you on your course to wealth.
              </div>
              <div className="my-2 list-text">
               <i className="fas fa-check mr-3 check-icon" />
               Learn tips and tricks from successful investors.
              </div>
              <div className="my-2 list-text">
               <i className="fas fa-check mr-3 check-icon" />
               Get help from industry experts.
              </div>
             </div>
            </div>
           </div>

           <div className="pt-3 elevate">
            <div className="text-primary-light">
             <b>STARTS ON</b>
            </div>
            <div>
             <small>
              <b>19/08/2019</b>
             </small>
            </div>
           </div>
          </div>
         </div>
        </OwlCarousel>
       </div>
      </div>
     </div>
    </section>

    <section className="section">
     <div className="container text-center my-0 py-5">
      <div className="d-inline-block w-100">
       <h1 className="font-weight-light">Useful tools you will get</h1>

       <div className="text-center my-0 py-5">
        <OwlCarousel
         className="owl-theme"
         margin={0}
         responsive={{
          0: {
           items: 1,
           nav: true
          },
          600: {
           items: 3,
           nav: false
          }
         }}
         nav
        >
         <Link
          to="/slide1"
          className="item card px-4 mx-2 d-inline-block py-5 tool-card "
         >
          <div className="d-flex w-100 align-items-center flex-column">
           <div className="icon-holder d-inline- d-flex align-items-center justify-content-center">
            <img src={weigh_icon} className="card-icon" alt="" />
           </div>
          </div>
          <div className="my-2 text-short">
           <small className="text-primary text-short">
            <b>A FINANCIAL HEALTH CHECKER</b>
           </small>
          </div>
          <div className="text-short">
           <small>
            <span className="text-secondary">
             Find out how to budget for growth
            </span>
           </small>
          </div>
         </Link>

         <Link
          to="/slide1"
          className="item card px-4 mx-2 d-inline-block py-5 tool-card "
         >
          <div className="d-flex w-100 align-items-center flex-column">
           <div className="icon-holder d-inline- d-flex align-items-center justify-content-center">
            <img src={coins_icon} className="card-icon" alt="" />
           </div>
          </div>
          <div className="my-2 text-short">
           <small className="text-primary text-short">
            <b>A FINANCIAL EXPENSE REDUCER</b>
           </small>
          </div>
          <div className="text-short">
           <small>
            <span className="text-secondary">
             Find out how to free up cash for saving
            </span>
           </small>
          </div>
         </Link>

         <Link
          to="/slide1"
          className="item card px-4 mx-2 d-inline-block py-5 tool-card "
         >
          <div className="d-flex w-100 align-items-center flex-column">
           <div className="icon-holder d-inline- d-flex align-items-center justify-content-center">
            <img src={book_icon} className="card-icon" alt="" />
           </div>
          </div>
          <div className="my-2 text-short">
           <small className="text-primary text-short">
            <b>
             A BUDGET <br /> PLANNER
            </b>
           </small>
          </div>
          <div className="text-short">
           <small>
            <span className="text-secondary">
             A downloadable plan for you to follow.
            </span>
           </small>
          </div>
         </Link>
        </OwlCarousel>
       </div>
       <div
        className="d-inline-block"
        onClick={() => {
         window.scrollTo(0, 0);
        }}
       >
        <Link to="/slide1/" className="btn btn-primary px-5 py-2">
         <div className="text-white  my-1">
          <small className="font-weight-bold">LET'S BEGIN</small>
         </div>
        </Link>
       </div>
      </div>
     </div>
    </section>

    <section className="section bg-black">
     <div className="py-3 chest-section">
      <div className="container text-center py-5 my-5">
       <div className="d-inline-block learn-container">
        <h3 className="text-white bbtlxy font-weight-bold">
         The journey to the life you desire is only one step away. <br /> Begin
         your 7 steps now.
        </h3>

        <Link to="/slide1/" className="btn btn-primary px-5 py-2 mt-5">
         <div
          className="text-white  my-1"
          onClick={() => {
           window.scrollTo(0, 0);
          }}
         >
          <small className="font-weight-bold">TAKE THE FIRST STEP.</small>
         </div>
        </Link>
       </div>
      </div>
     </div>
    </section>

    <Footer />
   </main>
  );
 }
}

export default LandingPage;
