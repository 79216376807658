import React, { Component } from "react";
import logo from "../../img/logo.png";
import { Link } from "react-router-dom";

import bg_ring from "../../img/icons/Results/bg_ring.svg";
import arrow from "../../img/icons/carret_up.svg";
import LoginModal from "../components/loginModal";
import StartUp from "../../img/icons/goals/StartUp.svg";

import Hex from "../../img/icons/options/hexagon.svg";
import flexible from "../../img/step5/flexibility.svg";
import quick from "../../img/step5/quick.svg";
import suitcase from "../../img/step5/case.svg";
import trend from "../../img/step5/trend.svg";
import carret from "../../img/icons/arrow.svg";
import ButtonSm from "../components/buttonSm";
import Button from "../components/button";

class S2 extends Component {
  state = {
    savings: window.calc.savings
  };
  render() {
    return (
      <>
        <div
          className="d-flex flex-fill slide flex-column align-items-center justify-content-center step-1 default-bg"
          style={{}}>
          <LoginModal
            path="/slide4/step2"
            modalVisible={this.state.loginVisible}
          />

          <div className="d-flex flex-column align-items-center text-white text-center">
            <img
              src={logo}
              alt=""
              className="main-logo d-none d-md-block mb-md-3"
            />

            <div class="mt-4 mt-md-5">
              <h4 class="lts font-weight-bold">4 REASONS</h4>
              <span class="text-white px-3 d-inline-block desc-text">
                Why you should consider a unit trust
              </span>
            </div>

            <div className="custom-divider d-flex flex-row justify-content-center mt-4 mb-2">
              <img src={carret} alt="" />
            </div>

            <div className="mt-4 w-100">
              <div className="py-3 d-flex flex-row flex-wrap justify-content-around w-100 mb-4 mb-md-0 mt-md-5 position-relative">
                <div className="rep mx-md-4">
                  <div className="d-flex flex-column justify-content-center align-items-center">
                    <div className="position-relative">
                      <img src={Hex} alt="" className="rep-hex" />
                      <img
                        src={flexible}
                        alt=""
                        className="rep-hex-icon position-absolute"
                      />
                      <img src={flexible} alt="" className="rep-hex-icon" />
                    </div>
                    <div className="text-white rep-hex-text">Flexibiity</div>
                  </div>
                </div>

                <div className="rep mx-md-4">
                  <div className="d-flex flex-column justify-content-center align-items-center">
                    <div className="position-relative">
                      <img src={Hex} alt="" className="rep-hex" />
                      <img
                        src={trend}
                        alt=""
                        className="rep-hex-icon position-absolute"
                      />
                      <img src={trend} alt="" className="rep-hex-icon" />
                    </div>
                    <div className="text-white rep-hex-text">High Returns</div>
                  </div>
                </div>
                {/* </div>
              <div className="py-3 d-flex flex-row flex-wrap justify-content-around "> */}
                <div className="rep mx-md-4">
                  <div className="d-flex flex-column justify-content-center align-items-center">
                    <div className="position-relative">
                      <img src={Hex} alt="" className="rep-hex" />
                      <img
                        src={quick}
                        alt=""
                        className="rep-hex-icon position-absolute"
                      />
                      <img src={quick} alt="" className="rep-hex-icon" />
                    </div>
                    <div className="text-white rep-hex-text">
                      Quick Withdrawal
                    </div>
                  </div>
                </div>

                <div className="rep mx-md-4">
                  <div className="d-flex flex-column justify-content-center align-items-center">
                    <div className="position-relative">
                      <img src={Hex} alt="" className="rep-hex" />
                      <img
                        src={suitcase}
                        alt=""
                        className="rep-hex-icon position-absolute"
                      />
                      <img src={suitcase} alt="" className="rep-hex-icon" />
                    </div>
                    <div className="text-white rep-hex-text">
                      Expertly Managed
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <Button
              back={false}
              to="/slide5/step3"
              className="mt-md-5"
              text="continue"
            />
          </div>
        </div>
        <ButtonSm back={false} to="/slide5/step3" text="continue" />
      </>
    );
  }

  componentWillUnmount() {
    window.calc.savings = this.state.savings;
  }
}

export default S2;
