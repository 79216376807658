import React, { Component } from "react";
import logo from "../../img/logo.png";

import VideoModal from "../components/videoModal";
import consolation_spender from "../../img/icons/Results/consolation_spender.svg";
import Deal_hunter from "../../img/icons/Results/Deal_hunter.svg";
import Focused from "../../img/icons/Results/Focused.svg";
import Impulse_buyer from "../../img/icons/Results/Impulse_buyer.svg";
import keeping_itreal from "../../img/icons/Results/keeping_itreal.svg";
import list_maker from "../../img/icons/Results/list_maker.svg";
import livingitup from "../../img/icons/Results/livingitup.svg";
import long_arrow from "../../img/icons/Results/long_arrow.svg";
import necessity_spender from "../../img/icons/Results/necessity_spender.svg";
import bg_ring from "../../img/icons/Results/bg_ring.svg";
import Button from "../components/button";
import ButtonSm from "../components/buttonSm";

class S2_5 extends Component {
 state = {
  options: [
   { icon: Impulse_buyer, title: "Impulse Buyer" },
   { icon: consolation_spender, title: "Consultation Spender" },
   { icon: necessity_spender, title: "Necessity Spender" },
   { icon: Deal_hunter, title: "Deal Hunter" },
   //    { icon: Deal_hunter, title: "Deal Hunter" },
   { icon: Focused, title: "Focused" }
  ]
 };
 render() {
  return (
   <>
    <div
     className="d-flex flex-fill slide flex-column align-items-center justify-content-center step-1 default-bg"
     style={{}}
    >
     <div className="d-flex flex-column align-items-center text-white text-center">
      <img src={logo} alt="" className="main-logo" />

      <div className="mt-4 mt-md-5">
       <h5 className="lts font-weight-bold">What kind of spender are you?</h5>
       <span className="text-white px-3 d-inline-block desc-text">
        Lets find out how your spending habits affect your savings.
       </span>
      </div>

      <div className="habits container d-flex flex-row flex-wrap justify-content-around my-4 my-md5">
       {this.state.options.map(d => (
        <div className="postion-relative my-3 mx-1 mx-md-3">
         <div className="d-flex justify-content-center flex-column align-items-center position-relative">
          <img src={bg_ring} alt="" className="align-self-center text-gold" />
          <img
           src={d.icon}
           alt=""
           className="position-absolute align-self-center icon text-dark"
          />
         </div>
         <div className=" habit-title text-center mt-3">
          <b> {d.title} </b>
         </div>
        </div>
       ))}
      </div>

      <Button back={false} to="/slide2/step4/0" text="Let's find out" />
     </div>
    </div>
    <ButtonSm back={false} to="/slide2/step4/0" text="Let's find out" />
   </>
  );
 }
}

export default S2_5;
