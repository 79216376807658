import React, { Component } from "react";
import logo from "../../img/logo.png";
import Card from "../components/card";
import Button from "../components/button";
import OwlCarousel from "react-owl-carousel";
import ButtonSm from "../components/buttonSm";

// images
import rent from "../../img/icons/rent.svg";
import groceries from "../../img/icons/Groceries.svg";
import transport from "../../img/icons/Transport.svg";
import utilities from "../../img/icons/Utilities.svg";
import HoverCard from "../components/hoverCard";
import swipeIcon from "../../img/icons/swipe.svg";
import Counter from "../components/counter";
import Tabs from "../components/tabs";

class Step1 extends Component {
 state = { needs: {}, wants: {} };
 render() {
  return (
   <>
    <div className="slide d-flex flex-fill flex-column align-items-center justify-content-center step-1 default-bg w-100">
     <HoverCard />

     <div className="d-flex flex-column align-items-center text-white text-center w-100">
      {/* <Tabs /> */}
      <img src={logo} alt="" className="main-logo d-xsm mb-md-3" />
      {/* <Counter current={2} total={6} /> */}

      <div className="intro-text">
       <Tabs active="needs" />
       <h3 className="mt-3 d-inline-block mb-3 my-md-5">
        <b className="title-text tf">Estimate your needs below</b>
       </h3>
      </div>

      <div className="text-center mt-0 w-100 px-md-5 container card-scroll px-0">
       <div className="px-md-5 mx-md-5 d-flex flex-row justify-content-md-center">
        <Card
         icon={rent}
         title="RENT"
         subtitle="How much is for rent or mortgages"
         value={this.state.needs}
         getValue={value => {
          this.setState({ needs: { ...this.state.needs, ...value } });
         }}
         max={4}
         current={1}
        />
        <Card
         icon={groceries}
         title="HOUSEHOLD"
         subtitle="Food, household items and other necessities"
         value={this.state.needs}
         getValue={value => {
          this.setState({ needs: { ...this.state.needs, ...value } });
         }}
         max={4}
         current={2}
        />

        <Card
         icon={transport}
         title="FAMILY"
         subtitle="What the children or family need monthly"
         value={this.state.needs}
         getValue={value => {
          this.setState({ needs: { ...this.state.needs, ...value } });
          // console.log(this.state.needs);
         }}
         max={4}
         current={3}
        />
        <Card
         icon={utilities}
         title="UTILITIES"
         subtitle="Fuel, Electricity, water, internet/data etc"
         value={this.state.needs}
         getValue={value => {
          this.setState({ needs: { ...this.state.needs, ...value } });
          // console.log(this.state.needs);
         }}
         max={4}
         current={4}
        />
       </div>
      </div>
      <div className="d-md-none d-inline-block mt-3">
       <img src={swipeIcon} alt="" />
       <small className="d-block font-weight-light mt-1 swipe-text">
        Swipe to view more
       </small>
      </div>

      <div className="mt-5 d-n">
       <Button to="/slide1/step1_5" onClick={() => this.update()} />
      </div>
     </div>
    </div>
    <ButtonSm to="/slide1/step1_5" onClick={() => this.update()} />
   </>
  );
 }

 componentDidMount() {
  this.setState({ ...window.store });
 }

 update = () => {
  window.store.needs = { ...window.store.needs, ...this.state.needs };
  localStorage.step1Options = JSON.stringify(this.state);
  let n = this.state.needs;
  let w = this.state.wants;
  let needs = 0;
  let wants = 0;
  Object.keys(n).map(d => {
   needs += n[d];
  });
  Object.keys(w).map(d => {
   wants += w[d];
  });

  window.calc = { ...window.calc, needs, wants };
  if (!localStorage.calc) {
   localStorage.calc = "{}";
  }
  localStorage.calc = JSON.stringify({
   ...JSON.parse(localStorage.calc),
   ...window.calc
  });
 };
}

export default Step1;
