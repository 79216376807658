import React, { Component } from "react";
import logo from "../../img/logo.png";
import { Link } from "react-router-dom";

import bg_ring from "../../img/icons/Results/bg_ring.svg";
import arrow from "../../img/icons/carret_up.svg";
import LoginModal from "../components/loginModal";
import StartUp from "../../img/icons/goals/StartUp.svg";

import S3 from "../slide3/s3";

class S2 extends Component {
  state = {
    ...window.slide4,
    savings: window.calc.savings
  };
  render() {
    return (
      <>
        <div
          className="d-flex flex-fill slide flex-column align-items-center justify-content-center step-1 default-bg"
          style={{}}>
          <LoginModal
            path="/slide4/step2"
            modalVisible={this.state.loginVisible}
          />

          <div className="d-flex flex-column align-items-center text-white text-center">
            <img src={logo} alt="" className="main-logo d-none d-md-block" />

            <div className="mt-4 mt-md-5">
              <span className="text-white px-3 d-inline-block font-weight-bold">
                How much can you save a month?
              </span>

              <div className="my-4">
                <input
                  className="py-1 text-center text-bronze card-input d-flex flex-fill w-100 undefined"
                  value={
                    this.state.savings
                      ? this.state.savings.toLocaleString()
                      : ""
                  }
                  placeholder="Enter savings"
                  onChange={event => {
                    let savings = parseInt(
                      event.target.value.replace(/,/g, "")
                    );
                    console.log(savings);
                    if (!isNaN(savings)) {
                      this.setState({ savings: savings });
                    }
                  }}
                />
              </div>

              <span className="text-white px-3 d-inline-block font-weight-bold">
                Choose from the options below
              </span>
            </div>

            <div className="habits container d-flex flex-row flex-wrap justify-content-around my-4 my-md5">
              {this.state.data.map((d, i) => (
                <Link
                  to={`/slide4/step3/${i}`}
                  className="postion-relative my-3 mx-1 mx-md-3"
                  onClick={() => {
                    window.slide3.active = i;
                    localStorage.slide3 = JSON.stringify(window.slide3);
                  }}>
                  <div className="d-flex justify-content-center flex-column align-items-center position-relative">
                    <img
                      src={bg_ring}
                      alt=""
                      className="align-self-center text-gold"
                    />
                    <img
                      src={d.icon}
                      alt=""
                      className="position-absolute align-self-center icon text-dark"
                    />
                  </div>
                  <div className=" habit-title text-center mt-3 text-white">
                    {d.name}
                  </div>
                </Link>
              ))}
            </div>

            <div className="flex-column justify-content-center d-flex mt-0">
              <img src={arrow} alt="" />
              <small className="mt-2 font-weight-bold text-sm">
                CHOOSE TO CONTINUE
              </small>
            </div>

            {/* <Button back={false} to="/slide3/step3" text="continue" /> */}
          </div>
        </div>
        {/* <ButtonSm back={false} to="/slide3/step3" text="continue" /> */}
      </>
    );
  }

  componentWillUnmount() {
    window.calc.savings = this.state.savings;
  }
}

export default S2;
