import React, { Component } from "react";
import logo from "../../img/logo.png";
import { Link } from "react-router-dom";

import bg_ring from "../../img/icons/Results/bg_ring.svg";
import arrow from "../../img/icons/carret_up.svg";
import LoginModal from "../components/loginModal";
import StartUp from "../../img/icons/goals/StartUp.svg";

import Hex from "../../img/icons/options/hexagon.svg";
import flexible from "../../img/step5/flexibility.svg";
import quick from "../../img/step5/quick.svg";
import suitcase from "../../img/step5/case.svg";
import trend from "../../img/step5/trend.svg";
import pig from "../../img/step5/pig.svg";
import target from "../../img/step5/target.svg";
import wallet from "../../img/step5/wallet.svg";
import ButtonSm from "../components/buttonSm";
import Button from "../components/button";

class S3 extends Component {
  state = {
    ...window.slide4,
    savings: window.calc.savings
  };
  render() {
    return (
      <>
        <div className="d-flex flex-fill slide flex-column align-items-center justify-content-center step-1 default-bg">
          <div className="d-flex flex-column align-items-center text-white text-center">
            <img
              src={logo}
              alt=""
              className="main-logo d-none d-md-block mb-md-3"
            />

            <div className="d-flex flex-row tab-title-main">
              <div className="tab-sm d-flex flex-row justify-content-center align-items-center flex-row mr-3">
                <span className="font-weight-bold">01</span>
              </div>
              <div className="text-left">
                It is flexible enough to meet your needs
              </div>
            </div>

            <div class="mt-4 mt-md-5">
              <h5 class="lts font-weight-bold">What are your needs?</h5>
            </div>

            <div className="my-3 w-100">
              <div className="d-flex flex-row justify-content-around w-100">
                <div
                  onClick={() => this.setGoal(1)}
                  to="/slide3/step2_5"
                  className="postion-relative my-3 mx-1 mx-md-3">
                  <div className="d-flex justify-content-center flex-column align-items-center position-relative">
                    <img
                      src={bg_ring}
                      alt=""
                      className="align-self-center text-gold"
                    />
                    <img
                      src={target}
                      alt=""
                      className="position-absolute align-self-center icon text-dark"
                    />
                  </div>
                  <div className=" habit-title text-center mt-3 text-white">
                    A goal
                  </div>
                </div>

                <div
                  onClick={() => this.setGoal(2)}
                  to="/slide3/step2_5"
                  className="postion-relative my-3 mx-1 mx-md-3">
                  <div className="d-flex justify-content-center flex-column align-items-center position-relative">
                    <img
                      src={bg_ring}
                      alt=""
                      className="align-self-center text-gold"
                    />
                    <img
                      src={pig}
                      alt=""
                      className="position-absolute align-self-center icon text-dark"
                    />
                  </div>
                  <div className=" habit-title text-center mt-3 text-white">
                    Passive Income
                  </div>
                </div>

                <div
                  onClick={() => this.setGoal(3)}
                  to="/slide3/step2_5"
                  className="postion-relative my-3 mx-1 mx-md-3">
                  <div className="d-flex justify-content-center flex-column align-items-center position-relative">
                    <img
                      src={bg_ring}
                      alt=""
                      className="align-self-center text-gold"
                    />
                    <img
                      src={wallet}
                      alt=""
                      className="position-absolute align-self-center icon text-dark"
                    />
                  </div>
                  <div className=" habit-title text-center mt-3 text-white">
                    Both for income & a goal
                  </div>
                </div>
              </div>
            </div>

            <div className="desc-text mb-3 w-300">
              <h5 className="px-3">
                <b>For how long do you want to save.</b>
              </h5>
              <div className="my-4">
                <select
                  className="py-1 px-3 text-center text-bronze card-input form-control d-flex flex-fill w-100 undefined"
                  placeholder="Enter savings"
                  onChange={e => this.setTime(parseInt(e.target.value))}>
                  <option value="1" hidden selected>
                    Select Duration
                  </option>
                  <option value="1">Less than 5 years</option>
                  <option value="2">More than 5 years</option>
                  <option value="3">Retirement</option>
                </select>
              </div>
            </div>

            {!(this.state.goal && this.state.time) && (
              <div className="flex-column justify-content-center d-flex mt-0">
                <img src={arrow} alt="" />
                <small className="mt-2 font-weight-bold text-sm">
                  CHOOSE TO CONTINUE
                </small>
              </div>
            )}

            {this.state.goal && this.state.time && (
              <Button
                back={false}
                to={"/slide5/step4/" + this.getIndex()}
                text="continue"
              />
            )}
          </div>
        </div>
        {this.state.goal && this.state.time && (
          <ButtonSm
            back={false}
            to={"/slide5/step4/" + this.getIndex()}
            text="continue"
          />
        )}
      </>
    );
  }

  componentWillUnmount() {
    window.calc.savings = this.state.savings;
  }

  setGoal = i => {
    this.setState({ goal: i });
    window.slide5.goal = i;
  };

  setTime(i) {
    this.setState({ time: i });
    window.slide5.time = i;
  }

  getIndex = () => {
    let index;
    let { goal } = this.state;
    let { time } = this.state;

    // if ((goal === 1 && time === 2) || time === 3) {
    //   index = 1;
    // } else if (goal === 3 || (goal === 3 && time === 3)) {
    //   index = 2;
    // } else if (goal === 2 && time === 3) {
    //   index = 3;
    // } else if (goal === 2 && time != 3) {
    //   index = 0;
    // } else {
    //   index = 0;
    // }
    if (goal === 1 && time === 1) {
      index = 2;
    } else if (goal === 1 && time !== 1) {
      index = 1;
    } else if (goal === 2 && time === 3) {
      index = 3;
    } else if (goal === 2 && time !== 3) {
      index = 0;
    } else if (goal === 3 && time === 3) {
      index = 3;
    } else if (goal === 3 && time !== 3) {
      index = 2;
    }

    window.slide5.product = index;

    return index;
  };
}

export default S3;
