import React, { Component } from "react";

class Modal extends Component {
  state = {};
  render() {
    return (
      <div
        class={
          "_modal d-flex flex-column justify-content-center align-items-center p-2 " +
          (this.props.show ? "show" : "")
        }
      >
        <div className="modal-inner position-relative d-flex flex-column justify-content-center align-items-center">
          {/* <div className="modal-overlay" /> */}
          {this.props.children}
        </div>
      </div>
    );
  }
}

export default Modal;
