import React, { Component } from "react";
import arrow from "../../img/icons/carret_up.svg";
import { Link } from "react-router-dom";
import ButtonSm from "../components/buttonSm";
import logo from "../../img/logo.png";
import Button from "../components/button";
import pointer from "../../img/step4/pointer.svg";

class S4 extends Component {
  state = { options: [] };

  render() {
    let letters = ["A", "B", "C", "D", "E", "F"];
    return (
      <>
        <div className="d-flex flex-fill flex-column align-items-center justify-content-center step-1 default-bg cover">
          <div className="d-flex flex-column align-items-center text-white text-center w-100">
            <img
              src={logo}
              alt=""
              className="main-logo d-none d-md-block mb-5"
            />

            <div className="w-100 d-flex flex-column align-items-center mt-4 mb-3">
              <div className="px-3 py-2 info-block desc-text b20 pt-md-3 round position-relative">
                <div className="d-flex flex-row justify-content-center top-bar">
                  {/* <div className="text-right">RISK</div> */}
                  <div className="font-weight-bold px-5 text-center text-uppercase">
                    {window.slide4.data[this.props.parent].name}
                  </div>
                  {/* <div className="text-left">REWARD</div> */}
                  <img src={pointer} alt="" className="property-pointer" />
                </div>
              </div>
            </div>

            <div className="text-center desc-text mt-3">{this.state.copy}</div>

            <div className="mt-3">
              {!this.state.singleStand && (
                <div className="px-3">
                  {this.state.options.map((d, i) => (
                    <div
                      className="d-flex flex-row align-items-center my-3"
                      key={i}>
                      <div className="mr-3">{letters[i]}.</div>
                      <Link
                        onClick={() => this.addRisk(d, i)}
                        to={
                          d.risk.length === 0 && d.reward.length === 0
                            ? `/slide4/step4/${this.props.parent}/${d.to}`
                            : `/slide4/step6/${this.props.parent}/${
                                this.props.index
                              }`
                        }
                        className="p-1 px-3 text-gold property-option text-left d-flex flex-fill">
                        {d.title}
                      </Link>
                    </div>
                  ))}
                </div>
              )}

              {!this.state.singleStand && (
                <div className="flex-column justify-content-center d-flex mt-5">
                  <img src={arrow} alt="" />
                  <small className="text-primary mt-2 font-weight-bold text-sm">
                    CHOOSE TO CONTINUE
                  </small>
                </div>
              )}
            </div>

            {this.state.singleStand && (
              <div className="mt-5">
                <Button
                  onClick={() => this.addRisk(this.state.options[0], 0)}
                  to={`/slide4/step6/${this.props.parent}/${this.props.index}`}
                  back={false}
                />
              </div>
            )}
          </div>
        </div>
        {this.state.singleStand && (
          <ButtonSm
            onClick={() => this.addRisk(this.state.options[0], 0)}
            to={`/slide4/step6/${this.props.parent}/${this.props.index}`}
            back={false}
          />
        )}
      </>
    );
  }

  componentDidMount = () => {
    console.log(window.slide4.data[this.props.parent].steps[this.props.index]);
    setTimeout(() => {
      this.setState({
        ...window.slide4.data[this.props.parent].steps[this.props.index],
        singleStand:
          window.slide4.data[this.props.parent].steps[this.props.index]
            .options[0].title === "Continue"
      });
    }, 0);
  };

  addRisk = (d, i) => {
    // return;
    // if (!(d.risk.length === 0 && d.reward.length === 0)) {
    if (!window.slide4.results[this.props.parent])
      window.slide4.results[this.props.parent] = [];
    // window.slide4.results[this.props.parent][this.props.index] = {
    //   ...d,
    //   index: i
    // };
    // }

    window.slide4.results[this.props.parent].push({
      index: this.props.index,
      option: i
    });
  };
}

export default S4;
