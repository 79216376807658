import React, { Component } from "react";
// images
import article2 from "../../../img/articles/slide3_article2_small.jpg";
import article1 from "../../../img/articles/slide3_article1.jpg";
import { Link } from "react-router-dom";
import Nav from "../../components/nav";

import twitter_icon from "../../../img/twitter_icon.png";
import facebook_icon from "../../../img/facebook_icon.png";
import Footer from "../../components/Footer";
import logo from "../../../img/logo.png";

class Article1 extends Component {
  state = {};
  render() {
    window.scrollTo(0, 0);
    return (
      <>
        <Nav />
        <div className="article-parent">
          <div className="article-header position-relative">
            <img
              src={article1}
              alt=""
              className="position-absolute h-100 w-100 article-header-image"
            />
            <div className="container d-flex flex-column flex-fill justify-content-between h-100 text-white">
              <div className="w-100 text-center mt-5">
                <img src={logo} alt="" className="main-logo" />
              </div>

              <div>
                <h1 className="font-weight-bold">
                  Welcome to the 7th and final step
                </h1>

                <div className="text-white align-items-center mb-5">
                  <div className="font-weight-normal d-flex align-items-center flex-row">
                    <small className="font-weight-normal">23 March 2019</small>
                    <div className="d-inline-block mx-2">|</div>
                    <small className="font-weight-normal">Share</small>
                    <div className="d-flex flex-row ml-2">
                      <a
                        href="https://twitter.com/AmanaCapitalKE"
                        className="mr-3">
                        <img
                          src={twitter_icon}
                          alt=""
                          className="social-icon"
                        />
                      </a>

                      <a
                        href="https://www.facebook.com/amanacapital2003/"
                        className="mr-3">
                        <img
                          src={facebook_icon}
                          alt=""
                          className="social-icon"
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* content */}

          <div className="container pb-5">
            <div className="text-muted mt-3 mb-5">
              <small>Home / Blog / Welcome to the 7th and final step</small>
            </div>

            <div className="row">
              <div className="col-md-9">
                <div className="text-dark article-text">
                  <p>
                    Welcome to the 7th and final step in the Amana Capital 7
                    Steps to Wealth course, “Learn from the best".
                  </p>
                  <p>
                    By now you should be well on your way to establishing a
                    clear and focussed savings plan for yourself, based upon
                    realistic goals, clear and well thought out savings choices
                    and a regular monthly savings budget.
                  </p>
                  <p>
                    However, at Amana Capital we know that saving and investment
                    is a life long journey, and in this step we are going to
                    share with you the five most important long term savings
                    lessons from some of the best investors on the planet.
                  </p>
                  <b className="mb-2 d-block">1.Take your time</b>
                  <p>
                    As we have seen throughout the 7 Steps course, the need to
                    give your savings plan adequate time to come to fruition is
                    at the very heart of every successful journey to wealth. The
                    magic of compounded interest is based upon time, earning
                    returns upon your returns only works when you give your
                    returns time to generate further returns.
                  </p>
                  <p>
                    By all means seek a superior rate of return as and when
                    appropriate, bearing in mind and balancing of course said
                    returns against the risk involved with each savings option,
                    but at the end of the day even the most phenomenal rates of
                    return require sufficient time to bear fruit. Don't be
                    tempted by short cuts, beware get rich quick schemes, stick
                    to your plan like glue and get on with your life. The magic
                    of mathematics shall take care of the rest.
                  </p>
                  <b className="mb-2 d-block">2.Keep your eye on the market</b>
                  <p>
                    Over time, inevitably, markets will go up and down, though
                    in markets such as Kenya and the region, the long term trend
                    should be upwards given our early stage of economic and
                    population growth.
                  </p>
                  <p>
                    When the market goes down, don't panic. That is part of
                    life, sometimes it rains, sometimes it doesn't. Understand
                    instead that a downturn in the market is the perfect
                    opportunity to increase your long term stock of wealth. For
                    example, if you have invested in a Unit Trust, an underlying
                    downturn i the market, meaning a reduction in the price of a
                    “unit" of the unit trust, is the perfect opportunity for you
                    to buy more of the units. Remember all you will be doing is
                    reducing the average price of your total units held. What
                    could be better than that?
                  </p>
                  <b className="mb-2 d-block">3.Keep your eyes on the taxman</b>
                  <p>
                    One of the great benefits of investing in a Unit Trust is
                    that it offers you a very tax efficient way to earn your
                    returns. Your dividends, and the underlying value growth,
                    will be taxed at a significantly lower rate than, for
                    example, rental income, or your salary for that matter.
                    Whilst we in no way condone or participate in tax evasion,
                    we fully seek and recommend maximum tax efficiencies. Over
                    the years, as governments come and go, and as individual
                    governments change their taxation policies, different
                    savings options will become more or less efficient. Seek to
                    become conversant with the tax laws in the country, keep
                    your eyes open for changes that could affect your savings
                    plans, and act accordingly.
                  </p>
                  <b className="mb-2 d-block">4.Compound compound compound</b>
                  <p>
                    Compounded interest is interest that is earned upon
                    interest. If you invest one thousand, an earn 10% interest,
                    you will have one thousand one hundred at the end of the
                    year. The following year you will earn 10% of your original
                    investment plus 10% of the interest that you earned in year
                    one. In year three you will earn 10% of your initial
                    investment plus 10% of the total of your interest earned in
                    the first two years.
                  </p>
                  <p>
                    It is this “interest upon interest" that truly drives wealth
                    creation for the most successful investors, and any basic
                    calculation will show that you will very quickly get to a
                    place where you are earning far more of your interest on
                    previously earned interest than on the initial amount
                    investment. If you further support your investment with
                    regular savings contributions per your savings plan then you
                    truly will be “in business". The golden rule therefore
                    becomes don't touch the returns. Earn and re-invest, earn
                    and re-invest, repeat repeat repeat and watch your wealth
                    grow in leaps and bounds.
                  </p>
                  <b className="mb-2 d-block">
                    5.Diversify, diversify, diversify
                  </b>
                  <p>
                    By now you will have learned that the key strength of a Unit
                    Trust is the inbuilt diversified nature of the asset classes
                    which it gives you exposure to.
                  </p>
                  <p>
                    A good Unit Trust takes your money and splits it between a
                    range of assets from forex to treasury bills to shares
                    depending upon the savings horizon and risk appetite of the
                    particular trust.
                  </p>
                  <p>
                    This key strength is one which you must take into your wider
                    savings, investment and business life. The old adage “don't
                    put all your eggs in one basket" sums it up perfectly. Any
                    one particular asset class can be prone to storms. If you
                    put all your money into real estate and house prices crash
                    then you are in trouble. If you put it all into the latest
                    agricultural fad the same applies. Seek diversification in
                    your savings option, but also in all your financial
                    decisions. The best insurance against a house price crash is
                    to have enough of your money in a range of other assets
                    which haven't crashed, which may indeed be booming!
                    Diversify and watch your savings fly!
                  </p>
                </div>
              </div>
              <div className="col-md-3 d-none">
                <b>Related Articles</b>

                <Link to="/slide4/article2">
                  <div className="bg-light my-5 position-relative">
                    <img src={article2} alt="" className="w-100" />
                    <div className="red-text p-3">
                      <small>
                        The 7 Rules of choosing your savings options
                      </small>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

export default Article1;
