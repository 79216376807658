import React, { Component } from "react";
import "../../css/menu.css";
import { Link } from "react-router-dom";

import amana_logo from "../../img/amana-logo.png";
import LoginModal from "./loginModal";
import Alert from "./Alert";
class Nav extends Component {
  state = {};
  render() {
    return (
      <div className="w-100 position-absolute ">
        <Alert />
        <div
          className={
            "position-relative py-4 container " +
            (this.props.padding ? "nav-padd" : "")
          }>
          <nav className="nav justify-content-end flex-row d-flex  align-items-center">
            {this.props.icon !== false && (
              <a href="https://amanacapital.co.ke">
                <img src={amana_logo} className="header-logo" alt="" />
              </a>
            )}
            <div className="login-btn">
              {localStorage.id && (
                <button
                  className="btn text-white mr-2 mr-md-5"
                  onClick={() => {
                    if (window.confirm("are you sure you want to log out?")) {
                      localStorage.clear();
                      window.h.push("/");
                    }
                  }}>
                  [ &nbsp;<small>Logout</small>&nbsp; ]
                </button>
              )}
            </div>
            <div
              className={
                " bg-white hambuger humburger d-flex justify-content-center align-items-center " +
                (this.props.padding ? "padded" : "")
              }>
              <i className="fas fa-bars text-dark bars" />
              <i className="fas fa-times text-dark position-absolute clear" />
            </div>
          </nav>
        </div>
      </div>
    );
  }
}

export default Nav;
