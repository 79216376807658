import React, { Component } from "react";
import logo from "../../img/logo.png";
import { Link } from "react-router-dom";

import bg_ring from "../../img/icons/Results/bg_ring.svg";

import carret from "../../img/icons/arrow.svg";
import ButtonSm from "../components/buttonSm";
import fund from "../../img/step5/fund.svg";
import check from "../../img/step5/check.svg";
import Button from "../components/button";

class S5 extends Component {
  state = {
    savings: window.calc.savings,
    future: 0,
    products: [
      {
        text: "Estimation on how much you would earn in 5 years",
        years: 5,
        monthly: false,
        showFuture: true
      },
      {
        text: "Estimated extra monthly income for the next 5 years",
        years: 5,
        monthly: true,
        showFuture: false
      },
      {
        text: "Estimation on how much you would earn in 5 years",
        years: 5,
        monthly: true,
        showFuture: true
      },
      {
        text: "Estimated monthly income if you retired in the next 20 years",
        years: 20,
        monthly: false,
        showFuture: false
      }
    ],
    active: parseInt(this.props.match.params.index)
  };
  render() {
    return (
      <>
        <div className="d-flex flex-fill slide flex-column align-items-center justify-content-center step-1 default-bg">
          <div className="d-flex flex-column align-items-center text-white text-center">
            <img
              src={logo}
              alt=""
              className="main-logo d-none d-md-block mb-md-3"
            />

            <div className="d-flex flex-row align-items-center tab-title-main">
              <div className="tab-sm d-flex flex-row justify-content-center align-items-center flex-row mr-3">
                <span className="font-weight-bold">02</span>
              </div>
              <div className="text-left">It has a high rate of return</div>
            </div>

            <div className="w-250 top-title res-title mt-4">
              <div className="intro-savings my-4">
                <h5>How much can you save ?</h5>
                <div className="my-4">
                  <input
                    className="py-1 text-center text-bronze card-input d-flex flex-fill w-100 undefined"
                    value={
                      this.state.savings
                        ? this.state.savings.toLocaleString()
                        : ""
                    }
                    placeholder="Enter savings"
                    onChange={event => {
                      let savings = parseInt(
                        event.target.value.replace(/,/g, "")
                      );
                      if (event.target.value == "") {
                        savings = 0;
                      }
                      console.log(savings);
                      if (!isNaN(savings)) {
                        //    window.calc.savings = savings;
                        this.setState({ savings: savings });
                        setTimeout(() => {
                          this.calculateFuture();
                        }, 0);
                      }
                    }}
                  />
                </div>
              </div>

              <div className="custom-divider d-flex flex-row justify-content-center mt-2 mb-2">
                <img src={carret} alt="" />
              </div>
            </div>

            <div className="my-5">
              <h3 className="text-gold">
                Ksh {this.state.future.toLocaleString()}
              </h3>
              <small className="text-gold">or</small> <br />
              <span className="text-gold">
                Ksh{" "}
                {parseInt(
                  this.state.future /
                    (this.state.products[this.state.active].years * 12)
                ).toLocaleString()}
                /month
              </span>
              <div className="mt-4">
                <small>{this.state.products[this.state.active].text}</small>
              </div>
            </div>
            <Button back={false} to="/slide5/step6" text="continue" />
          </div>
        </div>
        <ButtonSm back={false} to="/slide5/step6" text="continue" />
      </>
    );
  }

  componentWillUnmount() {
    window.calc.savings = this.state.savings;
  }

  componentDidMount() {
    this.calculateFuture();
  }

  calculateFuture() {
    // y = 12;
    let p = this.state.savings;
    let r = 0.12;
    let i = 1 + r / 12;

    let G = (i * (Math.pow(i, 60) - 1)) / (i - 1);

    this.setState({ future: parseInt(G * p) });
  }
}

export default S5;
