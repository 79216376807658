import React, { Component } from "react";
// images
import article2 from "../../../img/articles/article2_slide2.jpg";
import article1 from "../../../img/articles/article1_slide2_main.jpg";
import { Link } from "react-router-dom";
import Nav from "../../components/nav";

import twitter_icon from "../../../img/twitter_icon.png";
import facebook_icon from "../../../img/facebook_icon.png";
import Footer from "../../components/Footer";
import logo from "../../../img/logo.png";

class Article1 extends Component {
 state = {};
 render() {
  window.scrollTo(0, 0);
  return (
   <>
    <Nav />
    <div className="article-parent">
     <div className="article-header position-relative">
      <img
       src={article1}
       alt=""
       className="position-absolute h-100 w-100 article-header-image"
      />
      <div className="container d-flex flex-column flex-fill justify-content-between h-100 text-white">
       <div className="w-100 text-center mt-5">
        <img src={logo} alt="" className="main-logo" />
       </div>

       <div>
        <h1 className="font-weight-bold">7 tips to help you raise more cash</h1>

        <div className="text-white align-items-center mb-5">
         <div className="font-weight-normal d-flex align-items-center flex-row">
          <small className="font-weight-normal">23 March 2019</small>
          <div className="d-inline-block mx-2">|</div>
          <small className="font-weight-normal">Share</small>
          <div className="d-flex flex-row ml-2">
           <a href="https://twitter.com/AmanaCapitalKE" className="mr-3">
            <img src={twitter_icon} alt="" className="social-icon" />
           </a>

           <a
            href="https://www.facebook.com/amanacapital2003/"
            className="mr-3"
           >
            <img src={facebook_icon} alt="" className="social-icon" />
           </a>
          </div>
         </div>
        </div>
       </div>
      </div>
     </div>

     {/* content */}

     <div className="container pb-5">
      <div className="text-muted mt-3 mb-5">
       <small>Home / Blog / 7 tips to help you raise more cash</small>
      </div>

      <div className="row">
       <div className="col-md-9">
        <div className="text-dark article-text">
         <b className="mb-2 d-block">1. Minimize</b>
         <p>
          Take to heart the golden rule – if you don’t need it, get rid of it!
          Look around your home, no doubt it is full of things that you seldom
          if ever use, things that you bought on a whim or because they were on
          offer or just because. Get rid of them. Your home is full of literally
          tens if not hundreds of thousands of shillings worth of things that
          you most definitely do not need. Turn your thinking around, instead of
          looking to fill up your house with things, look to reduce the amount
          of things that you own to the minimum that you actually require. This
          change in lifestyle will have a profound and lasting effect on your
          finances for life.
         </p>
         <b className="mb-2 d-block"> 2. Bulk Buy</b>
         <p>
          It’s the oldest rule of the market place, the more of a thing that you
          buy, the less that you pay for each unit of the thing. Whether it is
          teabags, washing powder or potatoes, don’t buy piecemeal, buy big.
          Change your shopping patterns so that instead of going shopping every
          week, or multiple times a week, you only go shopping once a month. Buy
          everything that you will need for the month in the largest possible
          quantity that meets your requirements. It may not seem like much, but
          item by item, month y month, the savings that you make will grow and
          grow and grow.
         </p>
         <b className="mb-2 d-block">3. Ride share</b>
         <p>
          There’s no shame in it. Save drastically on your transport costs by
          car pooling with your neighbours and friends. No need for four
          different cars to take four different children to the same school from
          the same are. Take turns with your fellow parents to do the school
          run. Same applies to getting to work. For sure you will have
          neighbours who work in a similar location to you, take turns to drive
          each other to work, it will save you a lot more money than you realize
          over time, and it will make the Nairobi traffic a lot more
          companionable…
         </p>
         <b className="mb-2 d-block"> 4. Stay In</b>
         <p>
          Staying in is the new going out! For a quarter of what you would spend
          going out to a fancy night spot with your friends, you can have them
          round to your place and enjoy a good meal and some fine wine with them
          instead. Invest in some board games, host movie nights, or FIFA nights
          if you are so inclined. Learn how to cook new dishes, experiment with
          making your own cocktails, fill your home with happiness and laughter,
          and then laugh with happiness at how much extra money you have saved
          each month.
         </p>
         <b className="mb-2 d-block"> 5. Get a Hobby</b>
         <p>
          Funnily enough, one of the best ways to save extra money is to spend
          some money on a hobby that you love. This could be anything from
          painting watercolours to learning a new language to learning The
          Zumba! When you find a hobby that you are passionate about you will
          find that you actually spend much less time, and money, on doing
          things that you don’t actually care that much about. Instead, you’ll
          be spending much more time, and much less money, on doing something
          that makes you happy – and you never know, your hobby could well end
          up making you a bit of extra cash one day!
         </p>
         <b className="mb-2 d-block"> 6. Dress consciously</b>
         <p>
          Clothes and fashion is something that we all spend a lot of money on.
          We want to look good and impress the world around us. However, just
          like anything else in your life, you need to approach how you dress
          and how you present yourself to the world with a very level head. You
          almost certainly have far more clothes than you actually need, and
          like all of us have a wardrobe full of items that you never wear.
          Think like a stylist, develop a look book for yourself, smart and
          casual, and only buy the items that you need to match the look that
          you want. Not only will you actually look so much better, you will
          also save money in the process.
         </p>
         <b className="mb-2 d-block"> 7. Get Fit</b>
         <p>
          It may not seem obvious, but getting physically fitter is a great way
          to save money every month. Being in great shape physically has many
          many financial benefits. You’ll get sick much less, thus reducing
          medical expenses. You will be much less hungry and, much less tempted
          to waste money on snacks and treats. You’ll spend a lot more time
          going for walks and jogs than blowing cash in restaurants and bars,
          and you’ll perform so much better in your work that you are guaranteed
          to get that promotion or raise.
         </p>{" "}
        </div>
       </div>
       <div className="col-md-3">
        <b>Related Articles</b>

        <Link to="/slide2/article2">
         <div className="bg-light my-5 position-relative">
          <img src={article2} alt="" className="w-100" />
          <div className="red-text p-3">
           <small>What kind of spender are you?</small>
          </div>
         </div>
        </Link>
       </div>
      </div>
     </div>
    </div>
    <Footer />
   </>
  );
 }
}

export default Article1;
