import React, { Component } from "react";
import logo from "../../img/logo.png";

import bg_ring from "../../img/icons/Results/bg_ring.svg";
import Button from "../components/button";
import ButtonSm from "../components/buttonSm";

class S2_5 extends Component {
 state = { input: { amount: window.slide3.years } };
 render() {
  return (
   <>
    <div className="d-flex flex-fill slide flex-column align-items-center justify-content-center step-1 default-bg">
     <div className="d-flex flex-column align-items-center text-white text-center">
      <div className="d-flex justify-content-center flex-column align-items-center position-relative">
       <img src={bg_ring} alt="" className="align-self-center text-gold" />
       <img
        src={window.slide3.options[window.slide3.active].icon}
        alt=""
        className="position-absolute align-self-center icon text-dark"
       />
      </div>
      <div className=" habit-title text-center mt-2 text-white">
       {window.slide3.options[window.slide3.active].name}
      </div>
      <div className="my-4">
       <h5 className="lts font-weight-bold">
        When would you like to reach it?
       </h5>
       <span className="text-white px-3 d-inline-block desc-text">
        Enter duration
       </span>
      </div>
      <div className="d-flex flex-row align-items-center col-md-6 mt-md-0 year-input">
       <input
        type="text"
        name=""
        class="py-2 d-flex amt-i flex-fill px-3 text-left text-bronze card-input no-anim d-flex input w-100"
        value={this.state.input.amount.toLocaleString() + " Years"}
        placeholder="How much was it?"
        disabled
        // onChange={event => {
        //  let amount = parseInt(event.target.value.replace(/,/g, ""));
        //  // console.log(amount);
        //  if (!isNaN(amount)) {
        //   this.setState({ input: { ...this.state.input, amount } });
        //  }
        //  if (event.target.value === "") {
        //   this.setState({ input: { ...this.state.input, amount: 0 } });
        //  }
        // }}
       />
       <div className="d-flex flex-row bg-white toggle-button-parent">
        <button
         className="btn toggle-button"
         onClick={() => {
          let amount = this.state.input.amount;
          if (amount === "") amount = 0;
          amount -= 1;
          if (amount < 0) return false;
          window.slide3.years = amount;
          this.setState({ input: { ...this.state.input, amount } });
         }}
        >
         -
        </button>
        <button
         className="btn toggle-button"
         onClick={() => {
          let amount = this.state.input.amount;
          if (amount === "") amount = 0;
          amount += 1;
          if (amount < 0) return false;
          window.slide3.years = amount;
          this.setState({ input: { ...this.state.input, amount } });
         }}
        >
         +
        </button>
       </div>
      </div>
      <div className="mt-5 pt-3">
       <Button to="/slide3/step3" text="Continue" />
      </div>
     </div>
    </div>
    <ButtonSm to="/slide3/step3" text="Continue" />
   </>
  );
 }
}

export default S2_5;
