import React, { Component } from "react";
import logo from "../../img/logo.png";
import { Link } from "react-router-dom";

import bg_ring from "../../img/icons/Results/bg_ring.svg";

import carret from "../../img/icons/arrow.svg";
import ButtonSm from "../components/buttonSm";
import fund from "../../img/step5/fund.svg";
import check from "../../img/step5/check.svg";
import Button from "../components/button";

class S4 extends Component {
  state = {
    savings: window.calc.savings,
    products: [
      {
        title: "Amana income fund",
        options: [
          "A steady monthly income",
          "Inflation proof",
          "Savings secure from low performance"
        ]
      },
      {
        title: "Amana Growth Fund",
        options: [
          "Highest rate of return",
          "Inflation proof",
          "Performance may vary but delivers in the long term."
        ]
      },
      {
        title: "Amana Balanced Fund",
        options: [
          "Best for high return and long term goals",
          "Inflation proof",
          "Performance may vary but delivers in the long term."
        ]
      },
      {
        title: "Amana Retirement Fund",
        options: [
          "A steady monthly income",
          "Inflation proof",
          "Savings secure from low performance."
        ]
      }
    ],
    active: parseInt(this.props.match.params.index)
  };
  render() {
    return (
      <>
        <div className="d-flex flex-fill slide flex-column align-items-center justify-content-center step-1 default-bg">
          <div className="d-flex flex-column align-items-center text-white text-center">
            <img
              src={logo}
              alt=""
              className="main-logo d-none d-md-block mb-md-3"
            />

            <div className="d-flex flex-row tab-title-main">
              <div className="tab-sm d-flex flex-row justify-content-center align-items-center flex-row mr-3">
                <span className="font-weight-bold">01</span>
              </div>
              <div className="text-left">
                It is flexible enough to meet your needs
              </div>
            </div>

            <div className="w-250 top-title res-title mt-4">
              <h5 className="text-gold my-4">We found you a match</h5>

              <div className="custom-divider d-flex flex-row justify-content-center mt-2 mb-2">
                <img src={carret} alt="" />
              </div>
            </div>

            <div className="my-3 w-100 desc-text">
              <div className="d-flex flex-row w-100 align-items-center">
                <div className="postion-relative my-3 mx-1 mx-md-3 mr-3">
                  <div className="position-relative">
                    <img src={fund} alt="" className="absolute-center" />
                    <img
                      src={bg_ring}
                      alt=""
                      className="align-self-center text-gold"
                    />
                  </div>
                </div>
                <div>
                  <h5 className="font-weight-bold text-center">
                    {this.state.products[this.state.active].title}
                  </h5>
                </div>
              </div>

              <div className="desc-text">
                {this.state.products[this.state.active].options.map((d, i) => (
                  <div key={i} className="my-3">
                    <div className="my-3 d-flex flex-row align-items-center">
                      <img src={check} alt="" className="mr-3 check-icon" />
                      <div className="text-gold text-left">{d}</div>
                    </div>
                  </div>
                ))}
              </div>
            </div>

            <Button
              back={false}
              to={"/slide5/step5/" + this.state.active}
              text="continue"
            />
          </div>
        </div>
        <ButtonSm
          back={false}
          to={"/slide5/step5/" + this.state.active}
          text="continue"
        />
      </>
    );
  }

  componentDidMount = () => {
    console.log(this.state.active, this.props.match.params.index);
  };
}

export default S4;
