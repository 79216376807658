import React, { Component } from "react";
import logo from "../../img/logo.png";
import Button from "../components/button";

// images
import LoginModal from "../components/loginModal";
import ButtonSm from "../components/buttonSm";

class Step0_5 extends Component {
 state = {
  needs: {},
  wants: {},
  options: [
   "Is there a way to better manage the cost of living?",
   "Are there ways to still enjoy the finer things in life?",
   "Whats a good buffer to keep you growing and secure?"
  ],
  loginVisible: localStorage.id === undefined
 };
 render() {
  return (
   <>
    <div className="d-flex slide flex-fill flex-column align-items-center justify-content-center step-1 default-bg">
     <LoginModal
      path="/slide1/step0_55"
      modalVisible={this.state.loginVisible}
     />
     <div className="d-flex flex-column align-items-center text-white text-center">
      <img src={logo} alt="" className="main-logo mb-3  d-md-flex" />

      <div className=" mt-md-5 mt-md-4 text-center">
       <h3 className="text-center second-intro-text d-inline-block">
        <b className="title-text tf">The 50:30:20 Budget</b>
       </h3>

       <div className="mt-3 desc-text font-weight-bold">
        <span>
         A balanced budget spends 50% of income on necessities, 30% on other
         things and saves 20% for growth.
        </span>
       </div>
      </div>

      <div className="v-p2 d-flex flex-row justify-content-center mb-4 mt-5 align-items-end">
       <div className="px-0 mn text-center d-flex flex-column justify-content-end h-100">
        <small className="ead6af" />
        <div
         className="vertical-bar px-3 py-2 text-gold mx-1 mb-1 needs"
         style={{ minHeight: "100%" }}
        >
         50%
        </div>
        <small>NEEDS</small>
       </div>

       <div className="px-0 mn text-center d-flex flex-column justify-content-end h-100">
        <small className="ead6af" />
        <div
         className="vertical-bar px-3 py-2 text-gold mx-1 mb-1 wants"
         style={{ minHeight: "60%" }}
        >
         30%
        </div>
        <small>WANTS</small>
       </div>

       <div className="px-0 mn text-center d-flex flex-column justify-content-end h-100">
        <small className="ead6af" />
        <div
         className="vertical-bar px-3 py-2 text-gold mx-1 mb-1 savings"
         style={{ minHeight: "40%" }}
        >
         20%
        </div>
        <small>SAVINGS</small>
       </div>
      </div>
      <div className="mt-md-5">
       <Button to="/slide1/step1" text="LET'S FIND OUT" />
      </div>
     </div>
    </div>
    <ButtonSm to="/slide1/step1" text="LET'S FIND OUT" />
   </>
  );
 }

 updateCheckbox = i => {
  this.setState({ currentCheckbox: i });
 };
}

export default Step0_5;
