import React, { Component } from "react";
// images
import article1 from "../../../img/article_bg.jpg";
import { Link } from "react-router-dom";
import Nav from "../../components/nav";
import Footer from "../../components/Footer";

import twitter_icon from "../../../img/twitter_icon.png";
import facebook_icon from "../../../img/facebook_icon.png";
import logo from "../../../img/logo.png";

class Article2 extends Component {
 state = {};
 render() {
  window.scrollTo(0, 0);
  return (
   <>
    <Nav />
    <div className="article-parent">
     <div className="article-header article2-bg">
      <div className="container d-flex flex-column flex-fill justify-content-between h-100 text-white">
       <div className="w-100 text-center mt-5">
        <img src={logo} alt="" className="main-logo" />
       </div>

       <div>
        <h1 className="font-weight-bold">
         7 tips to help you stick to your budget
        </h1>

        <div className="text-white align-items-center mb-5">
         <div className="font-weight-normal d-flex align-items-center flex-row">
          <small className="font-weight-normal">23 March 2019</small>{" "}
          <div className="d-inline-block mx-2">|</div>
          <small className="font-weight-normal">Share</small>
          <div className="d-flex flex-row ml-2">
           <a href="https://twitter.com/AmanaCapitalKE" className="mr-3">
            <img src={twitter_icon} alt="" className="social-icon" />
           </a>

           <a
            href="https://www.facebook.com/amanacapital2003/"
            className="mr-3"
           >
            <img src={facebook_icon} alt="" className="social-icon" />
           </a>
          </div>
         </div>
        </div>
       </div>
      </div>
     </div>

     {/* content */}

     <div className="container pb-5">
      <div className="text-muted mt-3 mb-5">
       <small>Home / Blog / There is no secret to wealth</small>
      </div>

      <div className="row">
       <div className="col-md-9">
        <div className="text-dark article-text">
         <b className="mb-2 d-block">
          1. Learn the difference between need and want
         </b>
         <p>
          The 50:30:20 budget rule is based upon the differentiation between
          spending on things you need (the 50%) and spending on things you want
          (the 30%). Oftentimes, knowing the difference between these two is not
          quite so straightforward. We know that we need somewhere to live, that
          we need to eat, that we need electricity and water, and that we need
          to get around the city or town in our daily lives. We also know that
          we need to look presentable in public, but this is not the same thing
          as wanting a new pair of knee high boots or the latest trainers.
          Asking yourself before you buy anything if you really need it will go
          along way towards helping you stick to your budget.
         </p>
         <b className="mb-2 d-block">2. Discount doesn’t always mean deal</b>
         <p>
          A common trick used by marketers is to splash a big price discount
          message across their product – 20% off, 30% off, Bogoff! The common
          variation on this theme is “buy this and get this other thing for
          free”, as in buy dishwasher and get a free sponge. In both cases, the
          offers are designed to distract you from asking yourself if you really
          need the item on offer. If a product is discounted, the chances are
          that the person selling it to you is still making money on it, and the
          fact that it is cheaper than usual does not mean that you should
          therefore buy it. Beware the trap and sticking to your budget will be
          much easier every month.
         </p>
         <b className="mb-2 d-block">3. Shop around</b>
         <p>
          However, when you do really need to buy a particular item or service,
          it pays to do your research. Don’t just rush to purchase in the first
          place that you find what you are looking for. Take a few days, do your
          research, shop around. You will be amazed at the difference in price
          that you can find for the same item from different shops or suppliers.
          Remember, unless it is an emergency, the chances are that you don’t
          need the item immediately, so take the time that you need to get the
          best possible deal that you can.
         </p>
         <b className="mb-2 d-block">4. More is Less</b>
         <p>
          This especially applies to regular monthly shops. For everything from
          flour to detergent to pet food to chocolate, the larger the quantity
          of an item that you buy, the lower the unit price of that item, in
          general. We often pay a price in our daily lives from a failure to
          plan our regular monthly shopping, and end up buying lots of small
          units of a regular item on a frequent basis instead of buying a larger
          quantity of the same item once a month for a lower total price. Month
          by month the savings that you make by changing your habits to more for
          less will soon add up to a surprisingly substantial amount.
         </p>
         <b className="mb-2 d-block">5. Only to borrow to grow</b>
         <p>
          An increasingly major expenditure item in many Kenyan households each
          month is the interest repayments on loans. The various mobile loan
          applications, between monthly interest rates and facilitation fees,
          charge an annual interest rate well north of 40%. Without you
          realizing it, the interest that you are paying on such loans is making
          a significant dent in the amount of money that you have available for
          your savings. The golden rule is: only borrow if it is to use the
          money to grow, such as to fund further education, or for a realistic
          business investment, or perhaps to pay for a home. Borrowing to fund
          daily living expenses is a recipe for disaster, and should be avoided
          at all costs.
         </p>
         <b className="mb-2 d-block">6. Stick to your entertainment limit</b>
         <p>
          We all enjoy the finer things in life, and of course life isn’t worth
          living if we can’t have a bit of fun as we live it. Going out with the
          boys or the girls, enjoying a fine meal in a good restaurant, or even
          taking a little trip out of town, all of these are activities that we
          all indulge in and enjoy. However, the secret is not to over-indulge.
          Set yourself a monthly budget to spend on enjoying yourself, and stick
          to it. If that means one night out a month, so be it, the one night a
          month will be that much more enjoyable for the knowledge that you can
          afford it, and that you have earned it.
         </p>
         <b className="mb-2 d-block">7. Don’t throw good money after bad</b>
         <p>
          We are all guilty of cheating ourselves that because we have spent a
          certain amount of money on a project, we should continue spending
          money on that project because to not do so would be a “waste”. This is
          a common fallacy that needs to be avoided. If a project, some form of
          business investment for example, is not working for you, that chances
          are that it is not going to suddenly start working for you just
          because you throw more money at it. Learn how to have no emotions over
          such investments, learn how to judge whether or not they are viable,
          and learn when to pull the plug when you have to. Your future self
          will thank you for it.
         </p>
        </div>
       </div>
       <div className="col-md-3 ">
        <b>Related Articles</b>

        <Link to="/article1">
         <div className="bg-light my-5 position-relative">
          <img src={article1} alt="" className="w-100" />
          <div className="red-text p-3">
           <small>There is no secret to wealth</small>
          </div>
         </div>
        </Link>
       </div>
      </div>
     </div>
    </div>
    <Footer />
   </>
  );
 }
}

export default Article2;
