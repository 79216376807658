import React, { Component } from "react";
import { Link } from "react-router-dom";
import arrow_bottom from "../../img/arrow_bottom.svg";

// images import
import logo from "../../img/logo.png";
import twitter_icon from "../../img/twitter_icon.png";
import facebook_icon from "../../img/facebook_icon.png";
import youtube_icon from "../../img/youtube_icon.png";
import weigh_icon from "../../img/weigh.png";
import coins_icon from "../../img/coins.png";
import book_icon from "../../img/book.png";
import play_icon from "../../img/play_icon.png";
import amana_logo from "../../img/amana-logo.png";
import VideoModal from "../components/videoModal";

import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Nav from "../components/nav";
import Footer from "../components/Footer";

class ThankYouPage extends Component {
 state = {};
 render() {
  return (
   <main className="bg-white">
    <Nav responsiveLogo={true} />
    <VideoModal modalVisible={this.state.modalVisible} />
    <section class="section position-relative bg-dark banner-section d-flex flex-column justify-content-center">
     <div className="d-flex flex-column justify-content-center landing-page-bg">
      <div class="container my-md-5">
       <div class="d-flex flex-column text-white text-center align-items-center">
        <img src={logo} alt="" className="main-logo mb-3" />
        <div>
         <h1 class="text-white my-3 header-title d-inline-block landing-title">
          <b>Congratulations!!</b>
         </h1>
         <p class="header-content font-weight-bold dxx-2/">
          You have taken the first step to the life of your dreams. Our team
          will be contacting you shortly to confirm your counsultation
         </p>

         <div className="pt-4">
          <button
           class="btn btn-primary px-3 py-1 scroll-button"
           data-target="#chums"
           href="/slide1/"
          >
           <div class="text-white  my-1">
            <small class="font-weight-bold">Step 2: Raise Chumz!</small>
           </div>
          </button>
         </div>
        </div>

        {/* <img src={logo} alt="" className="main-logo" style={{ opacity: 0 }} /> */}
       </div>
      </div>
     </div>
    </section>

    <section class="section" id="chums">
     <div class="container text-center my-0 py-5">
      <div class="d-inline-block">
       <h2 className="font-weight-light">
        Step 2: Raise Chums launches on june 10th 2019!
       </h2>

       <p class="steps-text my-3 mx-auto">
        If trying to raise cash for saving seems like a difficult task, don't
        stress. In step 2 of the 7 Steps to Wealth we will learn how to find
        extra cash for you to grow your future.
       </p>
       <div
        className="d-inline-block"
        onClick={() => {
         window.scrollTo(0, 0);
        }}
       >
        <button
         class="btn btn-primary px-5 py-2 scroll-button"
         data-target="#schedule"
        >
         <div class="text-white  my-1">
          <small class="font-weight-bold">VIEW SCHEDULE</small>
         </div>
        </button>
       </div>
      </div>
     </div>
    </section>

    <section id="schedule">
     <div class="bg-secondary overflow-hidden">
      <div class="container text-white">
       <div class="_row ">
        <OwlCarousel
         className="owl-theme"
         margin={0}
         responsive={{
          0: {
           items: 1,
           nav: true
          },
          600: {
           items: 3,
           nav: false
          }
         }}
         nav={false}
        >
         <div class="_col-md-4 p-3 py-5">
          <div class="d-flex flex-row align-items-center mt-4">
           <small class="">
            <b>LESSON &nbsp; 1 </b>
           </small>
           <div class="separator mx-3  bg-white" />
           <small class="">
            <b>2</b>
           </small>
          </div>
          <div class="my-2">
           <span class="text-primary-light d-block">
            <b>PREPARE TO SAVE</b>
           </span>
           <div class="my-2">
            Analyse your financial habits and discover how to make the most out
            of what you have.
            <br />
            <br />
           </div>
           <div class="list my-3">
            <div class="my-2 list-text">
             <i class="fas fa-check mr-3 check-icon" />
             Create a bugdet that balances your priorities
            </div>
            <div class="my-2 list-text">
             <i class="fas fa-check mr-3 check-icon" />
             Discover how to raise extra cash for savings
            </div>
            <div class="my-2 list-text">
             <i class="fas fa-check mr-3 check-icon" />
             Learn how to spend wisely
             <br />
             <br />
            </div>
           </div>

           <Link
            to="/slide1"
            class="btn btn-primary px-3 pr-5 py-2 mt-3 bg-white border-0"
           >
            <div class="mr-5 text-white ml-2 my-1">
             <b class="text-sm text-primary-light border-0">START NOW</b>
            </div>
           </Link>
          </div>
         </div>

         <div class="_col-md-4 p-3 py-5">
          <div class="muted py-4">
           <div className="">
            <div class="d-flex flex-row align-items-center">
             <small class="">
              <b>LESSON &nbsp; 3 </b>
             </small>
             <div class="separator mx-3  bg-white" />
             <small class="">
              <b>5</b>
             </small>
            </div>
            <div class="my-2">
             <span class="text-primary-light d-block text-uppercase">
              <b>How to make your money work</b>
             </span>
             <div class="my-2">
              Learn about all the financial options available to you and learn
              how to choose what suits your goal.
             </div>
             <div class="list my-3">
              <div class="my-2 list-text">
               <i class="fas fa-check mr-3 check-icon" />
               Learn how to turn your life goals into financial goals.
              </div>
              <div class="my-2 list-text">
               <i class="fas fa-check mr-3 check-icon" />
               Discover the investment options that work best for achieving your
               goal.
              </div>
              <div class="my-2 list-text">
               <i class="fas fa-check mr-3 check-icon" />
               Learn how to balance your risk and reward.
              </div>
             </div>
            </div>
           </div>

           <div class="pt-3 elevate">
            <div class="text-primary-light">
             <b>STARTS ON</b>
            </div>
            <div>
             <small>
              <b>24/06/2019</b>
             </small>
            </div>
           </div>
          </div>
         </div>

         <div class="_col-md-4 p-3 py-5 overflow-x-visible">
          <div class="muted py-4">
           <div className="">
            <div class="d-flex flex-row align-items-center">
             <small class="">
              <b>LESSON &nbsp; 6 </b>
             </small>
             <div class="separator mx-3  bg-white" />
             <small class="">
              <b>7</b>
             </small>
            </div>
            <div class="my-2">
             <span class="text-primary-light d-block">
              <b className="text-uppercase">Stay the course to wealth</b>
             </span>
             <div class="my-2">
              Its not enough to just save we need to understand how to make it
              the top and stay there.
             </div>
             <div class="list my-3">
              <div class="my-2 list-text">
               <i class="fas fa-check mr-3 check-icon" />
               Discover the habits that keep you on your course to wealth.
              </div>
              <div class="my-2 list-text">
               <i class="fas fa-check mr-3 check-icon" />
               Learn tips and tricks from successful investors.
              </div>
              <div class="my-2 list-text">
               <i class="fas fa-check mr-3 check-icon" />
               Get help from industry experts.
              </div>
             </div>
            </div>
           </div>

           <div class="pt-3 elevate">
            <div class="text-primary-light">
             <b>STARTS ON</b>
            </div>
            <div>
             <small>
              <b>05/08/2019</b>
             </small>
            </div>
           </div>
          </div>
         </div>
        </OwlCarousel>
       </div>
      </div>
     </div>
    </section>

    <Footer />
   </main>
  );
 }
}

export default ThankYouPage;
