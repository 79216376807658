import React, { Component } from "react";
import logo from "../../img/logo.png";
import arrow from "../../img/icons/carret_up.svg";
import pointer from "../../img/step4/pointer.svg";

import ButtonSm from "../components/buttonSm";
import Button from "../components/button";
import { TweenMax, Power2, TimelineLite, Power4 } from "gsap/TweenMax";

class S3 extends Component {
  state = {
    ...window.slide4.data[this.props.index],
    day: 2019
  };

  render() {
    return (
      <>
        <div className="d-flex flex-fill flex-column align-items-center justify-content-center step-1 default-bg cover">
          <div className="d-flex flex-column align-items-center text-white text-center w-100">
            <img
              src={logo}
              alt=""
              className="main-logo d-none d-md-block mb-5"
            />

            <div className="yearSlider w-100 position-relative">
              <div className="container px-0 position-relative">
                <div className="calendar-marker text-gold d-flex flex-column align-items-center justify-content-between">
                  <span className="marker-text">YEAR</span>
                  <div className="mark" />
                </div>
                <div className="calendar-slider flex-row d-flex">
                  {this.getCalendar(this.state.day)}
                </div>
              </div>
            </div>

            <div className="w-100 d-flex flex-column align-items-center mt-4">
              <div className="px-3 py-2 info-block desc-text b20 pt-md-3 round position-relative">
                <div className="d-flex flex-row justify-content-center top-bar">
                  {/* <div className="text-right">RISK</div> */}
                  <div className="font-weight-bold px-5 text-center text-uppercase">
                    {window.slide4.data[this.props.index].name}
                  </div>
                  {/* <div className="text-left">REWARD</div> */}
                  <img
                    src={pointer}
                    alt=""
                    className="property-pointer d-flex align-self-center"
                  />
                </div>
              </div>
            </div>

            <div className="desc-text mt-4">
              <span className="font-weight-bold">
                How long do you want to grow your money?
              </span>
            </div>

            <div className="my-4">
              <div className="d-flex flex-row align-items-center col-md-6 mt-md-0 year-input px-0 mt-3">
                <input
                  type="text"
                  name=""
                  class="py-2 d-flex amt-i flex-fill px-3 text-left text-bronze card-input no-anim d-flex input w-100 border-right"
                  value={this.state.years.toLocaleString() + " Years"}
                  placeholder="How much was it?"
                  disabled
                />
                <div className="d-flex flex-row bg-white toggle-button-parent">
                  <button
                    className="btn toggle-button"
                    onClick={() => {
                      let years = this.state.years;
                      if (years === "") years = 0;
                      years -= 1;
                      if (years < 0) return false;
                      window.slide3.totalyears = years;
                      this.setState({ years });
                      // this.setState({ years, day: this.state.day - 1 });
                    }}>
                    -
                  </button>
                  <button
                    className="btn toggle-button"
                    onClick={() => {
                      let years = this.state.years;
                      if (years === "") years = 0;
                      years += 1;
                      if (years < 0) return false;
                      window.slide3.years = years;
                      // this.setState({ years, day: this.state.day + 1 });
                      this.setState({ years });
                    }}>
                    +
                  </button>
                </div>
              </div>
            </div>

            <div className="flex-column justify-content-center d-flex mt-5">
              <img src={arrow} alt="" />
              <small className="text-primary mt-2 font-weight-bold text-sm">
                CHOOSE TO CONTINUE
              </small>
            </div>
            <div className="mt-5">
              <Button to={`/slide4/step4/${this.props.index}/0`} />
            </div>
          </div>
        </div>
        <ButtonSm to={`/slide4/step4/${this.props.index}/0`} />
      </>
    );
  }

  componentWillUnmount() {
    window.slide4.data[this.props.index] = this.state;
  }

  componentDidMount = () => {
    window.slide4.results[this.props.index] = [];
    this.slide();
  };

  slide(animate) {
    let day = this.state.day;
    if (animate === false) {
      TweenMax.set(".calendar-slider", {
        x: (1 - (2019 + day)) * 60,
        ease: Power4.easeInOut
      });
      return;
    }
    TweenMax.to(".calendar-slider", 2, {
      x: (1 + (2019 - day)) * 60,
      ease: Power4.easeInOut
    });
  }

  getCalendar(active) {
    let items = [];
    for (let i = 2019; i < 2050; i++) {
      items.push(
        <div
          key={i}
          className={"slider-item " + (i === active ? "active" : "")}>
          {i}
        </div>
      );
    }
    // this.slide();
    return items;
  }
}

export default S3;
