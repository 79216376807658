import React, { Component } from "react";

class Tabs extends Component {
 state = {};
 render() {
  return (
   <div className="w-100  mt-3">
    <div
     className={
      "w-33 p-2 text-white text-center tab-option rounded-left " +
      (this.props.active === "needs" ? "active" : "")
     }
    >
     <span className="text-sm font-weight-bold">NEEDS</span>
    </div>
    <div
     className={
      "w-33 p-2 text-white text-center tab-option " +
      (this.props.active === "wants" ? "active" : "")
     }
    >
     <span className="text-sm font-weight-bold">WANTS</span>
    </div>
    <div
     className={
      "w-33 p-2 text-white text-center tab-option rounded-right " +
      (this.props.active === "income" ? "active" : "")
     }
    >
     <span className="text-sm font-weight-bold">INCOME</span>
    </div>
   </div>
  );
 }
}

export default Tabs;
