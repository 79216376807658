import React, { Component } from "react";
import { Route, Link } from "react-router-dom";
import { spring, AnimatedSwitch } from "react-router-transition";
import { TweenMax, Power2, TimelineLite, Power4 } from "gsap/TweenMax";
import hexagon from "../../img/icons/options/hexagon.svg";
import or from "../../img/icons/options/or.svg";
import arrow from "../../img/icons/carret_up.svg";
import Button from "./button";
import ButtonSm from "./buttonSm";

let mainPath;
class Option2 extends Component {
  constructor() {
    super();
    this.slide = window.slide2 ? window.slide2 : window.slide6;
    this.state = {
      ...this.slide,
      day: 1
    };
  }
  render() {
    mainPath =
      window.location.pathname
        .split("/")
        .splice(0, 3)
        .join("/") + "/";
    // console.log(mainPath + this.props.index + "/outcome/:option");
    return (
      <>
        <Route
          exact
          path={mainPath + this.props.index}
          render={routeProps => (
            <Item
              slide={(p, o) => {
                this.props.slide(
                  this.state.scenarios[
                    this.props.index !== 4 ? this.props.index + 1 : 4
                  ].day,
                  p,
                  o
                );
              }}
              {...routeProps}
              index={this.props.index}
            />
          )}
        />

        <Route
          exact
          path={mainPath + this.props.index + "/outcome/:option"}
          render={routeProps => (
            <Item2
              slide={(p, o) => {
                this.props.slide(
                  this.state.scenarios[
                    this.props.index !== 4 ? this.props.index + 1 : 4
                  ].day,
                  p,
                  o
                );
              }}
              {...routeProps}
              index={this.props.index}
            />
          )}
        />
      </>
    );
  }

  //  componentWillReceiveProps() {
  //   //   console.log("message");
  //  }

  calculateFuture() {
    let p = window.calc.savings;
    let r = 0.12;
    let i = 1 + r / 12;

    let G = (i * (Math.pow(i, 60) - 1)) / (i - 1);

    this.setState({ future: parseInt(G * p) });
  }
}

export default Option2;

class Item extends Component {
  constructor() {
    super();
    this.slide = window.slide6;
    this.state = {
      ...this.slide,
      day: 1
    };
  }

  render() {
    let future = this.calculateFuture();
    let futuresm = future * 0.85;
    let timeleft = window.slide6.years - window.slide6.totalYears;
    //   console.log(this.props.history.push());
    let { scenarios } = this.state;
    let opt = scenarios[this.props.index];
    let nxt = this.props.index;
    nxt++;
    let mnt1 = Math.abs(
      window.calc.savings * 0.01 * opt.options[0].percentage
    ).toLocaleString();
    let mnt2 = Math.abs(
      window.calc.savings * 0.01 * opt.options[1].percentage
    ).toLocaleString();

    return (
      <>
        <div className="px-3 py-3 info-block desc-text b20 pt-md-3">
          <b>
            {opt.desc
              .replace(/\$futuresm/, futuresm)
              .replace(/\$future/, future)
              .replace(/\$timeleft/, timeleft)}
          </b>
        </div>

        <div className="mt-4">
          <div
            onClick={() => {
              this.go(mainPath + this.props.index + "/outcome/0", nxt);
              //    console.log(opt.options[0].percentage);
            }}
            className="py-3 text-white d-flex flex-row s4-option contain b21">
            <div className="icon-option d-flex justify-content-center flex-column align-items-center">
              <img
                src={hexagon}
                alt=""
                className="position-absolute align-self-center shadow-hexagon"
              />
              <img
                src={hexagon}
                alt=""
                className="position-absolute align-self-center"
              />

              <img
                src={opt.options[0].icon}
                alt=""
                className="position-absolute align-self-center icon"
              />
            </div>
            <div className="d-flex flex-fill">
              <small className="text-left">
                {opt.options[0].title.replace("$mnt", mnt1)}
              </small>
            </div>
          </div>
          <div className="position-relative b21">
            <div className="position-absolute or-icon-sep px-3" />
            <div className="gold-hr my-4" />
            <div className="position-absolute or-icon px-3">
              <img src={or} alt="" />
            </div>
          </div>
          <div
            onClick={() => {
              this.go(mainPath + this.props.index + "/outcome/1", nxt);
              // this.props.slide(opt.options[1].percentage, 1);
            }}
            className="py-3 text-white d-flex flex-row s4-option contain b21">
            <div className="d-flex flex-fill">
              <small className="text-right">
                {opt.options[1].title.replace("$mnt", mnt2)}
              </small>
              <div className="icon-option d-flex justify-content-center flex-column align-items-center">
                <img
                  src={hexagon}
                  alt=""
                  className="position-absolute align-self-center shadow-hexagon delay"
                />
                <img
                  src={hexagon}
                  alt=""
                  className="position-absolute align-self-center"
                />
                <img
                  src={opt.options[1].icon}
                  alt=""
                  className="position-absolute align-self-center icon"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="flex-column justify-content-center d-flex mt-5 b21">
          <img src={arrow} alt="" />
          <small className="text-primary mt-2 font-weight-bold text-sm">
            CHOOSE TO CONTINUE
          </small>
        </div>
      </>
    );
  }

  calculateFuture() {
    // y = 12;
    let p = window.calc.savings;
    let r = 0.12;
    let i = 1 + r / 12;

    let G = (i * (Math.pow(i, 60) - 1)) / (i - 1);

    return parseInt(G * p);
  }

  componentDidUpdate() {
    if (window.location.pathname === mainPath + this.props.index) {
      let nxt = this.props.index;
      // console.log(nxt);
      // this.props.slide();
    }
  }

  componentDidMount = () => {
    this.props.slide(this.state.scenarios[this.props.index].year, 0);
  };

  go = (to, n) => {
    //   TweenMax.to(".brg", 1, { opacity: 0, scale: 0.95 });
    let tl = new TimelineLite();
    tl.staggerTo(".b20", 0.5, { opacity: 0, scale: 0.95 }, -0.2).staggerTo(
      ".b21",
      1,
      { opacity: 0, scale: 0.95, y: 0 },
      0.2,
      0.3
    );

    setTimeout(() => {
      // if (n !== 5) {
      this.props.history.push(to);
      // }
      TweenMax.set(".b20", { opacity: 0, scale: 1.05 });

      TweenMax.set(".b21", {
        opacity: 0,
        scale: 1.2,
        y: 20,
        ease: Power4.easeInOut
      });
      let tl2 = new TimelineLite();

      tl2
        .to(".b20", 1, { opacity: 1, scale: 1, ease: Power4.easeInOut })
        .staggerTo(".b21", 1, { opacity: 1, scale: 1, y: 0 }, 0.2, 0.5);

      //    TweenMax.to(".brg", 1, { opacity: 1, scale: 1 });
    }, 1300);
  };
}

class Item2 extends Component {
  constructor() {
    super();
    this.slide = window.slide2 ? window.slide2 : window.slide6;
    this.state = {
      ...this.slide,
      day: 1
    };
  }

  render() {
    let future = this.calculateFuture().toLocaleString();
    let futuresm = parseInt(future * 0.85).toLocaleString();
    let timeleft = window.slide6.years - window.slide6.totalYears;
    //   console.log(this.props.history.push());
    let { scenarios } = this.state;
    let opt = scenarios[this.props.index];
    let nxt = this.props.index;
    nxt++;
    console.log(opt);

    return (
      <>
        <div className="px-3 py-3 info-block desc-text b20 pt-md-3 bg-primary">
          <b>
            {opt.options[this.props.match.params.option].outcome.title
              .replace(/\$futuresm/, futuresm)
              .replace(/\$future/, future)
              .replace(/\$timeleft/, timeleft)}
          </b>
        </div>

        <div className="mt-4 my-md-5" onClick={() => {}}>
          <div className="desc-text b21">
            {opt.options[this.props.match.params.option].outcome.desc}
          </div>
        </div>
        <Button
          back={false}
          className="b21"
          onClick={() => {
            this.go(mainPath + nxt, nxt);
          }}
        />
        <div className="bottom-hover w-100 b21">
          <ButtonSm
            back={false}
            className="b21"
            onClick={() => {
              this.go(mainPath + nxt, nxt);
            }}
          />
        </div>
      </>
    );
  }

  calculateFuture() {
    // y = 12;
    let p = window.calc.savings;
    let r = 0.12;
    let i = 1 + r / 12;

    let G = (i * (Math.pow(i, 60) - 1)) / (i - 1);

    return parseInt(G * p);
  }

  componentDidUpdate() {
    if (window.location.pathname === mainPath + this.props.index) {
      // let nxt = this.props.index;
      // console.log(nxt);
      // this.props.slide();
    }
  }

  componentDidMount() {
    this.props.slide(
      this.state.scenarios[this.props.index].options[
        this.props.match.params.option
      ].year,
      0
    );
  }

  go = (to, n) => {
    if (n === 5) {
      setTimeout(() => {
        this.props.history.push("/slide6/step4");
      }, 1700);
      //    return false;
    }
    //   TweenMax.to(".brg", 1, { opacity: 0, scale: 0.95 });
    let tl = new TimelineLite();
    tl.staggerTo(".b20", 0.5, { opacity: 0, scale: 0.95 }, -0.2).staggerTo(
      ".b21",
      1,
      { opacity: 0, scale: 0.95, y: 0 },
      0.2,
      0.3
    );

    setTimeout(() => {
      if (n !== 5) {
        this.props.history.push(to);
      }
      TweenMax.set(".b20", { opacity: 0, scale: 1.05 });

      TweenMax.set(".b21", {
        opacity: 0,
        scale: 1.2,
        y: 20,
        ease: Power4.easeInOut
      });
      let tl2 = new TimelineLite();

      tl2
        .to(".b20", 1, { opacity: 1, scale: 1, ease: Power4.easeInOut })
        .staggerTo(".b21", 1, { opacity: 1, scale: 1, y: 0 }, 0.2, 0.5);

      //    TweenMax.to(".brg", 1, { opacity: 1, scale: 1 });
    }, 1300);
  };
}
