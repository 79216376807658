import React, { Component } from "react";
import logo from "../../img/logo.png";
import arrow from "../../img/icons/carret_up.svg";

import { TweenMax, Power2, TimelineLite, Power4 } from "gsap/TweenMax";
import Option from "../components/options";

class S4 extends Component {
  state = {
    ...window.slide2,
    day: 3,
    totalSavings: window.calc.savings,
    future: 0,
    newSavings: window.calc.savings
  };

  render() {
    return (
      <div
        className="d-flex flex-fill flex-column align-items-center justify-content-center step-1 default-bg cover"
        style={{}}>
        <div className="d-flex flex-column align-items-center text-white text-center w-100">
          <div className="py-3 total-anim text-center text-gold position-relative border-0">
            <h4>{this.state.newSavings.toLocaleString()} </h4>
            <small className="text-white">
              This is what your Savings will earn you in 5 years.
            </small>
          </div>

          <div className="dateSlider w-100 position-relative">
            <div className="container px-0 position-relative">
              <div className="calendar-marker text-gold d-flex flex-column align-items-center justify-content-between">
                <span className="marker-text">DAY</span>
                <div className="mark" />
              </div>
              <div className="calendar-slider flex-row d-flex">
                {this.getCalendar(this.state.day)}
              </div>
            </div>
          </div>

          <div className="w-100 d-flex flex-column align-items-center brg">
            {this.state.scenarios.map((d, i) => (
              <Option
                key={i}
                index={i}
                slide={(day, percentage, option) => {
                  console.log(day);
                  if (typeof i !== "undefined" && typeof option !== "undefined")
                    window.slide2.results[i] = option;

                  this.slide(day, percentage);
                }}
              />
            ))}
          </div>
          <div className="flex-column justify-content-center d-flex mt-5">
            <img src={arrow} alt="" />
            <small className="text-primary mt-2 font-weight-bold text-sm">
              CHOOSE TO CONTINUE
            </small>
          </div>
        </div>
      </div>
    );
  }

  componentDidMount() {
    localStorage.cmplt1 = "true";
    //   this.calculateSavings();
    this.calculateFuture();
    let $t = this;
    setTimeout(function() {
      $t.slide($t.state.day);
    }, 100);
  }

  slide(day, p) {
    TweenMax.to(".calendar-slider", 2, {
      x: (2 - day) * 30,
      ease: Power4.easeInOut
    });
    //   console.log(p);
    if (p) {
      let future = this.state.future;
      //    console.log("percentage :", p);
      //    console.log("total :", future);
      console.log(
        "total : ",
        this.state.totalSavings * 0.01 * p,
        window.calc.savings,
        p
      );
      future += this.state.totalSavings * 0.01 * p;
      let { newSavings } = this.state;
      newSavings += this.state.totalSavings * 0.01 * p;
      window.calc.savings = newSavings;

      this.setState({ future, newSavings });
    }
  }

  calculateFuture() {
    // y = 12;
    //   let p = this.state.totalSavings;
    let p = this.state.totalSavings;
    let r = 0.12;
    let i = 1 + r / 12;

    let G = (i * (Math.pow(i, 60) - 1)) / (i - 1);

    this.setState({ future: parseInt(G * p) });
  }

  calculateSavings = () => {
    let total = 0;
    this.state.results.map(d => {
      total += d.amount;
    });
    // total += window.slide2.totals.opt1 ? window.slide2.totals.opt1 : 0;
    // console.log(total);
    this.setState({ total });
  };

  componentWillUnmount() {
    // window.calc.savings = this.state.totalSavings;
    // window.calc.future = this.state.future;
    // console.log("unmounted : ", window.calc.savings, window.calc.future);
  }

  getCalendar(active) {
    let items = [];
    for (let i = 1; i < 32; i++) {
      items.push(
        <div className={"slider-item " + (i === active ? "active" : "")}>
          {i}
        </div>
      );
    }
    return items;
  }
}

export default S4;
