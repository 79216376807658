import React, { Component } from "react";
import back_icon from "../../img/icon_left.png";
import Modal from "./modal";
import Alert from "./Alert";

class HoverCard extends Component {
 state = { phoneNumber: "" };
 render() {
  return (
   <>
    <div className="d-none">
     <div
      onClick={() => this.setState({ modalVisible: true })}
      className="hover-circle p-2 text-center d-flex flex-column align-items-center text-white justify-content-center d-md-none"
     >
      <div className="hhx-33">
       {this.props.text ? this.props.text : "Let’s help with your budget"}
      </div>
     </div>
    </div>

    <Modal show={this.state.modalVisible}>
     <div className="hover-card-center position-relative text-white">
      <div
       className={
        "layer2 px-4 py-5 " + (this.state.disabled ? "disable-card" : "")
       }
      >
       <i
        class="fas fa-times text-white position-absolute hover-card-clear"
        onClick={() => this.setState({ modalVisible: false })}
       />
       <small className="d-block">
        <b className="d-block text-white">
         BOOK A FREE CONSULTATION WITH A WEALTH EXPERT
        </b>
        No sales pitch , no strings attached, just good and honest advice.
       </small>

       <input
        type="text"
        className="py-1 my-3 text-center text-bronze card-input d-flex flex-fill w-100 "
        placeholder="Enter your phone number"
        value={this.state.phoneNumber}
        onChange={evt => this.setState({ phoneNumber: evt.target.value })}
       />

       <button
        className="btn w-100 text-center w-100 btn-primary"
        onClick={this.send}
       >
        <small className="font-weight-bold text-white">Let's talk</small>
       </button>
      </div>
     </div>
    </Modal>

    <div className=" hover-card d-xsm text-white">
     <div className="layer1 w-50 px-4 py-5 d-flex flex-column align-items-center h-100 justify-content-center">
      <div className="font-weight-bold text-center">
       {this.props.text ? this.props.text : "Get help with your real budget"}
      </div>
      <img
       src={back_icon}
       className="d-inline-block mt-3 hover-indicator"
       alt=""
      />
     </div>
     <div className="layer2 px-4 py-5">
      <small className="d-block">
       <b className="d-block">BOOK A FREE CONSULTATION WITH A WEALTH EXPERT</b>
       No sales pitch , no strings attached, just good and honest advice.
      </small>

      <input
       type="tel"
       className="py-1 my-3 text-center text-bronze card-input d-flex flex-fill w-100 "
       placeholder="Enter your phone number"
       value={this.state.phoneNumber}
       onChange={evt => this.setState({ phoneNumber: evt.target.value })}
      />

      <button
       className="btn w-100 text-center w-100 btn-primary"
       onClick={this.send}
      >
       <small className="font-weight-bold text-white">Let's talk</small>
      </button>
     </div>
    </div>
   </>
  );
 }

 send = () => {
  if (this.state.phoneNumber === "") {
   alert("please enter a Phone number to proceed");
   return false;
  }
  this.setState({ disabled: true });

  fetch(window.server + "saveRequest.php", {
   method: "POST",
   headers: {
    // "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/x-www-form-urlencoded"
   },
   body: `user=${localStorage.id}&phone=${this.state.phoneNumber}`
  })
   .then(response => response.text())
   .then(response => {
    this.setState({ disabled: false, modalVisible: false });
    console.log(response);
    alert("We will get back to you shortly");
   });
 };
}

export default HoverCard;
