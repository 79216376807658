import React, { Component } from "react";
import Button from "../components/button";
import logo from "../../img/logo.png";
import ButtonSm from "../components/buttonSm";
import HoverCard from "../components/hoverCard";
import swipeIcon from "../../img/icons/swipe.svg";
import hexagon_fill from "../../img/hexagon_fill.png";
import hexagon from "../../img/hexagon.png";
import Modal from "../components/modal";

import { Link } from "react-router-dom";
import Counter from "../components/counter";

class Step4_5 extends Component {
 state = { phoneNumber: "" };
 render() {
  window.scroll4();
  // update database
  return (
   <>
    <div className="slide d-flex flex-fill flex-column text-white align-items-center justify-content-center step-1 default-bg pt-3">
     <Modal show={this.state.modalVisible}>
      <div className="hover-card-center position-relative text-white">
       <div
        className={
         "layer2 px-4 py-5 " + (this.state.disabled ? "disable-card" : "")
        }
       >
        <i
         class="fas fa-times text-white position-absolute hover-card-clear"
         onClick={() => this.setState({ modalVisible: false })}
        />
        <small className="d-block">
         <b className="d-block text-white">
          BOOK A FREE CONSULTATION WITH A WEALTH EXPERT
         </b>
         No sales pitch , no strings attached, just good and honest advice.
        </small>

        <input
         type="text"
         className="py-1 my-3 text-center text-bronze card-input d-flex flex-fill w-100 "
         placeholder="Enter your phone number"
         value={this.state.phoneNumber}
         onChange={evt => this.setState({ phoneNumber: evt.target.value })}
        />

        <button
         className="btn w-100 text-center w-100 btn-primary"
         onClick={this.send}
        >
         <small className="font-weight-bold text-white">Let's talk</small>
        </button>
       </div>
      </div>
     </Modal>

     <Counter current={6} total={6} />
     <div className="d-flex flex-row w-100 text-center pjk3 pb-3">
      <div className="w-50 position-relative">
       <h2 className="">
        <b className="title-text tf">Your ratio</b>
       </h2>
       <div className="font-weight-bold">
        {this.state.needs} : {this.state.wants} : {this.state.balance}
       </div>
       <div className="hr-v" />
      </div>
      <div className="w-50 text-gold">
       <h2 className="">
        <b className="title-text tf">Healthy ratio</b>
       </h2>
       <div className="font-weight-bold">50 : 30 : 20</div>
      </div>
     </div>

     <div className="bar-parent intro-bar2 position-relative container mt-4">
      <div className="position-relative">
       <div className="bar my-3 mt-2 w-100 d-flex flex-row align-items-center justify-content-start">
        <div
         className="bar p-1 text-right bg-white pr-3"
         style={{ width: this.state.needs + "%" }}
        >
         <h6 className="text-gold m-0 my-1">{this.state.needs}%</h6>
        </div>
        <div className="bg-silver e-bar pr-2 d-flex flex-column align-items-center justify-content-center">
         <div className="text-gold percentage text-small font-weight-bold">
          {this.calcDisplacement(this.state.needs, 50)}%
         </div>
        </div>
        <span>
         <small className="ml-3">
          <b>NEEDS</b>
         </small>
        </span>
       </div>
       <div className="bar my-3 w-100 d-flex flex-row align-items-center justify-content-start">
        <div
         className="bar p-1 text-right bg-white pr-3"
         style={{ width: this.state.wants + "%" }}
        >
         <h6 className="text-gold m-0 my-1">{this.state.wants}%</h6>
        </div>
        <div className="bg-silver e-bar pr-2 d-flex flex-column align-items-center justify-content-center">
         <div className="text-gold percentage text-small font-weight-bold">
          {this.calcDisplacement(this.state.wants, 30)}%
         </div>
        </div>
        <span>
         <small className="ml-3">
          <b>WANTS</b>
         </small>
        </span>
       </div>
       <div className="bar mt-3 w-100 d-flex flex-row align-items-center justify-content-start">
        <div
         className="bar p-1 text-right bg-white pr-3"
         style={{ width: this.state.balance + "%" }}
        >
         <h6 className="text-gold m-0 my-1">{this.state.balance}%</h6>
        </div>
        <div className="bg-silver e-bar pr-2 d-flex flex-column align-items-center justify-content-center">
         <div className="text-gold percentage text-small font-weight-bold">
          {this.calcDisplacement(this.state.balance, 20)}%
         </div>
        </div>
        <span>
         <small className="ml-3">
          <b>SAVINGS</b>
         </small>
        </span>
       </div>

       <div className="bar-indicator bar-indicator-sm bg-white" />
      </div>
     </div>

     <div className="mt-3 text-center">
      <span className="desc-text font-weight-bold d-inline-block">
       If you had invested your savings over the past 5 years you would have
       earned
      </span>
     </div>
     <div className="my-md-4 my-2">
      <h1 className="text-center text-gold">
       {this.calculateFuture(60).toLocaleString()}
      </h1>
     </div>

     <div className="mt-3 text-center">
      <div
       to=""
       className="d-inline-block m-2 text-white hexagon-link "
       onClick={() => this.setState({ modalVisible: true })}
      >
       <div className="hexagon-fill d-flex justify-content-center align-items-center position-relative my-2 hf-22">
        <img
         src={hexagon_fill}
         className="w-100 position-absolute hx-fill"
         alt=""
        />
        <img
         src={hexagon}
         className="w-100 position-absolute hx-outline"
         alt=""
        />
        <div className="p-3 hexagon-text text-center">
         <small className="text-s font-weight-bold">Talk to an expert</small>
        </div>
       </div>
      </div>
     </div>

     {/* <div className="mt-5 d-n">
      <Button
       text="create your balanced budget"
       to="/slide1/step5"
       auto={true}
      />
     </div> */}
    </div>
    <div class="navigation-buttons d-md-none d-flex flex-row">
     <div class="">
      <Link to="/slide1/step6" class="btn bg-white ">
       <small class="text-gold text-sm text-uppercase">Make adjustments</small>
      </Link>
     </div>
     <div class="">
      <a
       download
       href="/php/7 STEPS TO WEALTH - BUDGET PLANNER V2.xlsx"
       class="btn bg-gold "
      >
       <small class="text-white text-sm text-uppercase">
        <b>DOWNLOAD PLAN</b>
       </small>
      </a>
     </div>
    </div>
   </>
  );
 }

 componentDidMount() {
  this.setState({
   needs: this.calculate(window.calc.needs),
   wants: this.calculate(window.calc.wants),
   balance: this.calculate(this.getBalance())
  });

  window.updatePersistence();
 }

 calculate(i) {
  console.log(i);
  return parseInt((i / window.calc.earnings) * 100);
 }

 getBalance = () => {
  let { calc } = window;
  let total = calc.earnings - (calc.needs + calc.wants);
  return total;
 };

 calcDisplacement(a, b) {
  let val = a - b;
  if (val >= 0) {
   val = "+ " + val;
  } else {
   val = "- " + val * -1;
  }
  return val;
 }

 calculateFuture(y) {
  let p = this.getBalance();
  let r = 0.12;
  let i = 1 + r / y;

  let G = (i * (Math.pow(i, y) - 1)) / i;

  return G * p;
 }

 send = () => {
  if (this.state.phoneNumber === "") {
   alert("please enter a Phone number to proceed");
   return false;
  }

  this.setState({ disabled: true });

  fetch(window.server + "saveRequest.php", {
   method: "POST",
   headers: {
    // "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/x-www-form-urlencoded"
   },
   body: `user=${localStorage.id}&phone=${this.state.phoneNumber}`
  })
   .then(response => response.text())
   .then(response => {
    this.setState({ disabled: false, modalVisible: false });
    console.log(response);
    alert("We will get back to you shortly");
   });
 };
}

export default Step4_5;
