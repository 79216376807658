import React, { Component } from "react";
import logo from "../../img/logo.png";
import Card from "../components/card";
import Button from "../components/button";
import playIcon from "../../img/play_icon.png";
import indicator from "../../img/indicator.png";
import { Link } from "react-router-dom";

// images
import hexagon from "../../img/hexagon.png";
import Modal from "../components/modal";
import ModalCard from "../components/modalCard";
import Input from "../components/input";
import arrow_right from "../../img/arrow_right.png";
import LoginModal from "../components/loginModal";
import Alert from "../components/Alert";
import ButtonSm from "../components/buttonSm";

class Step0_5 extends Component {
 state = {
  needs: {},
  wants: {},
  options: [
   "Is there a way to better manage the cost of living?",
   "Are there ways to still enjoy the finer things in life?",
   "Whats a good buffer to keep you growing and secure?"
  ],
  loginVisible: localStorage.id === undefined
 };
 render() {
  return (
   <>
    <div className="d-flex slide flex-fill flex-column align-items-center justify-content-center step-1 default-bg">
     <LoginModal
      path="/slide1/step0_5"
      modalVisible={this.state.loginVisible}
     />
     <div className="d-flex flex-column align-items-center text-white text-center">
      <img src={logo} alt="" className="main-logo d-none d-md-flex" />

      <div className=" mt-5 mt-md-4 text-center">
       <h3 className="text-center second-intro-text d-inline-block">
        <b className="title-text tf">
         Welcome to your Financial Health Checker
        </b>
       </h3>

       <div className="mt-3 desc-text font-weight-bold">
        You have seen Willis and Mary find the right balance for their spending.
        Now its time to find your balance.
       </div>
      </div>
      <div className="mt-md-3">
       <div className="d-inline-block m-1 m-md-2">
        <div className="hexagon-fill d-flex justify-content-center align-items-center position-relative mt-5 mb-md-5 stp1">
         <img src={hexagon} className="w-100 position-absolute" alt="" />
         <div className="p-3 hexagon-text">
          <span className="hexagon-text text-sm font-weight-">
           Is there a way to better manage the cost of living?
          </span>
         </div>
        </div>
       </div>

       <div className="d-inline-block stp2">
        <div className="d-inline-block m-1 m-md-2 stp1">
         <div className="hexagon-fill d-flex justify-content-center align-items-center position-relative my-5">
          <img src={hexagon} className="w-100 position-absolute" alt="" />
          <div className="p-3 hexagon-text">
           <span className="hexagon-text text-sm font-weight-">
            Are there ways to still enjoy the finer things in life?
            <span className="d-md-none">
             <br />
             <br />
            </span>
           </span>
          </div>
         </div>
        </div>

        <div className="d-inline-block m-1 m-md-2">
         <div className="hexagon-fill d-flex justify-content-center align-items-center position-relative my-5">
          <img src={hexagon} className="w-100 position-absolute" alt="" />
          <div className="p-3 hexagon-text">
           <span className="hexagon-text text-sm font-weight-">
            Whats a good buffer to keep you growing and secure?
           </span>
          </div>
         </div>
        </div>
       </div>
      </div>
      <div className="mt-md-5">
       <Button to="/slide1/step1" text="LET'S FIND OUT" />
      </div>
     </div>
    </div>
    <ButtonSm to="/slide1/step1" text="LET'S FIND OUT" />
   </>
  );
 }

 updateCheckbox = i => {
  this.setState({ currentCheckbox: i });
 };
}

export default Step0_5;
