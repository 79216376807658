import React, { Component } from "react";

class Alert extends Component {
 state = { visible: false, text: "" };
 render() {
  return (
   <div
    class={
     "alert-modal d-flex flex-column justify-content-center align-items-center p-2 " +
     (this.state.visible ? "show" : "")
    }
   >
    <div className="modal-inner position-relative d-flex flex-column justify-content-center align-items-center">
     <div
      className={"alert-card p-3 w-100 " + (this.state.visible ? "show" : "")}
     >
      <div className="content text-white text-center my-3">
       {this.state.text}
      </div>

      <button
       className="btn w-100 text-center w-100 mt-3 btn-primary"
       onClick={() => this.dismiss()}
      >
       <small className="font-weight-bold text-white">ok</small>
      </button>
     </div>
    </div>
   </div>
  );
 }

 alert = (text, callback) => {
  this.setState({ visible: true, text });
  if (callback) callback();
 };
 dismiss = () => {
  this.setState({ visible: false });
 };
}

export default Alert;
