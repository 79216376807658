import React, { Component } from "react";
import { Link } from "react-router-dom";
import arrow_right from "../../img/arrow_right.png";
import arrow_left from "../../img/icons/arrow_left.svg";
class Button extends Component {
  state = {};
  render() {
    return (
      <div
        className={
          "d-md-flex d-none flex-row flex-wrap align-items-center " +
          this.props.className
        }>
        {this.props.back !== false && (
          <div className="">
            <button
              className={
                "btn bg-white py-2 px-3  mx-2 d-inline-block d-flex flex-row align-items-center justify-content-between " +
                (this.props.auto ? "w-auto" : " btn-next")
              }
              onClick={() => {
                if (this.props.onClick) this.props.onClick();
                window.scrollBack();
                window.history.back();
              }}>
              {!this.props.noIcon && (
                <img src={arrow_left} alt="" className="mr-2" />
              )}
              <small className={"text-gold text-sm text-uppercase"}>
                <b>{this.props.backText ? this.props.backText : "GO BACK"}</b>
              </small>
              <div />
            </button>
          </div>
        )}
        <div className="">
          <Link
            to={this.props.to}
            className={
              "btn bg-gold py-2 px-3  mx-2 d-inline-block d-flex flex-row align-items-center justify-content-between " +
              (this.props.auto ? "w-auto" : " btn-next")
            }
            onClick={() => {
              if (this.props.onClick) this.props.onClick();
              window.scrollBack();
            }}>
            <div />
            <small className={"text-white text-sm "}>
              <b>
                {this.props.text ? this.props.text.toUpperCase() : "CONTINUE"}
              </b>
            </small>
            {!this.props.noIcon && (
              <img src={arrow_right} alt="" className="ml-2" />
            )}
          </Link>
        </div>
      </div>
    );
  }
}

export default Button;
