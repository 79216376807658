import React, { Component } from "react";
import back_icon from "../../img/icon_left.png";
import down_icon from "../../img/icon_down.png";

class SlideFooter extends Component {
 state = {};
 render() {
  return (
   <div className="text-white slide-footer position-absolute w-100 d-none">
    <div className="container py-2 d-flex flex-row justify-content-center">
     {/* <div
      onClick={() => this.goBack()}
      className="text-sm font-weight-bold footer-btn d-inline-block d-flex flex-row align-items-center"
     >
      <span>GO BACK</span>
      <img src={back_icon} className="d-inline-block ml-2" alt="" />
     </div> */}

     <div
      className="text-sm font-weight-bold footer-btn d-inline-block d-flex flex-row align-items-center scroll-button"
      data-target="#lessons-section"
     >
      <span>LESSONS TO LEARN</span>
      <img src={down_icon} className="d-inline-block ml-2" alt="" />
     </div>
    </div>
   </div>
  );
 }
}

export default SlideFooter;
