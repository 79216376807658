import React, { Component } from "react";
import logo from "../../img/logo.png";

import { Link } from "react-router-dom";
import bg_ring from "../../img/icons/Results/bg_ring.svg";
import Button from "../components/button";
import Range from "../components/rangeSlider";
import carret from "../../img/icons/arrow.svg";
import HoverCard from "../components/hoverCard";

class S3 extends Component {
  state = {
    total: 0,
    index: 0,
    monthlyTotal: 0,
    currentTotal:
      window.slide3.options[window.slide3.active].data[0].lowerLimit,
    ...window.slide3
  };
  render() {
    let option = this.state.options[window.slide3.active];

    return (
      <>
        <HoverCard text="Get help planning for your dream" />
        <div className="d-flex flex-fill slide flex-column align-items-center justify-content-center step-1 default-bg">
          <div className="d-none d-md-flex flex-column align-items-center">
            <img src={logo} alt="" className="main-logo" />

            <div className="my-3 text-center">
              <h5 className="lts font-weight-bold text-white">
                When would you like to reach it?
              </h5>
              <span className="text-white px-3 d-inline-block desc-text">
                Enter below number to continue
              </span>
            </div>
          </div>
          <div className="d-flex flex-row align-items-center col-md-6 mt-md-0 year-input mb-3 mt-4">
            <input
              type="text"
              name=""
              className="py-2 d-flex amt-i flex-fill px-3 text-left text-bronze card-input no-anim d-flex input w-100"
              value={this.state.years.toLocaleString() + " Years"}
              placeholder="How much was it?"
              disabled
              onChange={() => {
                this.set();
                console.log("Total :: ", this.state.currentTotal);
                this.calculate(this.state.currentTotal);
              }}
            />
            <div className="d-flex flex-row bg-white toggle-button-parent">
              <button
                className="btn toggle-button"
                onClick={() => this.updateInput(-1)}>
                -
              </button>
              <button
                className="btn toggle-button"
                onClick={() => {
                  this.updateInput(1);
                }}>
                +
              </button>
            </div>
          </div>

          <div className="d-flex flex-column align-items-center text-white text-center w-100">
            <div className="d-flex flex-row justify-content-between align-items-center w-100 p-3 monthly-savings">
              <div className="d-flex flex-row align-items-center">
                <div className="d-flex justify-content-center flex-column align-items-center position-relative">
                  <div className="d-flex justify-content-center flex-column align-items-center position-relative">
                    <img
                      src={bg_ring}
                      alt=""
                      className="align-self-center text-gold"
                    />
                    <img
                      src={option.icon}
                      alt=""
                      className="position-absolute align-self-center icon text-dark"
                    />
                  </div>
                  <div className=" habit-title text-center mt-1 text-white ln">
                    <small className="goal-title">{option.name}</small>
                  </div>
                </div>
                <div className="text-left ml-2">
                  <div>
                    <h2 className="text-gold m-0 mb-1">
                      {this.state.monthlyTotal.toLocaleString()}
                    </h2>
                  </div>
                  <div className="ln">
                    <small>
                      This is what you’ll need to save a month in order to get
                      there
                    </small>
                  </div>
                </div>
              </div>
            </div>

            <div className="custom-divider d-flex flex-row justify-content-center mt-2 mb-2">
              <img src={carret} alt="" />
            </div>

            <div className="my-md-3 p-3 row slider-row container">
              {this.state.options[window.slide3.active].data.map((d, i) => (
                <div className="col-md-4">
                  <Range
                    key={i}
                    data={d}
                    percentage={d.percentage}
                    updateTotal={(total, percentage) => {
                      this.calculate(total, i, percentage);
                    }}
                  />
                </div>
              ))}
            </div>

            {/* <Button backText="Another Goal" to="/slide3/step4" text="Continue" /> */}
            <div class="d-md-flex d-none flex-row flex-wrap align-items-center">
              <div class="">
                <Link
                  to="/slide3/step2"
                  class="btn bg-white py-2 px-3  mx-2 d-inline-block d-flex flex-row align-items-center justify-content-between  btn-next">
                  <div />
                  <small class="text-gold text-sm text-uppercase">
                    <b>Another Goal</b>
                  </small>
                  <div />
                </Link>
              </div>
              <div class="">
                <Link
                  to="/slide3/step4"
                  class="btn bg-gold py-2 px-3  mx-2 d-inline-block d-flex flex-row align-items-center justify-content-between  btn-next"
                  href="/slide3/step4">
                  <div />
                  <small class="text-white text-sm ">
                    <b>CONTINUE</b>
                  </small>
                  <div />
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="navigation-buttons d-md-none d-flex flex-row">
          <div className="">
            <Link to="/slide3/step2" className="btn btn-sm-back">
              <small className="text-white text-uppercase font-weight-bold">
                Another Goal
              </small>
            </Link>
          </div>
          <div className="d-flex flex-fill ">
            <Link
              to="/slide3/step4"
              className="btn btn-sm-next "
              href="/slide3/step4">
              <small className="text-white text-uppercase">
                <b>Continue</b>
              </small>
            </Link>
          </div>
        </div>
      </>
    );
  }

  componentDidMount() {
    this.set();
    if (this.state.monthlyTotal === 0) {
      this.calculate(this.state.currentTotal);
    } else {
      this.setState({ monthlyTotal: window.slide3.monthlyTotal });
    }
  }

  componentWillUnmount() {
    window.slide3.years = this.state.years;
    window.slide3.monthlyTotal = this.state.monthlyTotal;
    localStorage.slide3 = JSON.stringify(window.slide3);
  }

  set = () => {
    let r = 0.1;
    let R = 1 + r / 12;
    this.G = (R * Math.pow(R, this.state.years * 12) - 1) / (R - 1);
  };

  calculate = (total, i, percentage) => {
    if (i !== this.state.index) {
      let options = this.state.options;
      options[window.slide3.active].data = options[
        window.slide3.active
      ].data.map((d, indx) => {
        if (i !== indx) {
          d.percentage = 0;
        } else {
          d.percentage = percentage;
        }
        return d;
      });
      console.log("options ::", options);

      this.setState({ options });
    }

    this.setState({
      monthlyTotal: parseInt(total / this.G),
      currentTotal: total,
      index: i
    });
  };

  updateInput = i => {
    let years = this.state.years;
    if (years === "") years = 0;
    years += i;
    if (years < 0) return false;
    window.slide3.years = years;
    this.setState({ years });

    setTimeout(() => {
      this.set();
      this.calculate(this.state.currentTotal);
    }, 0);
  };
}

export default S3;
