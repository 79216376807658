import React, { Component } from "react";
import { Route, BrowserRouter as Router } from "react-router-dom";
import StartUp from "../../img/icons/goals/StartUp.svg";
import S3 from "./s3";
import S4 from "./s4";
import S5 from "./s5";
import S6 from "./s6";

class Parent extends Component {
  state = {
    data: window.slide4.data
  };
  render() {
    return (
      <>
        {this.state.data.map((d, i) => {
          let el = [];
          el.push(
            <Route
              key={"a" + i}
              path={`/slide4/step3/${i}`}
              render={props => <S3 {...props} index={i} />}
              exact
            />
          );

          d.steps.map((data, index) => {
            el.push(
              <Route
                key={"c" + index}
                path={`/slide4/step4/${i}/${index}`}
                render={props => <S4 {...props} parent={i} index={index} />}
                exact
              />
            );

            el.push(
              <Route
                key={"b" + i}
                path={`/slide4/step6/${i}/${index}`}
                render={props => <S6 {...props} child={index} index={i} />}
                exact
              />
            );
          });

          return el;
        })}
      </>
    );
  }
}

export default Parent;
