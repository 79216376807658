import React, { Component } from "react";
import Input from "../../components/input";
import StepperItem from "../../components/stepperItem";
import Button from "../../components/button";
import logo from "../../../img/logo.png";

import leaf from "../../../img/leaf.png";
import HoverCard from "../../components/hoverCard";
import arrow_right from "../../../img/arrow_right.png";
import ButtonSm from "../../components/buttonSm";

class Step6 extends Component {
 state = {};
 render() {
  return (
   <>
    <div className="slide d-flex flex-fill align-items-center justify-content-center text-white default-bg flex-column py-4">
     <HoverCard />

     <img src={logo} className="main-logo " />
     <div className="mt-4">
      <h3 class="text-center second-intro-text d-inline-block mb-3 tex-center w-100">
       <b class="title-text tf">Create your plan for wealth</b>
      </h3>
      <div className="stepper-text-section text-center px-3">
       <span className="desc-text font-weight-bold d-inline-block brxw">
        Now you can create a plan for you to try and follow. Adjust the values
        to try and keep the 50 : 30: 20 rule. Remember to download your
        personalized plan before you go.
       </span>
      </div>
     </div>
    </div>
    <ButtonSm to="/slide1/step7" />
   </>
  );
 }
}

export default Step6;
