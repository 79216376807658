import React, { Component } from "react";
import Button from "../components/button";
import logo from "../../img/logo.png";
import ButtonSm from "../components/buttonSm";
import HoverCard from "../components/hoverCard";
import swipeIcon from "../../img/icons/swipe.svg";
import hexagon_fill from "../../img/hexagon_fill.png";
import hexagon from "../../img/hexagon.png";
import Modal from "../components/modal";

import { Link } from "react-router-dom";
import Counter from "../components/counter";

class Step4_5m extends Component {
 state = { percentages: {} };
 render() {
  window.scroll4();
  // update database
  return (
   <>
    <div className="slide d-flex flex-fill flex-column text-white align-items-center justify-content-center step-1 default-bg pt-3">
     <div className="p-3 text-center">
      <div className="ratio-text d-inline-block">
       <h5>
        <b>Your spending ratio.</b>
       </h5>
       <div className="pb-3">
        <small>This is how you compare to the 50% - 30% - 20% Standard</small>
       </div>
      </div>

      <div className="v-p d-flex flex-row justify-content-center mb-4 mt-5 align-items-end">
       <div className="px-0 mn text-center d-flex flex-column justify-content-end h-100">
        <small className="ead6af">
         {this.calcDisplacement(this.state.needs, 50)}%
        </small>
        <div
         className="vertical-bar px-3 py-2 text-gold mx-1 mb-1 needs"
         style={{ minHeight: this.state.percentages.needs + "%" }}
        >
         {this.state.needs}%
        </div>
        <small>NEEDS</small>
       </div>

       <div className="px-0 mn text-center d-flex flex-column justify-content-end h-100">
        <small className="ead6af">
         {this.calcDisplacement(this.state.wants, 30)}%
        </small>
        <div
         className="vertical-bar px-3 py-2 text-gold mx-1 mb-1 wants"
         style={{ minHeight: this.state.percentages.wants + "%" }}
        >
         {this.state.wants}%
        </div>
        <small>WANTS</small>
       </div>

       <div className="px-0 mn text-center d-flex flex-column justify-content-end h-100">
        <small className="ead6af">
         {this.calcDisplacement(this.state.balance, 20)}%
        </small>
        <div
         className="vertical-bar px-3 py-2 text-gold mx-1 mb-1 savings"
         style={{ minHeight: this.state.percentages.balance + "%" }}
        >
         {this.state.balance}%
        </div>
        <small>SAVINGS</small>
       </div>
      </div>

      <div className="py-3 total-anim text-center text-gold my-3">
       <h3> {this.calculateFuture(60).toLocaleString()} </h3>
       <span className="text-white">
        This is what it will earn you in 5 years
       </span>
      </div>
     </div>
    </div>
    <div class="navigation-buttons d-md-none d-flex flex-row">
     <div class="">
      <Link to="/slide1/step7" class="btn btn-sm-back">
       <small class="text-white text-uppercase font-weight-bold">
        Earn more
       </small>
      </Link>
     </div>
     <div class="">
      <Link to="/slide1/step11" class="btn btn-sm-next">
       <small class="text-white text-uppercase font-weight-bold">
        Finish Course
       </small>
      </Link>
     </div>
    </div>
   </>
  );
 }

 componentDidMount() {
  let t = {
   needs: this.calculate(window.calc.needs),
   wants: this.calculate(window.calc.wants),
   balance: this.calculate(this.getBalance())
  };

  let max = 100 / Math.max(t.needs, t.wants, t.balance);
  console.log(max, {
   needs: t.needs * max,
   wants: t.wants * max,
   balance: t.balance * max
  });
  this.setState({
   ...t,
   percentages: {
    needs: t.needs * max,
    wants: t.wants * max,
    balance: t.balance * max
   }
  });

  window.updatePersistence();
 }

 calculate(i) {
  // console.log(i);
  return parseInt((i / window.calc.earnings) * 100);
 }

 getBalance = () => {
  let { calc } = window;
  let total = calc.earnings - (calc.needs + calc.wants);
  return total;
 };

 calcDisplacement(a, b) {
  let val = a - b;

  if (val > 0) {
   val = "+ " + val;
  } else if (val === 0) {
   console.log(val);
   val = 0;
  } else {
   val = "- " + val * -1;
  }

  return val;
 }

 calculateFuture(y) {
  // y = 12;
  let p = this.getBalance();
  let r = 0.12;
  let i = 1 + r / 12;

  let G = (i * (Math.pow(i, y) - 1)) / (i - 1);

  return parseInt(G * p);
 }

 send = () => {
  if (this.state.phoneNumber === "") {
   alert("please enter a Phone number to proceed");
   return false;
  }

  this.setState({ disabled: true });

  fetch(window.server + "saveRequest.php", {
   method: "POST",
   headers: {
    // "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/x-www-form-urlencoded"
   },
   body: `user=${localStorage.id}&phone=${this.state.phoneNumber}`
  })
   .then(response => response.text())
   .then(response => {
    this.setState({ disabled: false, modalVisible: false });
    console.log(response);
    alert("We will get back to you shortly");
   });
 };
}

export default Step4_5m;
