import React, { Component } from "react";
import logo from "../../img/logo.png";
import arrow from "../../img/icons/carret_up.svg";

import { TweenMax, Power4 } from "gsap/TweenMax";
import Option from "../components/options3";

class S3 extends Component {
  state = {
    ...window.slide6,
    day: 2019,
    totalSavings: window.calc.savings,
    future: 0
  };

  render() {
    return (
      <div className="d-flex flex-fill flex-column align-items-center justify-content-center step-1 default-bg cover">
        <div className="d-flex flex-column align-items-center text-white text-center w-100">
          {/* <div className="yearSlider w-100 position-relative">
            <div className="container px-0 position-relative">
              <div className="calendar-marker text-gold d-flex flex-column align-items-center justify-content-between">
                <span className="marker-text">YEAR</span>
                <div className="mark" />
              </div>
              <div className="calendar-slider flex-row d-flex">
                {this.getCalendar(this.state.day)}
              </div>
            </div>
          </div> */}

          <div className="w-100 d-flex flex-column align-items-center brg-">
            {this.state.scenarios.map((d, i) => (
              <Option
                key={i}
                index={i}
                slide={(day, y, option) => {
                  let years = window.slide6.years;
                  let year = 0;
                  eval(y);
                  console.log(year, y);
                  this.setState({ day: this.state.day + year });
                  window.slide6.totalYears =
                    parseInt(this.state.day) + year - 2019;
                  if (typeof i !== "undefined" && typeof option !== "undefined")
                    window.slide6.results[i] = option;
                  setTimeout(() => {
                    this.slide();
                  }, 0);
                }}
              />
            ))}
          </div>
        </div>
      </div>
    );
  }

  componentDidMount() {
    localStorage.cmplt1 = "true";
    //   this.calculateSavings();
    this.calculateFuture();
    let $t = this;
    setTimeout(function() {
      $t.slide($t.state.day);
    }, 100);
  }

  slide(animate) {
    let day = this.state.day;
    if (animate === false) {
      TweenMax.set(".calendar-slider", {
        x: (1 - (2019 + day)) * 60,
        ease: Power4.easeInOut
      });
      return;
    }
    TweenMax.to(".calendar-slider", 2, {
      x: (1 + (2019 - day)) * 60,
      ease: Power4.easeInOut
    });
  }

  calculateFuture() {
    // y = 12;
    //   let p = this.state.totalSavings;
    let p = this.state.totalSavings;
    let r = 0.12;
    let i = 1 + r / 12;

    let G = (i * (Math.pow(i, 60) - 1)) / (i - 1);

    this.setState({ future: parseInt(G * p) });
  }

  calculateSavings = () => {
    let total = 0;
    this.state.results.map(d => {
      total += d.amount;
    });
    // total += window.slide2.totals.opt1 ? window.slide2.totals.opt1 : 0;
    // console.log(total);
    this.setState({ total });
  };

  componentWillUnmount() {
    window.calc.savings = this.state.totalSavings;
    window.calc.future = this.state.future;
    console.log("unmounted : ", window.calc.savings, window.calc.future);
  }

  getCalendar(active) {
    let items = [];
    for (let i = 2019; i < 2050; i++) {
      items.push(
        <div
          key={i}
          className={"slider-item " + (i === active ? "active" : "")}>
          {i}
        </div>
      );
    }
    // this.slide();
    return items;
  }
}

export default S3;
