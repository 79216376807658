import React, { Component } from "react";
import { Route, Link } from "react-router-dom";
import { spring, AnimatedSwitch } from "react-router-transition";
import { TweenMax, Power2, TimelineLite, Power4 } from "gsap/TweenMax";
import hexagon from "../../img/icons/options/hexagon.svg";
import or from "../../img/icons/options/or.svg";

let mainPath;
class Option extends Component {
  constructor() {
    super();
    this.slide = window.slide2 ? window.slide2 : window.slide6;
    this.state = {
      ...this.slide,
      day: 1
    };
  }
  render() {
    mainPath =
      window.location.pathname
        .split("/")
        .splice(0, 3)
        .join("/") + "/";
    // console.log(mainPath);
    return (
      <Route
        exact
        path={mainPath + this.props.index}
        render={routeProps => (
          <Item
            slide={(p, o) => {
              this.props.slide(
                this.state.scenarios[
                  this.props.index !== 4 ? this.props.index + 1 : 4
                ].day,
                p,
                o
              );
            }}
            {...routeProps}
            index={this.props.index}
          />
        )}
      />
    );
  }

  //  componentWillReceiveProps() {
  //   //   console.log("message");
  //  }
}

export default Option;

class Item extends Component {
  constructor() {
    super();
    this.slide = window.slide2 ? window.slide2 : window.slide6;
    this.state = {
      ...this.slide,
      day: 1
    };
  }

  render() {
    //   console.log(this.props.history.push());
    let { scenarios } = this.state;
    let opt = scenarios[this.props.index];
    let nxt = this.props.index;
    nxt++;
    let mnt1 = Math.abs(
      window.calc.savings * 0.01 * opt.options[0].percentage
    ).toLocaleString();
    let mnt2 = Math.abs(
      window.calc.savings * 0.01 * opt.options[1].percentage
    ).toLocaleString();

    return (
      <>
        <div className="px-3 py-3 info-block desc-text b20 pt-md-3">
          <b>{opt.desc}</b>
        </div>

        <div className="mt-4">
          <div
            onClick={() => {
              this.go(mainPath + nxt, nxt);
              this.props.slide(opt.options[0].percentage, 0);
              //    console.log(opt.options[0].percentage);
            }}
            to={mainPath + nxt}
            className="py-3 text-white d-flex flex-row s4-option contain b21">
            <div className="icon-option d-flex justify-content-center flex-column align-items-center">
              <img
                src={hexagon}
                alt=""
                className="position-absolute align-self-center shadow-hexagon"
              />
              <img
                src={hexagon}
                alt=""
                className="position-absolute align-self-center"
              />

              <img
                src={opt.options[0].icon}
                alt=""
                className="position-absolute align-self-center icon"
              />
            </div>
            <div className="d-flex flex-fill">
              <small className="text-left">
                {opt.options[0].title.replace("$mnt", mnt1)}
              </small>
            </div>
          </div>
          <div className="position-relative b21">
            <div className="position-absolute or-icon-sep px-3" />
            <div className="gold-hr my-4" />
            <div className="position-absolute or-icon px-3">
              <img src={or} alt="" />
            </div>
          </div>
          <div
            onClick={() => {
              this.go(mainPath + nxt, nxt);
              this.props.slide(opt.options[1].percentage, 1);
            }}
            to={mainPath + nxt}
            className="py-3 text-white d-flex flex-row s4-option contain b21">
            <div className="d-flex flex-fill">
              <small className="text-right">
                {opt.options[1].title.replace("$mnt", mnt2)}
              </small>
              <div className="icon-option d-flex justify-content-center flex-column align-items-center">
                <img
                  src={hexagon}
                  alt=""
                  className="position-absolute align-self-center shadow-hexagon delay"
                />
                <img
                  src={hexagon}
                  alt=""
                  className="position-absolute align-self-center"
                />
                <img
                  src={opt.options[1].icon}
                  alt=""
                  className="position-absolute align-self-center icon"
                />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }

  componentDidUpdate() {
    if (window.location.pathname === mainPath + this.props.index) {
      let nxt = this.props.index;
      console.log(nxt);
      this.props.slide();
    }
  }

  go = (to, n) => {
    if (n === 5) {
      setTimeout(() => {
        this.props.history.push("/slide2/step5");
      }, 1700);
      //    return false;
    }
    //   TweenMax.to(".brg", 1, { opacity: 0, scale: 0.95 });
    let tl = new TimelineLite();
    tl.staggerTo(".b20", 0.5, { opacity: 0, scale: 0.95 }, -0.2).staggerTo(
      ".b21",
      1,
      { opacity: 0, scale: 0.95, y: 0 },
      0.2,
      0.3
    );

    setTimeout(() => {
      if (n !== 5) {
        this.props.history.push(to);
      }
      TweenMax.set(".b20", { opacity: 0, scale: 1.05 });

      TweenMax.set(".b21", {
        opacity: 0,
        scale: 1.2,
        y: 20,
        ease: Power4.easeInOut
      });
      let tl2 = new TimelineLite();

      tl2
        .to(".b20", 1, { opacity: 1, scale: 1, ease: Power4.easeInOut })
        .staggerTo(".b21", 1, { opacity: 1, scale: 1, y: 0 }, 0.2, 0.5);

      //    TweenMax.to(".brg", 1, { opacity: 1, scale: 1 });
    }, 1300);
  };
}
