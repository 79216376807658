import React, { Component } from "react";
import logo from "../../img/logo.png";
import Card from "../components/card";
import Button from "../components/button";
import ButtonSm from "../components/buttonSm";
import HoverCard from "../components/hoverCard";

import earnings from "../../img/icons/Earnings.svg";
import Counter from "../components/counter";

class Step2 extends Component {
 state = {
  earnings: window.calc.earnings === 1 ? 10000 : window.calc.earnings
 };
 render() {
  // update database
  return (
   <>
    <div className="slide d-flex flex-fill flex-column align-items-center justify-content-center step-1 default-bg">
     <HoverCard />

     <div className="d-flex flex-column align-items-center text-white text-center">
      <img src={logo} className="main-logo d-xsm" />
      <Counter current={4} total={6} />

      <div className=" px-3 px-md-0">
       <h2 className="mt-3">
        <b className="title-text tf">How much do you earn in a month?</b>
       </h2>
       <div className="mt-3">
        <span className="skip-text">
         <span className="desc-text font-weight-bold d-inline-block my-2">
          What is the sum total of all your income from all sources. If you
          don’t have a salary give your 6 month average.
         </span>
        </span>
       </div>
      </div>
      <div className="text-center mt-4">
       <Card
        icon={earnings}
        title="EARNINGS"
        subtitle="Salary, business, rent, investments etc."
        value={{ EARNINGS: this.state.earnings }}
        getValue={value => {
         this.setState({ earnings: value.EARNINGS });
         window.calc.earnings = value.EARNINGS;
        }}
       />
      </div>
      <div className="mt-4">
       <Button to="/slide1/step3" onClick={this.updateStorage} />
      </div>
     </div>
    </div>
    <ButtonSm to="/slide1/step3" onClick={this.updateStorage} />
   </>
  );
 }

 componentDidMount() {
  window.updatePersistence();
 }

 updateStorage = () => {
  //   if (localStorage.calc) {
  //    let calc = JSON.parse(localStorage.calc);
  window.calc = {
   ...window.calc,
   earnings: this.state.earnings,
   savings: this.state.earnings - (window.calc.needs + window.calc.wants)
  };
  window.store.savings.SAVINGS =
   this.state.earnings - (window.calc.needs + window.calc.wants);
  localStorage.calc = JSON.stringify(window.calc);
  //   }
 };
}

export default Step2;
