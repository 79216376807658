import React, { Component } from "react";
// images
import article1 from "../../../img/articles/slide3_article2.jpg";
import article2 from "../../../img/articles/slide3_article1_small.jpg";
import { Link } from "react-router-dom";
import Nav from "../../components/nav";

import twitter_icon from "../../../img/twitter_icon.png";
import facebook_icon from "../../../img/facebook_icon.png";
import Footer from "../../components/Footer";
import logo from "../../../img/logo.png";

class Article2 extends Component {
  state = {};
  render() {
    window.scrollTo(0, 0);
    return (
      <>
        <Nav />
        <div className="article-parent">
          <div className="article-header position-relative">
            <img
              src={article1}
              alt=""
              className="position-absolute h-100 w-100 article-header-image"
            />
            <div className="container d-flex flex-column flex-fill justify-content-between h-100 text-white">
              <div className="w-100 text-center mt-5">
                <img src={logo} alt="" className="main-logo" />
              </div>

              <div>
                <h1 className="font-weight-bold">
                  The 7 benefits of sticking to the Plan
                </h1>

                <div className="text-white align-items-center mb-5">
                  <div className="font-weight-normal d-flex align-items-center flex-row">
                    <small className="font-weight-normal">23 March 2019</small>
                    <div className="d-inline-block mx-2">|</div>
                    <small className="font-weight-normal">Share</small>
                    <div className="d-flex flex-row ml-2">
                      <a
                        href="https://twitter.com/AmanaCapitalKE"
                        className="mr-3">
                        <img
                          src={twitter_icon}
                          alt=""
                          className="social-icon"
                        />
                      </a>

                      <a
                        href="https://www.facebook.com/amanacapital2003/"
                        className="mr-3">
                        <img
                          src={facebook_icon}
                          alt=""
                          className="social-icon"
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="container pb-5">
            <div className="text-muted mt-3 mb-5">
              <small>
                Home / Blog / The 7 benefits of sticking to the Plan
              </small>
            </div>

            <div className="row">
              <div className="col-md-9">
                <div className="text-dark article-text">
                  <p>
                    Sticking to your savings plan is obviously crucial in order
                    for you to achieve the wealth that you require to be truly
                    financially free. However, as you proceed down the path to
                    wealth you will find that the discipline and consistency
                    required brings many other great benefits to your life.
                  </p>
                  <b className="mb-2 d-block">
                    Staying in is the new going out
                  </b>
                  <p>
                    One of the benefits of being in control of your money is
                    that you become much more conscious of what you are spending
                    it on. Especially when we are younger, spending our money
                    out and about with friends in clubs and restaurants is a
                    major drain on our finances. Taking control of those
                    finances will inevitably lead you to spending more quality
                    time, for less money, at home with your friends and family.
                    No more 10,000 shillings nights out, many more dinner
                    parties, game nights and movie marathons.
                  </p>
                  <b className="mb-2 d-block">Am I loosing weight?</b>
                  <p>
                    Another common drain on our resources, which we are often
                    unaware of, is the money that we spend on snacks, sugary
                    drinks and the like. When you are consciously in control of
                    your money you will find that you start asking yourself if
                    you really do want that chocolate bar, that double scoop ice
                    cream or that extra helping of fries. Financial discipline
                    is part of a holistic approach of self discipline. Sit back
                    and watch as your waist enjoys the benefits.
                  </p>
                  <b className="mb-2 d-block">Do I really need that?</b>A pair
                  of shoes. A new computer game. A fluffy toy to hand from your
                  car's rear view mirror. A multi-purpose
                  blender/grinder/de-humidifier.
                  <p>
                    Impulse buying is the curse of the consumerist age, and we
                    are all liable to engage in it in one degree or another.
                    Again, there is no better way to get on top of your impulse
                    buying habits than holding to your savings plan like a baby
                    to a mother. No more shall you seek comfort in the clutter
                    of consumer goods. Your pocket, and your storage space,
                    shall thank you.
                  </p>
                  <b className="mb-2 d-block">Together forever</b>
                  <p>
                    Saving as a couple, controlling your finances together, is a
                    little discussed but crucial aspect of any successful
                    relationship. The very process outlined in the 7 Steps to
                    wealth of setting goals, analysing options and making
                    financial decisions shall bring you closer together. After
                    that, month in and month out, the journey of working towards
                    a shared goal will work wonders for the bond between the two
                    of you.
                  </p>
                  <b className="mb-2 d-block">Focus Focus Focus</b>
                  <p>
                    You will find that the rigors of a well thought out savings
                    plan have a great affect upon your performance in your day
                    to day work. Many of the things that motivate us are
                    sub-conscious. Often when we are unhappy in our work or job
                    it actually stems from a lack of focus or purpose. Even
                    though you may not be aware of it, what your unconscious
                    self is asking itself day in and day out is “why am I doing
                    this, what am i doing this for, who am I doing this for,
                    shall I have to do this forever?” Achieving financial
                    freedom is the fundamental reality of the modern age. Once
                    you have made the commitment to yourself to set upon the
                    path to that freedom in a logical and systematic manner,
                    your subconscious shall be a far more peaceful place, and
                    your work, your focus, and ultimately your bank account,
                    shall be the number one beneficiary.
                  </p>
                  <b className="mb-2 d-block">I deserve it</b>
                  <p>
                    As time passes, one of the greatest benefits of sticking to
                    your savings plan is the ever increasing feeling of
                    satisfaction and pride as you watch your savings pot grow
                    and grow and grow. It is a self-fulfilling cycle. You stick
                    to the plan, your savings grow. The more your savings grow,
                    the more you re-enforce for yourself why you are doing what
                    you are doing, and therefore the easier it becomes for you
                    to do it. Wealth and financial freedom is earned, not in the
                    sense of a salary, but rather by the ability to maintain
                    commitment and determination. Nothing feels better than
                    knowing that you are well on your way to being free of the
                    tyranny of bills. Enjoy the feeling, you deserve it.
                  </p>
                  <b className="mb-2 d-block">Knowledge is power</b>
                  <p>
                    The more that you enter the world of financial empowerment,
                    the more you will be financially empowered. Financial
                    literacy is often taken as a given – I know how to make sure
                    that I spend less than I earn therefore I am ok. This is but
                    the most basic level of financial empowerment. As you
                    conduct your studies into the world of savings and
                    investments, as you investigate the various savings options
                    available to you, as you learn about Unit Trusts and
                    equities and T-Bills, you will find that your ever deepening
                    understanding of financial matters makes you a more
                    successful, insightful and rational employee, employer,
                    business person and investor. Your savings plan is but one
                    of the strings in your financial bow. Mastering it, and
                    sticking to it, will make all the other strings to your bow
                    that much more powerfull. Never stop reading, never stop
                    learning, never stop studying, never stop saving. That is
                    how, step by step by step by step, the wealth that you want
                    shall be yours.
                  </p>{" "}
                </div>
              </div>

              <div className="col-md-3">
                <b>Related Articles</b>

                <Link to="/slide4/article1">
                  <div className="bg-light my-5 position-relative">
                    <img src={article2} alt="" className="w-100" />
                    <div className="red-text p-3">
                      <small>Weighing your savings options</small>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

export default Article2;
