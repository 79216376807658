import React, { Component } from "react";
import { Route, BrowserRouter as Router } from "react-router-dom";
import amana_logo from "../../img/amana-logo.png";
import S1 from "./s1";

import Nav from "../components/nav";
import S2 from "./s2";
import S3 from "./s3";
import S4 from "./s4";
import S2_5 from "./s2_5";
import Article1 from "./articles/article1";
import Article2 from "./articles/article2";

class Slide3Home extends Component {
 state = {};
 render() {
  return (
   <div>
    <Nav />
    <main className="main-page d-flex bg-dark flex-column">
     <div className="cover">
      <Route path="/(slide3|slide3/video)/" exact component={S1} />
      <Route path="/slide3/step2" component={S2} />
      <Route path="/slide3/step2_5" component={S2_5} />
      <Route path="/slide3/step3" component={S3} />
      <Route path="/slide3/step4" component={S4} />
      <Route path="/slide3/article1" component={Article1} />
      <Route path="/slide3/article2" component={Article2} />
     </div>
    </main>
    {/* <Lessons /> */}
   </div>
  );
 }
}

export default Slide3Home;
