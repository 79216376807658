import React, { Component } from "react";
import logo from "../../img/logo.png";
import Card from "../components/card";
import Button from "../components/button";
import playIcon from "../../img/play_icon.png";
import indicator from "../../img/indicator.png";
import { Link } from "react-router-dom";
import back_icon from "../../img/icon_left.png";
import Modal from "../components/modal";
import VideoModal from "../components/videoModal";
import ButtonSm from "../components/buttonSm";

class S1 extends Component {
  state = { needs: {}, wants: {}, modalVisible: false };
  render() {
    return (
      <>
        <div className="d-flex flex-fill slide flex-column align-items-center justify-content-center step-1 step1-bg">
          <VideoModal
            modalVisible={this.state.modalVisible}
            link="x4VxQWqhjJI"
          />
          <div className="d-flex flex-column align-items-center text-white text-center">
            <img src={logo} alt="" className="main-logo" />
            <div className="mt-4 mb-3 position-relative">
              <div className="d-inline-block text-gold indicator-text">
                STEP 05
              </div>
              <img src={indicator} className="indicator" alt="" />
            </div>

            <div className="intro-text mt-1">
              <h3 className="text-center">
                <b className="title-text tf intro-title">Tafuta Expert</b>
              </h3>
              <div className="mt-3 intro-text px-0">
                <span>
                  <span className="skip-text">
                    Learn about how a unit trust enables you to invest your
                    savings with a high rate of return without the risks that
                    accompany most other investment options.
                  </span>
                </span>
              </div>
            </div>

            <div className="mt-4 play-next">
              <Link
                to="/slide5/video"
                className="btn d-flex flex-row align-items-center"
                onClick={() => this.setState({ modalVisible: true })}>
                <img src={playIcon} className="play-icon" alt="" />
                <small className="font-weight-bold ml-2 text-white">
                  Watch the lesson
                </small>
              </Link>
            </div>
            <div className="mt-5">
              <Button back={false} to="/slide5/step2/register" />
            </div>
          </div>
        </div>
        <ButtonSm back={false} to="/slide5/step2/register" />
      </>
    );
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }
}

export default S1;
