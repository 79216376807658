import React, { Component } from "react";
import logo from "../../img/logo.png";
import { Link } from "react-router-dom";

import bg_ring from "../../img/icons/Results/bg_ring.svg";

import carret from "../../img/icons/arrow.svg";
import ButtonSm from "../components/buttonSm";
import fund from "../../img/step5/fund.svg";
import check from "../../img/step5/check.svg";
import pointer from "../../img/step4/pointer.svg";
import Carret from "../../img/step4/carret_left.svg";
import Button from "../components/button";

class S6 extends Component {
  state = {
    ...window.slide4,
    savings: window.calc.savings,
    future: 0
  };
  render() {
    return (
      <>
        <div className="d-flex flex-fill slide flex-column align-items-center justify-content-center step-1 default-bg">
          <div className="d-flex flex-column align-items-center text-white text-center">
            <img
              src={logo}
              alt=""
              className="main-logo d-none d-md-block mb-md-3"
            />

            <div className="d-flex flex-row align-items-center tab-title-main">
              <div className="tab-sm d-flex flex-row justify-content-center align-items-center flex-row mr-3">
                <span className="font-weight-bold">03</span>
              </div>
              <div className="text-left">It is managed by experts</div>
            </div>

            <div className="w-250 mt-4">
              <div className="custom-divider d-flex flex-row justify-content-center mt-2 mb-2">
                <img src={carret} alt="" />
              </div>

              <div className="mt-4">
                Why its better to invest using a fund manager like Amana
                Capital.
              </div>
            </div>

            <div className="w-100 d-flex flex-column align-items-center mt-4 mb-3">
              <div className="px-0 desc-text b20 pt-md-3 round position-relative">
                <div className="d-flex flex-row justify-content-center align-items-center top-bar tmln">
                  <div className="text-right text-center brd brd-left py-2 text-gold">
                    Individual
                  </div>
                  <div className="font-weight-bold brd tmlxl py-2 info-block text-center text-uppercase">
                    VS
                  </div>
                  <div className="text-center text-gold brd brd-right py-2">
                    Amana
                  </div>
                  <img src={pointer} alt="" className="property-pointer" />
                </div>
              </div>
            </div>
            <div className="d-flex flex-row flex-wrap add-center-divider position-relative contain-500">
              <div className="w-50">
                <div className="d-flex flex-row align-items-center px-3 my-3 w-100 text-left position-relative">
                  <img src={Carret} className="mr-2" alt="" />
                  <div className="timeline-text">
                    <small className="">Little knowledge of trends</small>
                  </div>
                </div>
              </div>
              <div className="w-50">
                <div className="d-flex flex-row align-items-center justify-content-end px-3 my-3 w-100 text-left position-relative">
                  <div className="timeline-text text-right">
                    <small className="">Expert insight into trends</small>
                  </div>
                  <img src={Carret} className="ml-2 flip" alt="" />
                </div>
              </div>
              <div className="w-50">
                <div className="d-flex flex-row align-items-center px-3 my-3 w-100 text-left position-relative">
                  <img src={Carret} className="mr-2" alt="" />
                  <div className="timeline-text">
                    <small className="">
                      Not protected against fraud and bank collapse
                    </small>
                  </div>
                </div>
              </div>
              <div className="w-50">
                <div className="d-flex flex-row align-items-center px-3 my-3 w-100 text-left position-relative">
                  <div className="timeline-text text-right">
                    <small className="">
                      If Amana collapses your money is secured by CMA
                    </small>
                  </div>
                  <img src={Carret} className="ml-2 flip" alt="" />
                </div>
              </div>
              <div className="w-50">
                <div className="d-flex flex-row align-items-center px-3 my-3 w-100 text-left position-relative">
                  <img src={Carret} className="mr-2" alt="" />
                  <div className="timeline-text">
                    <small className="">
                      Offered lower interest rates as an individual
                    </small>
                  </div>
                </div>
              </div>
              {/* <div className="w-50">
                <div className="d-flex flex-row align-items-center px-3 my-3 w-100 text-left position-relative">
                  <div className="timeline-text text-right">
                    <small className="">Expert insight into trends</small>
                  </div>
                  <img src={Carret} className="ml-2 flip" alt="" />
                </div>
              </div> */}
            </div>

            <Button
              back={false}
              to="/slide5/step7"
              className="mt-md-5"
              text="continue"
            />
          </div>
        </div>
        <ButtonSm back={false} to="/slide5/step7" text="continue" />
      </>
    );
  }

  componentWillUnmount() {
    window.calc.savings = this.state.savings;
  }

  componentDidMount() {
    this.calculateFuture();
  }

  calculateFuture() {
    // y = 12;
    let p = this.state.savings;
    let r = 0.12;
    let i = 1 + r / 12;

    let G = (i * (Math.pow(i, 60) - 1)) / (i - 1);

    this.setState({ future: parseInt(G * p) });
  }
}

export default S6;
