import React, { Component } from "react";
import logo from "../../img/logo.png";
import Card from "../components/card";
import Button from "../components/button";
import ButtonSm from "../components/buttonSm";
import OwlCarousel from "react-owl-carousel";

// images
import entertainment from "../../img/icons/Entertainment.svg";
import misc from "../../img/icons/Misc.svg";
import personalCare from "../../img/icons/Personal_Care.svg";
import HoverCard from "../components/hoverCard";
import swipeIcon from "../../img/icons/swipe.svg";
import Counter from "../components/counter";
import Tabs from "../components/tabs";

class Step1_5 extends Component {
 state = { wants: {} };
 render() {
  // update database
  return (
   <>
    <div className="slide d-flex flex-fill flex-column align-items-center justify-content-center step-1 default-bg w-100">
     <HoverCard />

     <div className="d-flex flex-column align-items-center text-white text-center w-100">
      <img src={logo} alt="" className="main-logo d-xsm mb-md-3" />

      <div className="intro-text">
       <Tabs active="wants" />
       <h3 className="mt-3 d-inline-block mb-3 my-md-5">
        <b className="title-text tf">Estimate your wants below</b>
       </h3>
      </div>

      <div className="text-center mt-0 w-100 px-md-5 container card-scroll px-0">
       <div className="px-md-5 mx-md-5 d-flex flex-row justify-content-md-center">
        <Card
         icon={entertainment}
         title="ENTERTAINMENT"
         subtitle="Going out, Eating out , Travel"
         value={this.state.wants}
         getValue={value => {
          this.setState({ wants: { ...this.state.wants, ...value } });
         }}
         max={3}
         current={1}
        />
        <Card
         icon={misc}
         title="MISCELLANEOUS"
         subtitle="Gifts, upgrades and family treats"
         value={this.state.wants}
         getValue={value => {
          this.setState({ wants: { ...this.state.wants, ...value } });
         }}
         max={3}
         current={2}
        />
        <Card
         icon={personalCare}
         title="PERSONAL CARE"
         subtitle="Clothing, grooming, family"
         value={this.state.wants}
         getValue={value => {
          this.setState({ wants: { ...this.state.wants, ...value } });
          console.log(this.state.wants);
         }}
         max={3}
         current={3}
        />
       </div>
      </div>

      <div className="d-md-none d-inline-block my-3">
       <img src={swipeIcon} alt="" />
       <small className="d-block font-weight-light mt-1 swipe-text">
        Swipe to view more
       </small>
      </div>

      <div className="mt-5 d-n">
       <Button to="/slide1/step2" onClick={() => this.update()} />
      </div>
     </div>
    </div>
    <ButtonSm to="/slide1/step2" onClick={() => this.update()} />
   </>
  );
 }

 componentDidMount() {
  this.setState({ ...window.store });
  window.updatePersistence();
 }

 update = () => {
  window.store.wants = { ...window.store.wants, ...this.state.wants };

  localStorage.step1Options = JSON.stringify(this.state);
  let w = this.state.wants;

  let wants = 0;

  Object.keys(w).map(d => {
   wants += w[d];
  });

  window.calc = { ...window.calc, wants };
  if (!localStorage.calc) {
   localStorage.calc = "{}";
  }
  localStorage.calc = JSON.stringify({
   ...JSON.parse(localStorage.calc),
   ...window.calc
  });
 };
}

export default Step1_5;
