import React, { Component } from "react";
import { Link } from "react-router-dom";
import arrow_right from "../../img/arrow_right.png";
class ButtonSm extends Component {
 state = { disabled: this.props.disabled };
 render() {
  let mut = this.state.disabled ? "mute" : "";

  return (
   <>
    <div className="navigation-buttons d-md-none d-flex flex-row">
     {this.props.back !== false && (
      <div className="">
       <button
        className={"btn btn-sm-back"}
        onClick={() => {
         // this.props.navigation.goback();
         window.history.back();
        }}
       >
        <small className={"text-white text-uppercase font-weight-bold"}>
         {this.props.backText ? this.props.backText : "BACK"}
        </small>
       </button>
      </div>
     )}
     <div className={"d-flex flex-fill " + mut}>
      <Link
       to={this.props.to}
       className={
        "btn btn-sm-next " + (this.props.back === false ? "w-100" : "")
       }
       onClick={() => {
        if (this.props.onClick) this.props.onClick();
        window.scrollBack();
       }}
      >
       <small className={"text-white text-uppercase"}>
        <b>{this.props.text ? this.props.text : "CONTINUE"}</b>
       </small>
      </Link>
     </div>
    </div>
   </>
  );
 }

 componentWillReceiveProps(props) {
  if (props.disabled !== "undefined")
   this.setState({ disabled: props.disabled });
  //   console.log(console.log(props.disabled));
 }
}

export default ButtonSm;
