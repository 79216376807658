import React, { Component } from "react";
// images
import article2 from "../../../img/articles/slide3_article2_small.jpg";
import article1 from "../../../img/articles/slide3_article1.jpg";
import { Link } from "react-router-dom";
import Nav from "../../components/nav";

import twitter_icon from "../../../img/twitter_icon.png";
import facebook_icon from "../../../img/facebook_icon.png";
import Footer from "../../components/Footer";
import logo from "../../../img/logo.png";

class Article1 extends Component {
  state = {};
  render() {
    window.scrollTo(0, 0);
    return (
      <>
        <Nav />
        <div className="article-parent">
          <div className="article-header position-relative">
            <img
              src={article1}
              alt=""
              className="position-absolute h-100 w-100 article-header-image"
            />
            <div className="container d-flex flex-column flex-fill justify-content-between h-100 text-white">
              <div className="w-100 text-center mt-5">
                <img src={logo} alt="" className="main-logo" />
              </div>

              <div>
                <h1 className="font-weight-bold">“Why a Unit Trust?”</h1>

                <div className="text-white align-items-center mb-5">
                  <div className="font-weight-normal d-flex align-items-center flex-row">
                    <small className="font-weight-normal">23 March 2019</small>
                    <div className="d-inline-block mx-2">|</div>
                    <small className="font-weight-normal">Share</small>
                    <div className="d-flex flex-row ml-2">
                      <a
                        href="https://twitter.com/AmanaCapitalKE"
                        className="mr-3">
                        <img
                          src={twitter_icon}
                          alt=""
                          className="social-icon"
                        />
                      </a>

                      <a
                        href="https://www.facebook.com/amanacapital2003/"
                        className="mr-3">
                        <img
                          src={facebook_icon}
                          alt=""
                          className="social-icon"
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* content */}

          <div className="container pb-5">
            <div className="text-muted mt-3 mb-5">
              <small>Home / Blog / “Why a Unit Trust?”</small>
            </div>

            <div className="row">
              <div className="col-md-9">
                <div className="text-dark article-text">
                  <p>
                    As we saw in Step 4 of the 7 Steps to Wealth, there are
                    plenty of options for you to choose from when it comes to
                    finding a home for your savings. These range from
                    investments in land to the stock market to savings accounts
                    and more. However, in Step 5, “Tafuta Experts", you will
                    learn about the savings option, Unit Trusts, that generally
                    outperforms all the others on all the measures that count.
                  </p>{" "}
                  <p>
                    A Unit Trust is based upon the pooling of monies from
                    multiple savers and then investing that money in a range of
                    financial assets. Typically these assets include fixed
                    deposit accounts, government bills and bonds, corporate
                    bonds, shares and forex.
                  </p>
                  <p>
                    The key strength of Unit Trusts lies in the spread of
                    investment across these various options.
                  </p>
                  <p>
                    Each of these options, as we saw in Step 4, carries within
                    it an inherent balance between risk and reward. Thus, for
                    example, a savings account is a very low risk home for your
                    money, which is a good thing of course, but it also offers
                    very low rewards or returns, especially after taking into
                    account the impact of inflation upon the value of your
                    money.
                  </p>
                  <p>
                    Conversely, putting your savings into the stock market can
                    potentially earn you a very very good rate of return, but by
                    the same token, you can also loose everything if the company
                    that you have invested in takes a wrong turn, or if the
                    market as a whole has a bad run.
                  </p>
                  <p>
                    A Unit Trust addresses this key savings question – how do I
                    best balance risk with reward – by spreading your risk
                    across the range of available options. Thus, for example, a
                    certain portion of the pooled money will go into low risk
                    options like fixed deposit accounts and treasury bills,
                    whilst a certain portion will go into potentially high
                    return assets like shares and, sometimes, forex.
                  </p>
                  <p>
                    The general principle of a Unit Trust, diversification of
                    risk and exposure to multiple potential rewards, is what
                    makes this vehicle such an ideal option for most savers. It
                    gives you the safety that you would look for from a bank
                    account whilst also giving you the potential upside that
                    comes with riskier assets.
                  </p>
                  <p>
                    Furthermore, Unit Trusts are not one size fits all. There
                    are generally a range of Unit Trust options available to you
                    based upon your appetite for risk and your savings horizons.
                  </p>
                  <p>
                    Amana Capital's Money Market Unit Trust, for example, is
                    designed to focus on minimizing risk, and in fact is
                    structured in such a way as to guarantee that you cannot
                    loose your principle. Whilst it may not give you the returns
                    available from other Unit Trust types, what it will do is
                    get you a better rate of return than a standard savings
                    account whilst also giving yoy the safety expect3d of a
                    savings account. Thus this form of Unit Trust is ideal for
                    individuals and businesses looking for a safe home for their
                    short term surplus cash.
                  </p>
                  <p>
                    The Amana Capital Balanced Fund, by way of a second example,
                    seeks to give you the 9deal balance between watching out for
                    risks whilst also seeking out superior returns. Unlike the
                    Money Market, in which allmost all the money is put into low
                    risk options, the balanced fund puts some of your money into
                    carefully selected stocks. Not only does this mean that you
                    enjoy a dividend from those stocks, it also allows you to
                    benefit from the underlying increase in value of the stocks.
                    This type of Unit Trust is ideal for mid term savings, money
                    that you want to put aside for a few years as opposed to a
                    few months.
                  </p>
                  <p>
                    The final kind of Unit Trust, such as Amana Capital's Growth
                    Unit Trust, is focussed to the long term rapid growth of
                    your savings. Typically such funds are heavily focussed on
                    the stock exchange. These are not designed for ahort or mid
                    term savings, and their value can go up and down. However,
                    through the expertise and market knowledge of your Unit
                    Trust fund manager, the general tendancy over time should be
                    for your savings to grow and grow and grow, and to grow more
                    so at a very very healthy rate of return.
                  </p>
                  <p>
                    It is the professional management of Unit Trusts that truly
                    set them apart and abovr as a savings options. Unit Trust
                    fund managers are compensated on the basis of the
                    performance of the funds that they are managing. For
                    example, Amana Capital charges 2% per annum of the total
                    value of the particular Unit Trust in question. Your fund
                    manager is incentivized to grow your savings at as healthy a
                    rate as possible, whilst also sharing with you the need to
                    mitigate against risks.
                  </p>
                  <p>
                    Knowledge of the stock market, studying the various
                    companies, understanding economic trends, wrestling with the
                    intricacies of foriegn exchange trading – all these are
                    specialized skills which most of us as savers do not have,
                    and have neither the time nor the inclination to develop.
                  </p>
                  <p>
                    Thus when it comes to your savings, you can do far far
                    worse, and probably not any better, than going to look and
                    talk to the experts.
                  </p>
                  <p>
                    To find out more about what a Unit Trust can do for you, and
                    why you should make Amana Capital your Unit Trust fund
                    manager of choice, go to{" "}
                    <a href="http://www.amanacapital.co.ke" target="_blank">
                      www.amanacapital.co.ke
                    </a>{" "}
                    today.
                  </p>
                </div>
              </div>
              <div className="col-md-3 d-none">
                <b>Related Articles</b>

                <Link to="/slide4/article2">
                  <div className="bg-light my-5 position-relative">
                    <img src={article2} alt="" className="w-100" />
                    <div className="red-text p-3">
                      <small>
                        The 7 Rules of choosing your savings options
                      </small>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

export default Article1;
