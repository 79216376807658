import React, { Component } from "react";
import ReactDOM from "react-dom";
import { Route, Link, BrowserRouter as Router } from "react-router-dom";
import Step1 from "./step1";
import Step2 from "./step2";
import Step3 from "./step3";
import Step4 from "./step4";
import Step5 from "./step5";
import Step0 from "./slide0";
// images
// import Nav from "../components/nav";
import Step1_5 from "./step1_5";
import Step0_5 from "./slide0_5";
import Lessons from "../components/Lessons";
import SlideFooter from "../components/slideFooter";
import Step6 from "./mobile/step6";
import Step7 from "./mobile/step7";
import Step8 from "./mobile/step8";
import Step9 from "./mobile/step9";
import Step10 from "./mobile/step10";
import Nav from "../components/nav";
import Step0_55 from "./step0_55";
import Step4_5 from "./step4_5";
import Step4_5m from "./step4_5m";
import Step11 from "./mobile/step11";
import Step12 from "./step12";
import ThankYouPage from "./thankyou";

class Home extends Component {
 state = {};
 render() {
  window.h = this.props.history;

  return (
   <div>
    <main className="main-page bg-dark">
     <Nav />

     <div className="cover">
      <Route path="/(slide1|slide1/video)" exact component={Step0} />
      <Route path="/slide1/step0_55" component={Step0_55} />
      <Route path="/slide1/step0_5" component={Step0_5} />
      <Route path="/slide1/step1" exact component={Step1} />
      <Route path="/slide1/step1_5" exact component={Step1_5} />
      <Route path="/slide1/step2" exact component={Step2} />
      <Route path="/slide1/step3" component={Step3} />
      <Route path="/slide1/step4" component={Step4} />
      <Route path="/slide1/step4_5" component={Step4_5} />
      <Route path="/slide1/step4_5m" component={Step4_5m} />
      <Route path="/slide1/step5" component={Step5} />
      <Route path="/slide1/step6" component={Step6} />
      <Route path="/slide1/step7" component={Step7} />
      <Route path="/slide1/step8" component={Step8} />
      <Route path="/slide1/step9" component={Step9} />
      <Route path="/slide1/step10" component={Step10} />
      <Route path="/slide1/step11" component={Step11} />
      <Route path="/slide1/step12" component={Step12} />
      <Route path="/slide1/thankyou" component={ThankYouPage} />
     </div>
     <SlideFooter />
    </main>
    {/* <Lessons /> */}
   </div>
  );
 }

 goBack = () => {
  this.props.history.goBack();
 };
}

export default Home;
