import React, { Component } from "react";
import Button from "./button";
import { Link } from "react-router-dom";

import p1 from "../../img/articles/slide3_article1_small.jpg";
import p2 from "../../img/articles/slide3_article2_small.jpg";
import OwlCarousel from "react-owl-carousel";
import Footer from "./Footer";

class Lessons4 extends Component {
  state = {};
  render() {
    return (
      <>
        <section
          className="section pt-5 bg-white learn-more"
          id="lessons-section">
          <div className="container text-center py-3">
            <div className="">
              <h2 className="font-weight-light">Lessons to learn</h2>

              <div className="my-5 mx-md-5 px-md-5">
                <OwlCarousel
                  className="owl-theme"
                  margin={0}
                  responsive={{
                    0: {
                      items: 1,
                      nav: false
                    },
                    600: {
                      items: 2,
                      nav: false
                    }
                  }}>
                  <Link
                    to="/slide4/article1"
                    className="_col-md-4 text-center item text-center">
                    <div className="sec-card shadow-sm mx-md-4">
                      <img src={p1} alt="" className="w-100 section-img" />
                      <div className="p-3 my-3">
                        <div>
                          <b>Weighing your savings options</b>
                        </div>
                        <div className="my-2">
                          <div>
                            <small>
                              Learn about the key steps you need to take to live
                              the life you desire in our free course powered by
                              Amana Capital.
                            </small>
                          </div>

                          <button className="btn btn-secondary btn-sm px-3 mt-4">
                            <small>Read more</small>
                          </button>
                        </div>
                      </div>
                    </div>
                  </Link>

                  <Link
                    to="/slide4/article2"
                    className="_col-md-4 text-left item text-center">
                    <div className="sec-card shadow-sm mx-md-4">
                      <img src={p2} alt="" className="w-100 section-img" />
                      <div className="p-3 my-3">
                        <div>
                          <b>The 7 Rules of choosing your savings options</b>
                        </div>
                        <div className="my-2">
                          <div>
                            <small>
                              Creating and sticking to a budget will become an
                              easy task once you follow these helpful tips
                              prepared by our wealth experts.
                            </small>
                          </div>

                          <button className="btn btn-secondary btn-sm px-3 mt-4">
                            <small>Read more</small>
                          </button>
                        </div>
                      </div>
                    </div>
                  </Link>
                </OwlCarousel>
              </div>
            </div>
          </div>
        </section>

        <Footer />
      </>
    );
  }
}

export default Lessons4;
