import React, { Component } from "react";
import logo from "../../img/logo.png";
import Button from "../components/button";
import { Link } from "react-router-dom";
import ButtonSm from "../components/buttonSm";
import HoverCard from "../components/hoverCard";
import Counter from "../components/counter";

class Step3 extends Component {
 calculate = () => {
  let { calc } = window;
  let total = calc.earnings - (calc.needs + calc.wants);
  return total;
 };

 state = { balance: this.calculate() };
 render() {
  return (
   <>
    <div className="slide d-flex flex-fill flex-column align-items-center justify-content-center step-1 default-bg">
     <HoverCard />
     <Counter current={5} total={6} />

     <div className="d-flex flex-column align-items-center text-white text-center">
      <img src={logo} alt="" className="main-logo" />
      <Counter current={4} total={6} />
      <div className="total-section mx-3">
       <h2 className="mt-3 mt-md-5">
        <b className="title-text tf">Your balance is:</b>
       </h2>
       <div className="my-md-4 my-2">
        <h1 className="text-center text-gold">
         {this.state.balance.toLocaleString()}
        </h1>
       </div>
       <div className="mt-3">
        <span className="desc-text font-weight-bold d-inline-block">
         {this.state.balance >= 0 && (
          <>
           <p>
            This is what you need to save for your future and handle any
            emergencies in a month.
           </p>
           <p>If it isn’t realistic you can go back and adjust.</p>
          </>
         )}
         {this.state.balance < 0 && (
          <span>Seems like your expenditure exeeds your income.</span>
         )}
        </span>
       </div>
      </div>
      <div className="mt-5 d-n">
       {this.state.balance < 0 ? (
        <Link to="/slide1/step1" className="btn bg-white btn-sm p-2 px-5 ">
         <div className="text-gold">
          <small className="font-weight-bold">GO BACK</small>
         </div>
        </Link>
       ) : (
        <Button to="/slide1/step4" />
       )}
      </div>
     </div>
    </div>

    {this.state.balance < 0 ? (
     <div class="navigation-buttons d-md-none d-flex flex-row">
      <div class="w-100">
       <Link to="/slide1/step1" class="btn btn-sm-back w-100">
        <small class="text-white text-uppercase font-weight-bold">BACK</small>
       </Link>
      </div>
     </div>
    ) : (
     <ButtonSm backonly={this.state.balance < 0} to="/slide1/step4_5m" />
    )}
   </>
  );
 }

 componentDidMount() {
  window.updatePersistence();
 }
}

export default Step3;
