import React, { Component } from "react";
import logo from "../../img/logo.png";
import Card from "../components/card";
import Button from "../components/button";
import playIcon from "../../img/play_icon.png";
import indicator from "../../img/indicator.png";
import { Link } from "react-router-dom";
import back_icon from "../../img/icon_left.png";
import Modal from "../components/modal";
import VideoModal from "../components/videoModal";
import ButtonSm from "../components/buttonSm";
import play_icon from "../../img/play_icon.png";

class Step0 extends Component {
 state = { needs: {}, wants: {}, modalVisible: false };
 render() {
  return (
   <>
    <div
     className="d-flex flex-fill slide flex-column align-items-center justify-content-center step-1 step1-bg"
     style={{}}
    >
     <VideoModal modalVisible={this.state.modalVisible} link="r-UoiWpvvOs" />
     <div className="d-flex flex-column align-items-center text-white text-center">
      <img src={logo} alt="" className="main-logo" />
      <div className="mt-4 mb-3 position-relative">
       <div className="d-inline-block text-gold indicator-text">STEP 01</div>
       <img src={indicator} className="indicator" alt="" />
      </div>

      <div className="intro-text mt-1">
       <h2 className="text-center">
        <b className="title-text tf intro-title">Fanya Budget</b>
       </h2>
       <div className="mt-3 intro-text px-0">
        <span>
         <span className="skip-text">
          In these tough times it can be hard to think of finding money to save.
          Join Willis and Mary as they learn how to create the right balance
          between running their life today and having enough to build the future
          they desire.
         </span>
        </span>
       </div>
      </div>

      <div className="mt-4 play-next">
       <Link
        to="/slide1/video"
        className="btn d-flex flex-row align-items-center"
        onClick={() => this.setState({ modalVisible: true })}
       >
        <img src={play_icon} className="play-icon" alt="" />
        <small className="font-weight-bold ml-2 text-white">PLAY STEP 1</small>
       </Link>
      </div>
      <div className="mt-5">
       <Button back={false} to="/slide1/step0_55/register" />
      </div>
     </div>
    </div>
    <ButtonSm back={false} to="/slide1/step0_55/register" />
   </>
  );
 }

 componentDidMount() {
  window.scrollTo(0, 0);
 }

 update = () => {
  let n = this.state.needs;
  let w = this.state.wants;
  let needs = 0;
  let wants = 0;
  Object.keys(n).map(d => {
   needs += n[d];
  });
  Object.keys(w).map(d => {
   wants += w[d];
  });

  window.calc = { ...window.calc, needs, wants };
  console.log(window.calc);
 };
}

export default Step0;
