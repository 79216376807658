import React, { Component } from "react";
import logo from "../../img/logo.png";
import Modal from "../components/modal";
import carret_down from "../../img/icons/carret_down.svg";

import { Link } from "react-router-dom";

import hex1 from "../../img/hex1.png";
import hex2 from "../../img/hex2.png";
import Lessons3 from "../components/Lessons3";

class S4 extends Component {
  state = { monthlyTotal: window.slide3.monthlyTotal };
  render() {
    return (
      <>
        <div className="slide d-flex flex-fill flex-column text-white align-items-center justify-content-center step-1 default-bg pt-3">
          <Modal show={this.state.modalVisible}>
            <div className="hover-card-center position-relative text-white">
              <div
                className={
                  "layer2 px-4 py-5 " +
                  (this.state.disabled ? "disable-card" : "")
                }>
                <i
                  class="fas fa-times text-white position-absolute hover-card-clear"
                  onClick={() => this.setState({ modalVisible: false })}
                />
                <small className="d-block">
                  <b className="d-block text-white">
                    BOOK A FREE CONSULTATION WITH A WEALTH EXPERT
                  </b>
                  No sales pitch , no strings attached, just good and honest
                  advice.
                </small>

                <input
                  type="text"
                  className="py-1 my-3 text-center text-bronze card-input d-flex flex-fill w-100 "
                  placeholder="Enter your phone number"
                  value={this.state.phoneNumber}
                  onChange={evt =>
                    this.setState({ phoneNumber: evt.target.value })
                  }
                />

                <button
                  className="btn w-100 text-center w-100 btn-primary"
                  onClick={() => {
                    this.send();
                  }}>
                  <small className="font-weight-bold text-white">
                    LET'S TALK
                  </small>
                </button>
              </div>
            </div>
          </Modal>

          <div className="p-3 text-center">
            <img src={logo} className="main-logo" alt="" />

            <div className="mt-4 mt-md-5">
              <h5 className="lts font-weight-bold">
                Congratulations! You’ve finished Step 3.
              </h5>
              <span className="text-white px-3 d-inline-block desc-text">
                Learn how to analyse your options in Step 4 releasing on 22nd
                July
              </span>
            </div>

            <div className="py-3 total-anim text-center text-gold mt-3 mb-0 my-md-5 d-inline-block">
              <h3>{this.state.monthlyTotal.toLocaleString()}</h3>
              <span className="text-white mx-3 d-inline-block">
                In order to reach your goal this is what you’ll need to save in
                a month for <b> {window.slide3.years} Years</b>
              </span>
            </div>

            <div className="d-flex flex-row justify-content-center mt-4">
              <div
                className="mr-2 cursor-pointer d-flex text-center align-items-center justify-content-center"
                onClick={() => this.setState({ modalVisible: true })}>
                <div className="position-absolute d-flex text-white">
                  Talk to an
                  <br /> Advisor
                </div>
                <img src={hex1} alt="" />
              </div>

              <Link
                to="/slide4"
                className="ml-2 cursor-pointer d-flex text-center align-items-center justify-content-center">
                <div className="position-absolute d-flex text-white">
                  Step 4 <br /> Cheki <br /> Options
                </div>
                <img src={hex2} alt="" />
              </Link>
            </div>

            <div className="flex-column justify-content-center d-flex mt-5">
              <small className="text-primary mb-2 font-weight-bold text-sm">
                LEARN MORE
              </small>
              <img src={carret_down} alt="" />
            </div>
          </div>
        </div>
        <Lessons3 />
      </>
    );
  }

  componentWillMount() {
    setTimeout(() => {
      this.setState({ monthlyTotal: window.slide3.monthlyTotal });
    }, 0);
  }

  send = () => {
    if (this.state.phoneNumber === "") {
      alert("please enter a Phone number to proceed");
      return false;
    }
    this.setState({ disabled: true });

    fetch(window.server + "saveRequest.php", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/x-www-form-urlencoded"
      },
      body: `user=${localStorage.id}&phone=${this.state.phoneNumber}`
    })
      .then(response => response.text())
      .then(response => {
        this.setState({ disabled: false, modalVisible: false });
        console.log(response);
        // this.props.history.push("/slide1/thankyou");
        window.location = "/page/thankyou.html";
      });
  };
}

export default S4;
