import React, { Component } from "react";
// images
import article1 from "../../../img/articles/slide3_article2.jpg";
import article2 from "../../../img/articles/slide3_article1_small.jpg";
import { Link } from "react-router-dom";
import Nav from "../../components/nav";

import twitter_icon from "../../../img/twitter_icon.png";
import facebook_icon from "../../../img/facebook_icon.png";
import Footer from "../../components/Footer";
import logo from "../../../img/logo.png";

class Article2 extends Component {
  state = {};
  render() {
    window.scrollTo(0, 0);
    return (
      <>
        <Nav />
        <div className="article-parent">
          <div className="article-header position-relative">
            <img
              src={article1}
              alt=""
              className="position-absolute h-100 w-100 article-header-image"
            />
            <div className="container d-flex flex-column flex-fill justify-content-between h-100 text-white">
              <div className="w-100 text-center mt-5">
                <img src={logo} alt="" className="main-logo" />
              </div>

              <div>
                <h1 className="font-weight-bold">
                  Breaking down your financial goals
                </h1>

                <div className="text-white align-items-center mb-5">
                  <div className="font-weight-normal d-flex align-items-center flex-row">
                    <small className="font-weight-normal">23 March 2019</small>
                    <div className="d-inline-block mx-2">|</div>
                    <small className="font-weight-normal">Share</small>
                    <div className="d-flex flex-row ml-2">
                      <a
                        href="https://twitter.com/AmanaCapitalKE"
                        className="mr-3">
                        <img
                          src={twitter_icon}
                          alt=""
                          className="social-icon"
                        />
                      </a>

                      <a
                        href="https://www.facebook.com/amanacapital2003/"
                        className="mr-3">
                        <img
                          src={facebook_icon}
                          alt=""
                          className="social-icon"
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="container pb-5">
            <div className="text-muted mt-3 mb-5">
              <small>Home / Blog / Breaking down your financial goals</small>
            </div>

            <div className="row">
              <div className="col-md-9">
                <div className="text-dark article-text">
                  <p>
                    Setting your financial goals may sound simple, but in
                    practice it helps to assign different savings amounts to
                    specific life goals. In this article we shall look at the 7
                    key goals that you need to consider when planning your
                    financial future.
                  </p>
                  <b className="mb-2 d-block">1. Housing</b>

                  <p>
                    Owning your own home should be a primary goal for all.
                    Whilst housing in Kenya is far from affordable, whether you
                    are buying a finished home or building your own, home
                    ownership is one of the fundamental pillars of long term
                    financial security. Be realistic about what sort of home
                    your present financial situation allows you to plan for.
                    There is no point planning for an ambassadorial mansion when
                    your circumstances suggest an apartment. Be conscious also
                    of your family plans, how many children do you have or want
                    to have, and whether or not you are planning for a home in
                    which you intend to spend your old age. Do your research
                    into upcoming residential areas, which are generally more
                    affordable, look into the various options and prices, and
                    plan accordingly.
                  </p>

                  <b className="mb-2 d-block">2. Education</b>

                  <p>
                    As every Kenyan parent knows, educating our children is one
                    of, if not the most, important and expensive financial
                    challenges faced. If you do not have children yet, but plan
                    to, remember that the earlier that you start planning and
                    saving for their education, the better an education you will
                    be able to provide them with when the time comes. The cost
                    of education of course is also not just school fees,
                    everything else from uniforms to books to sports equipment
                    to after-school activities also has to be catered for. No
                    matter what stage you are on in the journey of educating
                    your children, saving today for the expenses coming tomorrow
                    is a must.
                  </p>
                  <b className="mb-2 d-block">3. Retirement</b>

                  <p>
                    Old age comes to us all eventually, and it is crucial that
                    you begin making financial preparations for it as soon as
                    you can – the earlier you do, the sooner you will be in a
                    position to guarantee yourself a sufficient income for the
                    rest of your life. Do not put yourself in a position where
                    you are reliant on your children, or others, to look after
                    you in your old age. Develop a specific retirement savings
                    plan and add to it each month. If you do this consistently
                    you will be able to look forward to a prosperous and happy
                    time in your golden years.
                  </p>

                  <b className="mb-2 d-block">4. Medical</b>

                  <p>
                    Medical emergencies and the need to plan for future medical
                    expenses are a fact of life. A good medical insurance cover
                    is obviously crucial, but unless you can afford the most
                    gold plated of covers, it is still very important to factor
                    in an allocation towards emergency medical expenses into
                    your monthly savings goals. This way, should the worst
                    happen, you will at least not have to worry about the cost
                    of addressing it.
                  </p>

                  <b className="mb-2 d-block">5. Inheritance</b>

                  <p>
                    Any parent should be planning for their children’s wellbeing
                    even when we are no longer around. If you can, put something
                    aside in your monthly savings plan which is allocated to be
                    left for your children when the time comes. One of the
                    benefits of planning ahead for this is that it will allow
                    you to tackle any tax implications in good time. It is also
                    important to recognize the reality of funeral expenses,
                    which are not insubstantial, and to plan accordingly so that
                    this burden is not placed upon your children.
                  </p>

                  <b className="mb-2 d-block">6. Holidays</b>

                  <p>
                    Life is not all sweat and toil. You and your family are
                    fully entitled to spend enjoyable time together, travel the
                    country and the world, and spend some of your hard earned
                    money on the finer things in life. Set aside a part of your
                    monthly savings specifically to holidays. Whether it is a
                    weekend in Mombasa or a week at Disney World, some things
                    are worth more than all the money in the world.
                  </p>

                  <b className="mb-2 d-block">7. Unemployment</b>

                  <p>
                    In uncertain times, one must take into account that ones
                    present source of income is not necessarily for life.
                    Loosing a job is a reality that many of us have to face at
                    one point in our lives, companies close or downsize, and our
                    own businesses could go under for many reasons beyond our
                    own control. Make sure that you are saving enough each month
                    to build up an emergency fund covering at least 6 months of
                    your financial requirements so that you give yourself the
                    time and flexibility required to find your next source of
                    income should your present one cease to deliver.
                  </p>
                </div>
              </div>
              <div className="col-md-3">
                <b>Related Articles</b>

                <Link to="/slide3/article1">
                  <div className="bg-light my-5 position-relative">
                    <img src={article2} alt="" className="w-100" />
                    <div className="red-text p-3">
                      <small>What kind of spender are you?</small>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

export default Article2;
