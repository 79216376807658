import React, { Component } from "react";
import Button from "../components/button";
import logo from "../../img/logo.png";
import ButtonSm from "../components/buttonSm";
import HoverCard from "../components/hoverCard";
import swipeIcon from "../../img/icons/swipe.svg";
import { Link } from "react-router-dom";

class Step4 extends Component {
 state = {};
 render() {
  window.scroll4();
  // update database
  return (
   <>
    <div className="slide d-flex flex-fill flex-column align-items-center justify-content-center step-1 default-bg pt-3">
     <HoverCard />

     <img src={logo} className="main-logo calto-logo d-xsm" alt="" />
     <div className="py-3 total-anim text-center text-gold mb-3 mt-4">
      <h3> {this.calculateFuture(60).toLocaleString()} </h3>
      <span className="text-white">
       This is what it will earn you in 5 years
      </span>
     </div>

     <div className="text-white text-center container bars-sm-parent mt-0 item-flat-parent">
      <div className="d-flex flex-row item-flat">
       <div className="col-md-6 p-0 text-right d-flex flex-column align-items-end">
        <div className="px-3 pt-3 pb-0 d-flex flex-column align-items-end mr-md-3 bars-column">
         <h4 className="side-title mr-0 tf">
          <b>A balanced ratio</b>
         </h4>
         {/* <div className="side-subtitle my-2 text-md d-none">
          This is what a Kenyan family should spend to build their dreams and
          have something for a rainy day
         </div> */}
        </div>

        <div className="bar-parent position-relative w-100">
         <div className="bar my-3 mt-2 w-100 d-flex flex-row align-items-center justify-content-end">
          <span>
           <small className="mr-3">
            <b>NEEDS</b>
           </small>
          </span>
          <div
           className="bar p-1 text-left bg-white pl-3"
           style={{ width: "50%" }}
          >
           <h6 className="text-gold m-0 my-1">50%</h6>
          </div>
         </div>
         <div className="bar my-3 w-100 d-flex flex-row align-items-center justify-content-end">
          <span>
           <small className="mr-3">
            <b>WANTS</b>
           </small>
          </span>
          <div
           className="bar p-1 text-left bg-white pl-3"
           style={{ width: "30%" }}
          >
           <h6 className="text-gold m-0 my-1">30%</h6>
          </div>
         </div>
         <div
          className="bar mt-3 w-100 d-flex flex-row align-items-center justify-content-end"
          style={{ width: "20%" }}
         >
          <span>
           <small className="mr-3">
            <b>SAVINGS</b>
           </small>
          </span>
          <div className="bar w-25 p-1 text-left bg-white pl-3">
           <h6 className="text-gold m-0 my-1">20%</h6>
          </div>
         </div>

         <div className="bar-indicator bg-white" />
        </div>
       </div>

       <div className="col-md-6 p-0 text-left">
        <div className="px-3 pt-3 pb-0 ml-md-3 d-flex flex-column align-items-start text-gold bars-column">
         <h4 className="side-title mr-0 tf">
          <b>Your spending ratio</b>
         </h4>
         {/* <div className="side-subtitle-right my-2 text-md">
          This is what you are spending. Next we will help you adjust this and
          create a personalised plan
         </div> */}
        </div>

        <div className="bar-parent">
         <div className="bar my-3 mt-2 w-100 d-flex flex-row align-items-center justify-content-start">
          <div
           className="bar p-1 text-right bg-silver px-3"
           style={{ width: this.state.needs + "%" }}
          >
           <h6 className="text-white m-0 my-1">{this.state.needs}%</h6>
          </div>
          <span className="d-flex flex-row align-items-center">
           <small className="ml-3">
            <b>NEEDS&nbsp;</b>{" "}
           </small>
           <span className="text-gold percentage">
            {this.calcDisplacement(this.state.needs, 50)}%
           </span>
          </span>
         </div>
         <div className="bar my-3 w-100 d-flex flex-row align-items-center justify-content-start">
          <div
           className="bar p-1 text-right bg-silver px-3"
           style={{ width: this.state.wants + "%" }}
          >
           <h6 className="text-white m-0 my-1">{this.state.wants}%</h6>
          </div>
          <span className="d-flex flex-row align-items-center">
           <small className="ml-3">
            <b>WANTS&nbsp;</b>{" "}
           </small>
           <span className="text-gold percentage">
            {this.calcDisplacement(this.state.wants, 30)}%
           </span>
          </span>
         </div>
         <div className="bar mt-3 w-100 d-flex flex-row align-items-center justify-content-start">
          <div
           className="bar p-1 text-right bg-silver px-3"
           style={{ width: this.state.balance + "%" }}
          >
           <h6 className="text-white m-0 my-1">{this.state.balance}%</h6>
          </div>
          <span className="d-flex flex-row align-items-center">
           <small className="ml-3">
            <b>SAVINGS&nbsp;</b>{" "}
           </small>
           <span className="text-gold percentage">
            {this.calcDisplacement(this.state.balance, 20)}%
           </span>
          </span>
         </div>
        </div>
       </div>
      </div>
     </div>
     <div className="d-md-none d-inline-block mt-4 text-white text-center">
      <img src={swipeIcon} alt="" />
      <small className="d-block font-weight-light mt-1 swipe-text">
       Swipe to view more
      </small>
     </div>
     <div className="mt-5 pt-3 d-n" />
     <div class="d-md-flex d-none flex-row flex-wrap align-items-center">
      <div class="">
       <Link
        to="/slide1/step5"
        class="btn bg-white py-2 px-3  mx-2 d-inline-block d-flex flex-row align-items-center justify-content-between  btn-next"
       >
        <img src="" alt="" class="mr-2" />
        <small class="text-gold text-sm text-uppercase">
         <b>Earn more</b>
        </small>
        <div />
       </Link>
      </div>
      <div class="">
       <Link
        class="btn bg-gold py-2 px-3  mx-2 d-inline-block d-flex flex-row align-items-center justify-content-between  btn-next"
        to="/slide1/step11"
       >
        <div />
        <small class="text-white text-sm ">
         <b>FINISH STEP 1</b>
        </small>
        <img src="" alt="" class="ml-2" />
       </Link>
      </div>
     </div>
    </div>
    <ButtonSm text="create budget" to="/slide1/step6" auto={true} />
   </>
  );
 }

 componentDidMount() {
  this.setState({
   needs: this.calculate(window.calc.needs),
   wants: this.calculate(window.calc.wants),
   balance: this.calculate(this.getBalance())
  });

  window.updatePersistence();
 }
 calculate(i) {
  console.log(i);
  return parseInt((i / window.calc.earnings) * 100);
 }

 getBalance = () => {
  let { calc } = window;
  let total = calc.earnings - (calc.needs + calc.wants);
  return total;
 };

 calcDisplacement(a, b) {
  let val = a - b;
  if (val >= 0) {
   val = "+ " + val;
  } else {
   val = "- " + val * -1;
  }
  return val;
 }

 getBalance = () => {
  let { calc } = window;
  let total = calc.earnings - (calc.needs + calc.wants);
  return total;
 };

 calculateFuture(y) {
  let p = this.getBalance();
  let r = 0.12;
  let i = 1 + r / 12;

  let G = (i * (Math.pow(i, y) - 1)) / (i - 1);

  return parseInt(G * p);
 }
}

export default Step4;
