import React, { Component } from "react";

class ModalCard extends Component {
 state = {};
 render() {
  return (
   <div className="p-3 position-relative">
    <div className="card border-0 shadow modal-card p-4 text-secondary w-100 mt-5">
     <h4 className="text-gold">{this.props.title}</h4>
     <p>{this.props.description}</p>
     {this.props.children}
    </div>
   </div>
  );
 }
}

export default ModalCard;
