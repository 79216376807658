import React, { Component } from "react";
import Modal from "./modal";

import { Route, BrowserRouter as Router } from "react-router-dom";

class VideoModal extends Component {
 state = {
  modalVisible: false
 };
 render() {
  let path = (
   window.location.pathname.replace("/video", "") + "/video"
  ).replace("//", "/");
  console.log(path);
  return (
   <Modal show={this.state.modalVisible}>
    <div className="d-none">
     <Route
      path={path}
      render={() => (
       <Item
        show={t => {
         this.setState({ modalVisible: t });
        }}
       />
      )}
     />
    </div>
    <div className="tab-buttons mt-5 d-flex flex-column align-items-end w-100">
     <button
      className="btn btn-outline-light mx-3 mx-md-5 "
      onClick={() => {
       //    this.setState({ modalVisible: false });
       setTimeout(() => {
        window.history.back();
       }, 500);
      }}
     >
      <small className="font-weight-bold">CLOSE</small>
     </button>
    </div>
    {/* <div className="p-3 text-white d-flex flex-row align-items-end video-cover w-100 h-100">
      <button className="btn">
       <i class="fas fa-times text-dark position-absolute clear" />
      </button>
     </div> */
    /* {this.state.modalVisible && ( */}
    <iframe
     title="intro-video"
     src={`https://www.youtube.com/embed/${
      this.props.link
     }?rel=0&autoplay=0&controls=1&modestbranding=1&autohide=1&showinfo=0`}
     frameborder="0"
     allowfullscreen
     className="d-flex flex-fill w-100"
    />
    {/* )} */}
   </Modal>
  );
  //   return <Route path={this.state.path} exact component={Item} />;
 }
 componentDidMount() {
  this.setState({
   path: window.location.pathname.replace("/video", "") + "/video"
  });
 }

 //  componentWillReceiveProps(props) {
 //   this.setState({ modalVisible: props.modalVisible });
 //  }
}

class Item extends Component {
 state = { modalVisible: true };
 render() {
  return <div />;
 }

 componentDidMount() {
  // alert("mounting");
  this.props.show(true);
 }

 componentWillUnmount() {
  // alert("unmounting");
  this.props.show(false);
 }
}

export default VideoModal;
