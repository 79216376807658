import React, { Component } from "react";
// images
import article2 from "../../../img/article2.jpg";
import { Link } from "react-router-dom";
import Nav from "../../components/nav";

import twitter_icon from "../../../img/twitter_icon.png";
import facebook_icon from "../../../img/facebook_icon.png";
import Footer from "../../components/Footer";
import logo from "../../../img/logo.png";

class Article extends Component {
 state = {};
 render() {
  window.scrollTo(0, 0);
  return (
   <>
    <Nav />
    <div className="article-parent">
     <div className="article-header">
      <div className="container d-flex flex-column flex-fill justify-content-between h-100 text-white">
       <div className="w-100 text-center mt-5">
        <img src={logo} alt="" className="main-logo" />
       </div>

       <div>
        <h1 className="font-weight-bold">There is no secret to wealth</h1>

        <div className="text-white align-items-center mb-5">
         <div className="font-weight-normal d-flex align-items-center flex-row">
          <small className="font-weight-normal">23 March 2019</small>{" "}
          <div className="d-inline-block mx-2">|</div>
          <small className="font-weight-normal">Share</small>
          <div className="d-flex flex-row ml-2">
           <a href="https://twitter.com/AmanaCapitalKE" className="mr-3">
            <img src={twitter_icon} alt="" className="social-icon" />
           </a>

           <a
            href="https://www.facebook.com/amanacapital2003/"
            className="mr-3"
           >
            <img src={facebook_icon} alt="" className="social-icon" />
           </a>
          </div>
         </div>
        </div>
       </div>
      </div>
     </div>

     {/* content */}

     <div className="container pb-5">
      <div className="text-muted mt-3 mb-5">
       <small>Home / Blog / There is no secret to wealth</small>
      </div>

      <div className="row">
       <div className="col-md-9">
        <div className="text-dark article-text">
         <p>
          That is the secret that every wealthy person knows – that there is no
          secret. There is no magic formula, there is no short-cut, there is
          only a simple set of steps which any and everyone can follow to put
          themselves on the path to a much wealthier future.
         </p>
         <p>
          The 7 Steps to Wealth is a free online course for all Kenyans that has
          been developed by Amana Capital, who are themselves one of the leading
          wealth management firms in Kenya, and are responsible for managing the
          money of some of the regions most successful investors.
         </p>
         <p>
          The 7 Steps to Wealth course will teach you everything that you need
          to know about how to re-organise your financial life in such a way
          that the attainment of wealth ceases to be a pipe dream and starts to
          become a reality. At the heart of the course is the simple basic truth
          that it is only by saving a proportion of your income each month, and
          selecting the right vehicle for those savings, that you can have any
          hope of attaining financial independence and freedom in this life.
         </p>
         <p>
          The 7 Steps to Wealth course does not require you to have a degree in
          accounting or financial management. It is designed to be easily
          understandable and useful to all Kenyans. In each step a set of simple
          games, tests and tools will help you manage your day to day finances,
          learn the financial mistakes that you may be making, and select the
          appropriate savings options for your money.
         </p>
         <b className="mb-2 d-block">Step 1: “Fanya Budget”</b>
         <p>
          You will learn the crucial difference between accounting for where
          your money is going and budgeting for where it should go. You will
          learn how to divide your expenditure between the the things that you
          need and the things that you want, and you will learn how to apply the
          golden 50:30:20 ration to your monthly income so as to ensure that you
          are saving sufficiently to fund your future wealth.
         </p>
         <b className="mb-2 d-block">Step 2, “Raise Chumz”</b>
         <p>
          You will learn how you can raise some extra cash to get your savings
          up and running, as well as a host of tips and guides on how to avoid
          common spending mistakes that invariably lead to a reduced amount
          available for your savings.
         </p>
         <b className="mb-2 d-block">Step 3, “Weka Goals”</b>
         <p>
          You will learn the very important lesson regarding how to turn your
          life dream into a set of manageable and achievable monthly financial
          savings goals. Everyone of us has dreams for their life, what
          determines success is the ability to translate those dreams into a
          financial target in terms of how much money you need to achieve those
          dreams, and how much you need to save each month, earning what
          returns, in order to reach that target.
         </p>
         <b className="mb-2 d-block">Step 4, “Cheki Options”</b>
         <p>
          You will learn all about the risks and rewards involved in the
          different savings options available to you, from shares to real estate
          to savings accounts and more. You will earn how to balance these
          options and how to determine which option, or range of options, is
          right for you given your goals and stage of life.
         </p>
         <b className="mb-2 d-block">Step 5, “Tafuta Expert”</b>
         <p>
          You will learn all about Unit Trusts, the chama-like professionally
          managed and regulated col lective investment schemes that not only
          help you achieve some of the best year on year returns in the market,
          but also allow you to do so without exposing yourself to the many
          risks involved in the savings alternatives.
         </p>
         <b className="mb-2 d-block">In Step 6, “Ngoja”</b>
         <p>
          You will be given a host of tips and advice as to how you can stick to
          the most important step of all, which is sticking to the plan. At the
          heart of this Step is the simple truth that wealth comes to those who
          save consistently, achieve superior returns on their savings, and
          resist the temptation to dip into their savings before their set
          financial goal has been reached.
         </p>
         <p>
          In the final Step, “Learn from the best”, you will benefit from advice
          and investment lessons for a wide range of Kenya’s most successful
          businesspeople and financial gurus. This last step is the step that
          you will keep taking all the way until you have achieved the wealth
          that you set out to achieve, and is designed to help you continually
          improve upon your savings and investment decisions.
         </p>
         <p>
          The 7 Steps to Wealth course is free to all Kenyans. There are no
          strings attached, no hidden costs, no terms and conditions. Amana
          Capital’s mission is to empower all Kenyans with the skills and
          knowledge that they require so that we can build a wealthier and more
          prosperous Nation for us all.
         </p>
         <p>
          To start taking the 7 Steps to Wealth for yourself, and put yourself
          on the path to the life of your dreams, register for the &amp; steps
          to Wealth course today at www.amanacapital.co.ke.
         </p>
        </div>
       </div>
       <div className="col-md-3">
        <b>Related Articles</b>

        <Link to="/article2">
         <div className="bg-light my-5 position-relative">
          <img src={article2} alt="" className="w-100" />
          <div className="red-text p-3">
           <small>Welcome to the 7 Steps to Wealth</small>
          </div>
         </div>
        </Link>
       </div>
      </div>
     </div>
    </div>
    <Footer />
   </>
  );
 }
}

export default Article;
