import React, { Component } from "react";
import logo from "../../img/logo.png";
import arrow from "../../img/icons/carret_up.svg";

import { TweenMax, Power2, TimelineLite, Power4 } from "gsap/TweenMax";
import Option from "../components/options";
import ButtonSm from "../components/buttonSm";
import { Link } from "react-router-dom";

class S5 extends Component {
  state = {
    ...window.slide4.data[this.props.index]
  };

  render() {
    let letters = ["A", "B", "C", "D", "E", "F"];
    return (
      <>
        <div className="d-flex flex-fill flex-column align-items-center justify-content-center step-1 default-bg cover">
          <div className="d-flex flex-column align-items-center text-white text-center w-100">
            <div className="w-100 d-flex flex-column align-items-center ">
              <div className="px-3 py-3 info-block desc-text b20 pt-md-3 w-100">
                <div className="d-flex flex-row justify-content-center top-bar">
                  <div className="text-right">RISK</div>
                  <div className="font-weight-bold mx-5 text-center">
                    {window.slide4.data[this.props.parent].name}
                  </div>
                  <div className="text-left">REWARD</div>
                </div>
              </div>
            </div>

            <div>
              <h5 className="text-center my-4 mb-5 font-weight-bold">
                How do you begin ?
              </h5>

              <div>
                {this.state.options.map((d, i) => (
                  <div
                    className="d-flex flex-row align-items-center my-3"
                    key={i}>
                    <div className="mr-3">{letters[i]}.</div>
                    <Link
                      onClick={() => {
                        window.slide4.results[this.props.index] = {
                          ...d,
                          index: i
                        };
                      }}
                      to="/slide4/step6"
                      className="p-1 px-3 text-gold property-option text-left">
                      {d.title}
                    </Link>
                  </div>
                ))}
              </div>
            </div>

            <div className="flex-column justify-content-center d-flex mt-5">
              <img src={arrow} alt="" />
              <small className="text-primary mt-2 font-weight-bold text-sm">
                CHOOSE TO CONTINUE
              </small>
            </div>
          </div>
        </div>
        <ButtonSm to="/slide4/step6" />
      </>
    );
  }

  componentDidMount = () => {
    setTimeout(() => {
      this.setState({ ...window.slide4.data[this.props.index] });
      window.slide4.currentOption = this.props.index;
    }, 0);
  };
}

export default S5;
