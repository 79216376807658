import React, { Component } from "react";
import logo from "../../img/logo.png";
import Button from "../components/button";
import bg_ring from "../../img/icons/Results/bg_ring.svg";
import { Link } from "react-router-dom";

import arrow_right from "../../img/arrow_right.png";
import arrow_left from "../../img/icons/arrow_left.svg";
import ButtonSm from "../components/buttonSm";

class S4 extends Component {
  state = {
    ...window.slide6,
    future: window.calc.future ? window.calc.future : 0,
    savings: window.calc.savings
  };
  render() {
    if (!window.slide6.results) window.slide6.results = [];
    let results = window.slide6.results;

    return (
      <>
        <div className="d-flex flex-fill flex-column align-items-center justify-content-center step-1 default-bg cover">
          <div className="d-flex flex-column align-items-center text-white text-center mt-5 my-md-0">
            <img src={logo} alt="" className="main-logo d-sm-none" />

            <div class="py-3 total-anim text-center text-gold position-relative mt-4">
              <h3> {this.state.future.toLocaleString()} </h3>
              <small class="text-white">
                This is what your Savings will earn you in 5 years.
              </small>
            </div>

            <div className="mt-4 mb-3">
              <h5 className="">
                <b className="title-text">Your Spending Behaviour</b>
              </h5>
            </div>

            <div className="row container">
              {results.map((d, i) => (
                <div className="col-md-6 text-center" key={i}>
                  <div
                    className="py-3 text-white d-flex flex-row s4-option contain-lg b21 d-inline-block"
                    key={i}>
                    <div className="icon-option d-flex justify-content-center flex-column align-items-center position-relative">
                      <img
                        src={bg_ring}
                        alt=""
                        className="position-absolute align-self-center text-gold"
                      />
                      <img
                        src={this.state.scenarios[i].options[d].award.icon}
                        alt=""
                        className="position-absolute align-self-center icon text-dark"
                      />
                    </div>
                    <div className="d-flex flex-fill flex-column">
                      <small className="text-left font-weight-bold">
                        {this.state.scenarios[i].options[d].award.title}
                      </small>
                      <small className="text-left">
                        {this.state.scenarios[i].options[d].award.desc}
                      </small>
                    </div>
                  </div>
                </div>
              ))}
            </div>

            <div class="mt-md-5">
              <Button back={false} to="/slide6/step5" />
            </div>
          </div>
        </div>
        <ButtonSm back={false} to="/slide6/step5" />
      </>
    );
  }

  componentDidMount() {
    localStorage.cmplt1 = "true";
    console.log("step5 mounted", window.calc.future);
    this.setState({ future: window.calc.future });
    this.calculateFuture();
  }

  calculateTotal = () => {
    let total = window.calc.savings;

    //   console.log(total);
    this.setState({ savings: total });
    setTimeout(() => {
      this.calculateFuture();
    }, 0);
  };

  update = () => {
    // adding persistency
    localStorage.huntItems = JSON.stringify(this.state);
  };

  calculateFuture() {
    // y = 12;
    console.log(this.state.savings);
    let p = this.state.savings;
    let r = 0.12;
    let i = 1 + r / 12;

    let G = (i * (Math.pow(i, 60) - 1)) / (i - 1);

    this.setState({ future: parseInt(G * p) });
  }
}

export default S4;
