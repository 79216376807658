import React, { Component } from "react";
import { Route, BrowserRouter as Router } from "react-router-dom";
import amana_logo from "../../img/amana-logo.png";
import Nav from "../components/nav";

import S1 from "./s1";
import S2 from "./s2";
import Parent from "./parent";
import S7 from "./s7";
import Article1 from "./articles/article1";
import Article2 from "./articles/article2";

class Slide4Home extends Component {
  state = {};
  render() {
    return (
      <div>
        <Nav />
        <main className="main-page d-flex bg-dark flex-column">
          <div className="cover">
            <Route path="/(slide4|slide4/video)/" exact component={S1} />
            <Route path="/slide4/step2" component={S2} />
            <Parent />
            <Route path="/slide4/step7" component={S7} />
            <Route path="/slide4/article1" component={Article1} />
            <Route path="/slide4/article2" component={Article2} />
          </div>
        </main>
      </div>
    );
  }
}

export default Slide4Home;
