import React, { Component } from "react";
import Button from "./button";
import { Link } from "react-router-dom";

import twitter_icon from "../../img/twitter_icon.png";
import facebook_icon from "../../img/facebook_icon.png";
import youtube_icon from "../../img/youtube_icon.png";
import amana_logo from "../../img/amana-logo.png";

class Footer extends Component {
 state = {};
 render() {
  return (
   <>
    <footer class="bg-red py-5">
     <div class="container py-5 text-white">
      <div class="row">
       <div class="col-md-6">
        <div>
         <img src={amana_logo} alt="" className="footer-logo" />
        </div>
        <div className="footer-text1">
         <h6 class="mb-5 mt-4 pt-2">
          <b>Amana Capital Limited, where we help you save</b>
         </h6>
        </div>
        <div className="d-flex flex-row pt-3">
         <a
          href="https://twitter.com/AmanaCapitalKE"
          target="_blank"
          className="mr-3"
         >
          <img src={twitter_icon} alt="" className="social-icon" />
         </a>

         <a
          href="https://www.facebook.com/amanacapital2003/"
          target="_blank"
          className="mr-3"
         >
          <img src={facebook_icon} alt="" className="social-icon" />
         </a>

         <a
          href="https://www.youtube.com/channel/UCZ5eBGBF57WAKFwbOpXD0Tg"
          target="_blank"
          className="mr-3"
         >
          <img src={youtube_icon} alt="" className="social-icon" />
         </a>
        </div>
       </div>
       <div class="col-md-6">
        <h6 class="mt-4 mb-5">
         <b>GET IN TOUCH</b>
        </h6>
        <span class="pb-2 pt-0 text-md d-block">
         <a href="#">info@amanacapital.co.ke</a>
        </span>
        <span class="py-2 text-primary">
         <div className="text-primary text-md">
          +245 020 235 1741 or +245 020 235 1741 or +245 020 235 1741
         </div>
        </span>
       </div>
      </div>
     </div>
     <div class="container mb-5">
      <div class="row">
       <div class="col-md-6" />
       <div class="col-md-6 mb-4">
        <small>
         <nav>
          <a href="https://www.amanacapital.co.ke/" class="mx-1 text-white">
           Home
          </a>
          <a
           href="https://www.amanacapital.co.ke/about-us/"
           class="mx-1 text-white"
          >
           About us
          </a>
          <a
           href="https://www.amanacapital.co.ke/amana-growth-fund/"
           class="mx-1 text-white"
          >
           Savings plans
          </a>
          <a
           href="https://www.amanacapital.co.ke/fund-performances/"
           class="mx-1 text-white"
          >
           Fund perfomances
          </a>

          <a
           href="https://www.amanacapital.co.ke/team/"
           class="mx-1 text-white"
          >
           Team
          </a>

          <a
           href="https://www.amanacapital.co.ke/media/"
           class="mx-1 text-white"
          >
           Media
          </a>

          <a
           href="https://www.amanacapital.co.ke/contact-us"
           class="mx-1 text-white"
          >
           Contact us
          </a>
         </nav>
        </small>
       </div>
       <div class="col-md-6">
        <small>
         <a href="#" class="mr-md-3 bottom-link text-white">
          Terms and conditions
         </a>

         <a href="#" class="mr-md-3 bottom-link text-white">
          User privacy statement
         </a>
        </small>
       </div>
       <div class="col-md-6">
        <small>
         <div class="mr-md-3 bottom-link text-white">
          &copy; Copyright 2019 - Amana capital limited. Built by{" "}
          <b>
           <a href="https://legibra.com/">Legibra</a>
          </b>
         </div>
        </small>
       </div>
      </div>
     </div>
    </footer>
   </>
  );
 }
}

export default Footer;
