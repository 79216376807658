import React, { Component } from "react";
import logo from "../../img/logo.png";
import Button from "../components/button";
import { Link } from "react-router-dom";
import ButtonSm from "../components/buttonSm";
import HoverCard from "../components/hoverCard";
import Counter from "../components/counter";
import arrow_right from "../../img/arrow_right.png";
import arrow_left from "../../img/icons/arrow_left.svg";
import Lessons from "../components/Lessons";

class Step12 extends Component {
 calculate = () => {
  let { calc } = window;
  let total = calc.earnings - (calc.needs + calc.wants);
  return total;
 };
 state = { balance: this.calculate() };
 render() {
  // update database
  window.updatePersistence();

  return (
   <>
    <div className="slide d-flex flex-fill flex-column align-items-center justify-content-center step-1 default-bg">
     <HoverCard />
     <Counter current={5} total={6} />

     <div className="d-flex flex-column align-items-center text-white text-center">
      <img src={logo} className="main-logo" />
      <Counter current={4} total={6} />
      <div className="total-section mx-3">
       <h2 className="mt-3 mt-md-5">
        <b className="title-text tf">
         Step 2: Raize Chumz launches on June 17th!
        </b>
       </h2>

       <div className="mt-3">
        <span className="desc-text font-weight-bold d-inline-block">
         Learn how to increase how much you can save by changing perspective on
         our things and habits. In the meantime we have prepared a few articles
         below to help you stick to your plan for wealth!
        </span>
       </div>
      </div>
      <div className="mt-5 d-n">
       <div class="d-md-flex d-none flex-row flex-wrap align-items-center">
        <div class="">
         <button
          class="btn bg-gold py-2 px-3  mx-2 d-inline-block d-flex flex-row align-items-center justify-content-between btn-next scroll-button"
          data-target="#lessons-section"
         >
          <div />
          <small class="text-white text-sm ">
           <b>CONTINUE LEARNING</b>
          </small>
          <img src={arrow_right} alt="" class="ml-2" />
         </button>
        </div>
       </div>
      </div>
     </div>
    </div>
    {/* <ButtonSm
     backonly={this.state.balance < 0}
     text="CONTINUE LEARNING"
     to="/slide1/step4_5m"
    /> */}

    <div class="navigation-buttons d-md-none d-flex flex-row">
     <div class="w-100">
      <button
       class="btn btn-sm-next scroll-button w-100"
       data-target="#lessons-section"
      >
       <small class="text-white text-uppercase">
        <b>CONTINUE LEARNING</b>
       </small>
      </button>
     </div>
    </div>

    <Lessons />
   </>
  );
 }

 componentDidMount() {
  if (localStorage.finishedStep1 === undefined) {
   fetch(window.server + "finish.php", {
    method: "POST",
    headers: {
     // "Content-Type": "application/json",
     "Access-Control-Allow-Origin": "*",
     "Content-Type": "application/x-www-form-urlencoded"
    },
    body: `user=${localStorage.id}`
   })
    .then(response => response.text())
    .then(response => {
     this.setState({ disabled: false, modalVisible: false });
     console.log(response);
    });

   localStorage.finishedStep1 = "true";
  }
 }
}

export default Step12;
