import React, { Component } from "react";
import logo from "../../img/logo.png";
import Button from "../components/button";
import ButtonSm from "../components/buttonSm";
import { Link } from "react-router-dom";

class S3 extends Component {
 state = {
  total: 0,
  items: [],
  input: { name: "", amount: "" },
  future: window.calc.future,
  savings: window.calc.savings
 };
 render() {
  return (
   <>
    <div className="d-flex flex-fill flex-column align-items-center justify-content-center step-1 default-bg cover">
     <div className="d-flex flex-column align-items-center text-white text-center">
      <img src={logo} alt="" className="main-logo mb-4" />

      <div class="py-3 total-anim text-center text-gold position-relative">
       <h3> {(this.state.future + this.state.total).toLocaleString()} </h3>
       <small class="text-white">
        This is what your Savings will earn you in 5 years.
       </small>
      </div>

      <div className="mt-3 mb-3">
       <h5 className="">
        <b className="title-text">It’s Time to Hunt!</b>
       </h5>
       <div className="mt-2 desc-text ">
        How much is the stuff gathering dust in your cupboard worth as savings?
        Lets find out.
       </div>
      </div>

      <div className="">
       {this.state.items.length !== 0 && (
        <div className="item-parent contain my-3">
         {this.state.items.map((d, i) => (
          <div
           className="d-flex flex-row justify-content-between px-3 py-2 chumz item"
           key={i}
          >
           <div>
            <i
             class="fas fa-times-circle text-gold cursor-pointer"
             onClick={() => {
              let { items } = this.state;
              items.splice(i, 1);
              this.setState({ items });
              this.calculateTotal();
             }}
            />
            <span className="ml-2">{d.name}</span>
           </div>
           <span className="balance">Kes {d.amount.toLocaleString()}</span>
          </div>
         ))}
        </div>
       )}
      </div>

      <div className="my-3 desc-text px-0 input-sect row">
       <div className="col-md-6">
        <input
         type="text"
         name=""
         class="py-2 px-3 text-left text-bronze card-input no-anim d-flex flex-fill w-100"
         value={this.state.input.name}
         placeholder="What is it?"
         onChange={event =>
          this.setState({
           input: { ...this.state.input, name: event.target.value }
          })
         }
        />
       </div>
       <div className="d-flex flex-row align-items-center mt-3 col-md-6 mt-md-0">
        <input
         type="text"
         name=""
         class="py-2 d-flex amt-i flex-fill px-3 text-left text-bronze card-input no-anim d-flex input w-100"
         value={this.state.input.amount.toLocaleString()}
         placeholder="How much was it?"
         onChange={event => {
          let amount = parseInt(event.target.value.replace(/,/g, ""));
          // console.log(amount);
          if (!isNaN(amount)) {
           this.setState({ input: { ...this.state.input, amount } });
          }
          if (event.target.value === "") {
           this.setState({ input: { ...this.state.input, amount: 0 } });
          }
         }}
        />
        <div className="d-flex flex-row bg-white toggle-button-parent">
         <button
          className="btn toggle-button"
          onClick={() => {
           let amount = this.state.input.amount;
           if (amount === "") amount = 0;
           amount -= 1000;
           if (amount < 0) return false;
           this.setState({ input: { ...this.state.input, amount } });
          }}
         >
          -
         </button>
         <button
          className="btn toggle-button"
          onClick={() => {
           let amount = this.state.input.amount;
           if (amount === "") amount = 0;
           amount += 1000;
           if (amount < 0) return false;
           this.setState({ input: { ...this.state.input, amount } });
          }}
         >
          +
         </button>
        </div>
       </div>
      </div>

      <div className="mt-4">
       <button
        onClick={() => {
         let { input } = this.state;
         console.log(input);
         if (input.name === "" || input.amount === "") {
          alert("please fill in all the fields");
          return false;
         }
         this.setState({
          items: [
           ...this.state.items,
           { name: input.name, amount: input.amount }
          ],
          input: { name: "", amount: "" }
         });
         setTimeout(() => {
          this.calculateTotal();
         }, 0);
        }}
        className="btn btn-sm btn-rounded btn-outline-primary btn-add2 p-1 pr-3 d-flex flex-row align-items-center"
       >
        <i class="fas fa-plus-circle mr-2" />
        <span>Add</span>
       </button>
      </div>

      <div className="mt-md-5">
       {localStorage.cmplt1 === "true" && (
        <Button
         to="/slide2/step7"
         onClick={() => {
          window.slide2.totals = {
           ...window.slide2.totals,
           opt1: this.state.total
          };
          localStorage.slide2 = JSON.stringify(window.slide2);
         }}
         text="I AM DONE"
        />
       )}

       {localStorage.cmplt1 !== "true" && (
        <Button
         to="/slide2/step2_5"
         onClick={() => {
          window.slide2.totals = {
           ...window.slide2.totals,
           opt1: this.state.total
          };
          localStorage.slide2 = JSON.stringify(window.slide2);
         }}
         text="LEARN TO SPEND"
        />
       )}
      </div>
     </div>
    </div>
    {localStorage.cmplt1 === "true" && (
     <ButtonSm
      to="/slide2/step7"
      onClick={() => {
       window.slide2.totals = {
        ...window.slide2.totals,
        opt1: this.state.total
       };
       localStorage.slide2 = JSON.stringify(window.slide2);
      }}
      text="I AM DONE"
     />
    )}

    {localStorage.cmplt1 !== "true" && (
     <ButtonSm
      to="/slide2/step2_5"
      onClick={() => {
       window.slide2.totals = {
        ...window.slide2.totals,
        opt1: this.state.total
       };
       localStorage.slide2 = JSON.stringify(window.slide2);
      }}
      text="LEARN TO SPEND"
     />
    )}
   </>
  );
 }

 componentDidMount() {
  localStorage.cmplt2 = "true";
  let $t = this;
  setTimeout(() => {
   $t.setState({ future: window.calc.future });
  }, 0);
  if (localStorage.huntItems)
   this.setState({ ...JSON.parse(localStorage.huntItems) });
  setTimeout(() => {
   //    this.calculateTotal();
  }, 0);
 }

 componentWillUnmount() {
  this.update();
  window.calc.savings = this.state.savings + this.state.total;
  window.calc.future = this.state.future + this.state.total;
 }

 calculateTotal = () => {
  let total = 0;
  this.state.items.map(d => {
   total += d.amount;
  });
  //   console.log(total);
  this.setState({
   total
  });
  setTimeout(() => {
   window.calc.savings = this.state.savings + this.state.total;
   window.calc.future = this.state.future + this.state.total;
  }, 0);
 };

 update = () => {
  // adding persistency
  localStorage.huntItems = JSON.stringify(this.state);
 };

 calculateFuture() {
  // y = 12;
  console.log(this.state.savings);
  let p = this.state.savings;
  let r = 0.12;
  let i = 1 + r / 12;

  let G = (i * (Math.pow(i, 60) - 1)) / (i - 1);

  this.setState({ future: parseInt(G * p) });
 }
}

export default S3;
