import React, { Component } from "react";
import Input from "../components/input";
import StepperItem from "../components/stepperItem";
import Button from "../components/button";
import logo from "../../img/logo.png";
import { Link } from "react-router-dom";

import leaf from "../../img/leaf.png";
import HoverCard from "../components/hoverCard";
import arrow_right from "../../img/arrow_right.png";
import Input2 from "../components/input2";

class Step5 extends Component {
 state = {
  needs: window.calc.needs,
  savings: window.calc.savings,
  wants: window.calc.wants,
  options: {
   ...window.store
  },
  limits: {
   needs: window.calc.earnings * 0.5,
   wants: window.calc.earnings * 0.3,
   savings: window.calc.earnings * 0.2
  }
 };
 render() {
  let mut =
   //  this.calculatePercentage(this.state.needs) > 50 ||
   this.calculatePercentage(this.state.wants) > 30 ||
   this.calculatePercentage(this.state.savings) > 20
    ? "mute-buttons"
    : "";

  return (
   <div className="cover d-flex flex-fill align-items-center justify-content-center text-white default-bg flex-column py-4">
    <HoverCard />
    <img src={logo} className="main-logo d-xsm d-none" />
    <div className="mt-3">
     <div className="stepper-text-section text-center d-xsm">
      {/* <small className="desc-text font-weight-bold d-inline-block">
       Now you can create a plan for you to try and follow. Adjust the values to
       try and keep the 50 : 30: 20 rule. Remember to download your personalized
       plan before you go.
      </small> */}

      <div className="py-3 total-anim text-center text-gold mb-3 mt-4">
       <h3>{this.calculateFuture(60, this.state.savings).toLocaleString()} </h3>
       <span className="text-white">
        This is what it will earn you in 5 years
       </span>
      </div>
     </div>
    </div>

    <div className="container ">
     <div className="adjust-parent">
      <div className="d-flex flex-row adjust justify-content-center">
       <div
        className={
         "col-md-4 d-flex flex-column align-items-center text-center " +
         (this.calculatePercentage(this.state.needs) === 50 ? " limit " : "") +
         (this.calculatePercentage(this.state.needs) > 50 ? "error" : "")
        }
       >
        <div className="mt-5 position-relative">
         <img src={leaf} alt="" className="position-absolute leaf-icon-image" />
         <h5 className="text-gold text-error">
          {this.calculatePercentage(this.state.needs)}%{/* 50% */}
         </h5>
         <small className="font-weight-bold text-error">Needs</small>
        </div>
        <small className="text-gold font-weight-bold mb-3 mt-4 pt-1">
         TARGET : {this.state.limits.needs.toLocaleString()}{" "}
        </small>
        <div className="inputContainer my-0">
         <Input2
          value={"Kes " + this.state.needs.toLocaleString()}
          className="input-error text-error"
         />
        </div>
        <div className="my-4 d-inline-block">{this.getStepper("needs")}</div>
       </div>

       <div
        className={
         "col-md-4 d-flex flex-column align-items-center text-center " +
         (this.calculatePercentage(this.state.wants) === 30 ? " limit " : "") +
         (this.calculatePercentage(this.state.wants) > 30 ? "error" : "")
        }
       >
        <div className="mt-5 position-relative">
         <img src={leaf} alt="" className="position-absolute leaf-icon-image" />
         <h5 className="text-gold text-error">
          {this.calculatePercentage(this.state.wants)}%{/* 30% */}
         </h5>
         <small className="font-weight-bold text-error">Wants</small>
        </div>
        <small className="text-gold font-weight-bold mb-3 mt-4 pt-1">
         TARGET : {this.state.limits.wants.toLocaleString()}{" "}
        </small>
        <div className="inputContainer my-0">
         <Input2
          value={"Kes " + this.state.wants.toLocaleString()}
          className="input-error text-error"
         />
        </div>
        <div className="my-4 d-inline-block">{this.getStepper("wants")}</div>
       </div>

       <div
        className={
         "col-md-4 d-flex flex-column align-items-center text-center " +
         (this.calculatePercentage(this.state.savings) === 20
          ? " limit "
          : "") +
         (this.calculatePercentage(this.state.savings) > 2000000 ? "error" : "")
        }
       >
        <div className="mt-5 position-relative">
         <img src={leaf} alt="" className="position-absolute leaf-icon-image" />
         <h5 className="text-gold text-error">
          {this.calculatePercentage(this.state.savings)}%{/* 20% */}
         </h5>
         <small className="font-weight-bold text-error">Saving</small>
        </div>
        <small className="text-gold font-weight-bold mb-3 mt-4 pt-1">
         TARGET : Kshs {this.state.limits.savings.toLocaleString()}
        </small>
        <div className="inputContainer my-0">
         <Input2
          value={"Kes " + this.state.savings.toLocaleString()}
          className="input-error text-error"
         />
        </div>
        {/* <div className="my-4 d-inline-block">{this.getStepper("savings")}</div> */}
       </div>
      </div>
     </div>
    </div>

    <div className={"w-100 justify-content-center flex-row text-center "}>
     <div className="my-2 d-inline-block">
      <Link
       className="btn bg-gold py-2 px-5  mx-2 d-inline-block d-flex flex-row align-items-center justify-content-between w-auto"
       to="/slide1/step11"
       onClick={() => {
        let { needs } = this.state;
        let { wants } = this.state;
        let { savings } = this.state;
        window.store = this.state.options;
        window.calc = { ...window.calc, needs, wants, savings };
        console.log(window.store, window.calc);
        localStorage.step1Options = JSON.stringify(window.store);
        localStorage.calc = JSON.stringify(window.calc);
        window.updatePersistence();
        window.scrollTo(0, 0);
        // this.props.history.push("/slide1/step12");
       }}
      >
       <div />
       <small class="text-white text-sm ">
        <b>FINISH</b>
       </small>
      </Link>
     </div>
     <div className="d-none my-2 d-inline-block/ mute">
      <Button back={false} text="refer a friend" auto={true} noIcon={true} />
      {/* <Button
              text="next course"
              to="/slide2/"
              auto={true}
              noIcon={false}
            /> */}
     </div>

     <div className="d-none">
      <button
       to={this.props.to}
       className="btn bg-gold py-2 px-3 w-auto mx-2 d-inline-block d-flex flex-row align-items-center justify-content-between "
       onClick={() => {
        let { needs } = this.state;
        let { wants } = this.state;
        let { savings } = this.state;
        window.store = this.state.options;
        window.calc = { ...window.calc, needs, wants, savings };
        console.log(window.store, window.calc);
        localStorage.step1Options = JSON.stringify(window.store);
        localStorage.calc = JSON.stringify(window.calc);
        window.scrollTo(0, 0);
        window.h.push("/slide2/");
       }}
      >
       <div />
       <small className="text-white text-sm ">
        <b>NEXT COURSE</b>
       </small>
       <img src={arrow_right} alt="" className="ml-1" />
      </button>
     </div>
    </div>
   </div>
  );
 }

 update(type, amount, d, t) {
  let state = { ...this.state };
  state[type] += amount;
  state.options[type][d] += amount;
  // console.log(state[type]);
  // if (
  //  state[type] <= this.state.limits[type] ||
  //  state[type] < this.state[type]
  // ) {
  //  this.setState({ ...state });
  // }

  this.setState({
   ...state
  });
  setTimeout(() => {
   let savings = window.calc.earnings - (this.state.needs + this.state.wants);
   if (savings < 0) {
    savings = 0;
   }
   this.setState({
    ...state,
    savings: savings
   });
  }, 100);
  // if (t) {
  //  this.setState({ ...state });
  // }
 }

 calculatePercentage(i) {
  return parseInt((i / window.calc.earnings) * 100);
 }

 getStepper(el) {
  return Object.keys(this.state.options[el]).map((d, i) => {
   return (
    <StepperItem
     key={i}
     title={d}
     onChange={(val, t) => this.update(el, val, d, t)}
     amount={this.state.options[el][d]}
    />
   );
  });
 }

 getBalance = () => {
  let { calc } = window;
  let total = calc.earnings - (calc.needs + calc.wants);
  return total;
 };

 calculateFuture(y, p) {
  // let p = this.getBalance();
  let r = 0.12;
  let i = 1 + r / 12;

  let G = (i * (Math.pow(i, y) - 1)) / (i - 1);

  return parseInt(G * p);
 }
}

export default Step5;
