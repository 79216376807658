import React, { Component } from "react";

import { Link } from "react-router-dom";
import amana_logo from "../../img/amana-logo.png";

class Menu extends Component {
  state = {};
  render() {
    return (
      <div className="w-100 position-absolute">
        <div className="menu-panel">
          <div className="position-absolute w-100">
            <div className="py-4 container w-100 position-relative ghost-nav">
              <nav className="nav justify-content-end flex-row d-flex  align-items-center">
                {this.props.icon !== false && (
                  <img src={amana_logo} className="header-logo" alt="" />
                )}
                <div className="login-btn" />
                <div className=" bg-white hambuger humburger d-flex justify-content-center align-items-center">
                  <i className="fas fa-bars text-dark bars" />
                  <i className="fas fa-times text-dark position-absolute clear" />
                </div>
              </nav>
            </div>
          </div>

          <img src={amana_logo} className="background-logo" alt="" />

          <div className="container position-relative menu-container">
            <ul className="nav-links p-0 position-absolute text-white d-inline-block text-align-right">
              <div className="nav-links-after" />

              <li className="my-2 menu-link">
                <Link to="/" className="navlink px-3">
                  <small>&nbsp;&nbsp;</small> <b>Home</b>
                </Link>
              </li>

              <li className="my-2 menu-link">
                <Link to="/slide1/" className="navlink px-3">
                  <small>1.</small> <b>Fanya Budget</b>
                </Link>
              </li>

              <li className="my-2 menu-link">
                <Link to="/slide2/" className="navlink px-3">
                  <small>2.</small> <b>Raize Chumz</b>
                </Link>
              </li>

              <li className="my-2 menu-link">
                <Link to="/slide3/" className="navlink px-3">
                  <small>3.</small> <b>Weka Goals</b>
                </Link>
              </li>

              <li className="my-2 menu-link">
                <Link to="/slide4/" className="navlink px-3">
                  <small>4.</small> <b>Cheki Options</b>
                </Link>
              </li>

              <li className="my-2 menu-link">
                <Link to="/slide5/" className="navlink px-3">
                  <small>5.</small> <b>Tafuta Expert</b>
                </Link>
              </li>

              <li className="my-2 menu-link">
                <Link to="/slide6/" className="navlink px-3">
                  <small>6.</small> <b>Ngoja</b>
                </Link>
              </li>

              <li className="my-2 menu-link">
                <Link to="/slide7/" className="navlink px-3">
                  <small>7.</small> <b>Learn from the Best</b>
                </Link>
              </li>
            </ul>
          </div>
          <div className="foot-note p-3 text-light d-none d-md-bloc ">
            <div>
              In compliance with the The Capital Markets (Collective investment
              Schemes) Regulations, 2001 82 (3), ‘a’ and ‘b’ on advertising, the
              investor is advised:
            </div>
            <div>
              <b>(a)</b> The price of shares, and the income there from if the
              collective investment scheme pays dividends may go down as well as
              up; and
            </div>
            <div>
              <b>(b)</b> Investors are reminded that in certain specified
              circumstances their right to redeem their shares may be suspended.
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Menu;
