import React, { Component } from "react";
import { Route, BrowserRouter as Router } from "react-router-dom";
import amana_logo from "../../img/amana-logo.png";
import S1 from "./s1";
import Lessons from "../components/Lessons";
import S2 from "./s2";
import S3 from "./s3";
import S4 from "./s4";
import S5 from "./s5";

import consolation_spender from "../../img/icons/Results/consolation_spender.svg";
import Deal_hunter from "../../img/icons/Results/Deal_hunter.svg";
import Focused from "../../img/icons/Results/Focused.svg";
import Impulse_buyer from "../../img/icons/Results/Impulse_buyer.svg";
import keeping_itreal from "../../img/icons/Results/keeping_itreal.svg";
import list_maker from "../../img/icons/Results/list_maker.svg";
import livingitup from "../../img/icons/Results/livingitup.svg";
import long_arrow from "../../img/icons/Results/long_arrow.svg";
import necessity_spender from "../../img/icons/Results/necessity_spender.svg";

import arrow from "../../img/icons/options/arrow.svg";
import chill from "../../img/icons/options/chill.svg";
import drink from "../../img/icons/options/drink.svg";
import Impact from "../../img/icons/options/Impact.svg";
import list from "../../img/icons/options/list.svg";
import moments from "../../img/icons/options/moments.svg";
import nightout from "../../img/icons/options/nightout.svg";
import old_phone from "../../img/icons/options/old_phone.svg";
import Shopping from "../../img/icons/options/Shopping.svg";
import Smartphone from "../../img/icons/options/Smartphone.svg";
import Stay_home from "../../img/icons/options/Stay_home.svg";
import S6 from "./s6";
import Nav from "../components/nav";
import s7 from "./s7";
import S2_5 from "./s2_5";
import Article1 from "./articles/article1";
import Article2 from "./articles/article2";

class Slide2Home extends Component {
  //  state = {
  //   ...window.slide2,
  //   day: 1
  //  };
  render() {
    return (
      <div>
        <Nav />
        <main className="main-page d-flex bg-dark flex-column">
          <div className="cover">
            <Route path="/(slide2|slide2/video)/" exact component={S1} />
            <Route path="/slide2/step2" component={S2} />
            <Route path="/slide2/step3" exact component={S3} />
            <Route path="/slide2/step4" component={S4} />
            <Route path="/slide2/step5" component={S5} />
            <Route path="/slide2/step6" component={S6} />
            <Route path="/slide2/step7" component={s7} />
            <Route path="/slide2/step2_5" component={S2_5} />
            <Route path="/slide2/article1" component={Article1} />
            <Route path="/slide2/article2" component={Article2} />
          </div>
        </main>
        {/* <Lessons /> */}
      </div>
    );
  }

  componentWillMount() {
    window.slide2 = {
      scenarios: [
        {
          desc:
            "It’s the beginning of the month  and all your friends are meeting. What is your choice?",
          day: 3,
          options: [
            {
              icon: nightout,
              title:
                "Go have a night to remember and spend KES $mnt more than usual",
              percentage: -10,
              result:
                "That was fun and it was great reconnecting but you might be left with some guilt over how much you spent",
              award: {
                icon: consolation_spender,
                title: "CONSOLATION SPENDER",
                desc:
                  "Its easy to justify extra sending to make up for how tough life is. You need to keep in mind that it wont get easier by spending – only by saving."
              }
            },
            {
              icon: chill,
              title: "Join them but miss a few drinks and save KES $mnt",
              percentage: 5,
              result:
                "You don’t have to stay at home all the time but sacrificing a few nights out can make a huge difference",
              input: { title: "What does one night usually cost?", amount: 0 },
              award: {
                icon: Focused,
                title: "FOCUSED",
                desc:
                  "Congratulations you have your eye on the prize and can endure the shortfalls of life without spending to make it up."
              }
            }
          ]
        },

        {
          desc:
            "You have been budgeting for a new phone and there are two options on Jumia",
          day: 9,
          options: [
            {
              icon: old_phone,
              title:
                "5% discount on the phone you had budgeted for. Save KES $mnt",
              percentage: 10,
              result:
                "You walk away feeling the loss but it wears off the next time you get a call from a friend",
              input: { title: "How much did you save?", amount: 0 },
              award: {
                icon: necessity_spender,
                title: "NECESSITY SPENDER",
                desc:
                  "This is great! you only spend when necessary and don’t take on unnecessary items that wont see much use."
              }
            },
            {
              icon: Smartphone,
              title:
                "20% discount on the sexy new s10 . It will cost KES $mnt extra",
              percentage: -25,
              result:
                "big chunk of money and after a few weeks you realise the only difference is a larger screen and shorter battery life",
              award: {
                icon: Deal_hunter,
                title: "DEAL HUNTER",
                desc:
                  "You need to watch out for discounts and offers . They often make you belive that something is worth it when you could live without it."
              }
            }
          ]
        },

        {
          desc:
            "You have been invited for a big stylish event where you can meet a few celebs and big business people",
          day: 15,
          options: [
            {
              icon: Impact,
              title:
                "Go all out and make an impact!! This costs you KES $mnt more than you had budgeted",
              percentage: -5,
              result:
                "You styled up, looked great and had the photos to prove it. But it cost you quite a bit to be prepared.",
              award: {
                icon: livingitup,
                title: "LIVING IT UP",
                desc:
                  "Be careful. It is very easy to live beyond your means trying to match other peoples flashy lives. Remember they wont be there when you are broke."
              }
            },
            {
              icon: Stay_home,
              title: "Stay at home and chill with your friends saving $mnt",
              percentage: 5,
              result:
                "You had a good evening with friends and went home with a smile.",
              input: { title: "How much did you save?", amount: 0 },
              award: {
                icon: keeping_itreal,
                title: "KEEPING IT REAL",
                desc:
                  "Well done. You arent swayed by the flash and lights. You understand who and where you are in life and spend accordingly"
              }
            }
          ]
        },
        {
          desc:
            "Prices went up on the roadtrip you were planning with friends. Everyone still wants to go.",
          day: 21,
          options: [
            {
              icon: chill,
              title:
                "Chill at home and use the some of the money to console yourself . You save KES $mnt",
              percentage: 20,
              result:
                "It wasn’t fun watching them on holiday. It was more fun being only non broke friend when they came back.",
              input: {
                title: "How much do your trips usually cost?",
                amount: 0
              },
              award: {
                icon: nightout,
                title: "UNMOVEABLE",
                desc:
                  "Its easy for peer pressure to make you spend more. Keep in mind that only you can ensure your future wealth and should prioritise accordingly"
              }
            },
            {
              icon: moments,
              title:
                "Don’t miss out on the fun and special moments. The trip will cost KES $mnt extra",
              percentage: -20,
              result:
                "It was awesome and you had a blast but now you have to deal with a long broke two weeks",
              award: {
                icon: nightout,
                title: "FOMO CRAZY",
                desc:
                  "Its easy for peer pressure to make you spend more. Keep in mind that only you can ensure your future wealth and should prioritise accordingly"
              }
            }
          ]
        },
        {
          desc:
            "Its monthly shopping time for the family . How does it normally happen",
          day: 27,
          options: [
            {
              icon: Shopping,
              title:
                "Go to a big shopping mall and spend a long time window shopping. You spend KES $mnt extra ",
              percentage: -10,
              result:
                "You feel great after shopping and when you give your family their surprise. However each month you wonder where your money went.",
              award: {
                icon: nightout,
                title: "IMPULSE BUYER",
                desc:
                  "Shops and malls have spent a lot of time designing ways to get you to unconsciously spend more. Be mindful of the games and be strong for your future"
              }
            },
            {
              icon: list,
              title:
                "Go with a list and spend little time on only what you need plus a few discounts. You save KES $mnt",
              percentage: 10,
              result:
                "The list is done and you feel a sense of accomplishment because nothing was wasted",
              input: {
                title: "How much did you save on extra food and suprises?",
                amount: 0
              },
              award: {
                icon: nightout,
                title: "LIST MAKER",
                desc:
                  "Others may laugh but only fools deny the power of a good list. You get what you need with no wasted time and effort. Well done!"
              }
            }
          ]
        }
      ],
      results: [],
      total: 0,
      totals: {}
    };

    window.sld2 = {};
    window.sld2.results = [];

    if (localStorage.slide2)
      window.slide2 = { ...JSON.parse(localStorage.slide2) };
  }
}

export default Slide2Home;
