import React, { Component } from "react";
import ButtonSm from "../components/buttonSm";
import { Link } from "react-router-dom";
import { TweenMax, Power2, TimelineLite, Power4 } from "gsap/TweenMax";

import Carret from "../../img/step4/carret_left.svg";
import Ring from "../../img/step4/ring.svg";
import Button from "../components/button";
import pointer from "../../img/step4/pointer.svg";

class S6 extends Component {
  state = {
    next: 0,
    day: 2019 + window.slide4.data[this.props.index].years
  };
  render() {
    return (
      <>
        <div className="d-flex flex-fill flex-column align-items-center justify-content-center step-1 default-bg cover">
          <div className="d-flex flex-column align-items-center text-white text-center w-100">
            {this.state.next !== false && (
              <div className="yearSlider w-100 position-relative">
                <div className="container px-0 position-relative">
                  <div className="calendar-marker text-gold d-flex flex-column align-items-center justify-content-between">
                    <span className="marker-text">YEAR</span>
                    <div className="mark" />
                  </div>
                  <div className="calendar-slider flex-row d-flex">
                    {this.getCalendar(this.state.day)}
                  </div>
                </div>
              </div>
            )}

            {this.state.next === false && (
              <h3>
                <b className="title-text tf">Does It Balance?</b>
              </h3>
            )}

            <div className="w-100 d-flex flex-column align-items-center mt-4 mb-3">
              <div className="px-0 desc-text b20 pt-md-3 round position-relative">
                <div className="d-flex flex-row justify-content-center align-items-center top-bar tmln">
                  <div className="text-right text-center brd brd-left py-2">
                    RISK
                  </div>
                  <div className="font-weight-bold brd py-2 info-block text-center text-uppercase">
                    {window.slide4.data[this.props.index].name}
                  </div>
                  <div className="text-center text-gold brd brd-right py-2">
                    REWARD
                  </div>
                  <img src={pointer} alt="" className="property-pointer" />
                </div>
              </div>
            </div>

            <div className="row w-100 mt-3 timeline position-relative d-flex flex-column">
              {this.generateTimeline()}
            </div>

            <div className="mt-md-5">
              {this.state.next === false ? (
                <div class="d-md-flex d-none flex-row flex-wrap align-items-center">
                  <div class="">
                    <Link
                      to="/slide4/step2"
                      class="btn bg-white py-2 px-3  mx-2 d-inline-block d-flex flex-row align-items-center text-center justify-content-center  btn-next">
                      <small class="text-gold text-sm text-uppercase">
                        <b>Play again</b>
                      </small>
                      <div />
                    </Link>
                  </div>
                  <div class="">
                    <Link
                      class="btn bg-gold py-2 px-3  mx-2 d-inline-block d-flex flex-row align-items-center text-center justify-content-center  btn-next"
                      to="/slide4/step7">
                      <small class="text-white text-sm ">
                        <b>FINISH</b>
                      </small>
                    </Link>
                  </div>
                </div>
              ) : (
                <Button
                  back={false}
                  to={`/slide4/step4/${this.props.index}/${this.state.next}`}
                />
              )}
            </div>
          </div>
        </div>
        {this.state.next === false ? (
          <div class="navigation-buttons d-md-none d-flex flex-row">
            <div class="">
              <Link to="/slide4/step2" class="btn btn-sm-back">
                <small class="text-white text-uppercase font-weight-bold">
                  Play again
                </small>
              </Link>
            </div>
            <div class="d-flex flex-fill ">
              <Link
                to="/slide4/step7"
                class="btn btn-sm-next "
                href="/slide4/step7">
                <small class="text-white text-uppercase">
                  <b>Finish</b>
                </small>
              </Link>
            </div>
          </div>
        ) : (
          <ButtonSm
            back={false}
            to={`/slide4/step4/${this.props.index}/${this.state.next}`}
          />
        )}
      </>
    );
  }

  componentDidMount() {
    this.slide(false);

    let currentResult = window.slide4.results[this.props.index].filter(
      d => d.index === this.props.child
    )[0];
    let currentOption =
      window.slide4.data[this.props.index].steps[currentResult.index].options[
        currentResult.option
      ];

    let steps = window.slide4.results[this.props.index].filter(d => {
      let val = d.index <= currentResult.index;

      if (val) {
        val = d.option === currentResult.option;
      }
      return val;
    });
    // console.log("initial steps", window.slide4.results[this.props.index]);
    // window.slide4.results[this.props.index] = steps;
    // console.log("filtered steps", steps);

    this.setState({ next: currentOption.to });
    let additionalYears = 0;
    let yrs = window.slide4.data[this.props.index].years;
    if (typeof currentOption.years !== "undefined") {
      additionalYears = eval(currentOption.years);
    }

    console.log(currentOption);

    console.log("additionalYears : ", additionalYears);
    console.log("years : ", currentOption.years);

    window.slide4.data[this.props.index].years += additionalYears;

    setTimeout(() => {
      this.setState({
        day: this.state.day + additionalYears
      });
    }, 500);
  }

  slide(animate) {
    let day = this.state.day;
    let animTime = 2;
    if (animate === false) {
      animTime = 0;
    }
    TweenMax.to(".calendar-slider", animTime, {
      x: (1 + (2019 - day)) * 60,
      ease: Power4.easeInOut
    });
  }

  getCalendar(active) {
    let items = [];
    for (let i = 2019; i < 2050; i++) {
      items.push(
        <div
          key={i}
          className={"slider-item " + (i === active ? "active" : "")}>
          {i}
        </div>
      );
    }
    this.slide();
    return items;
  }

  generateTimeline = () => {
    let count = 1;

    let arr = [];
    let arr2 = [];

    window.slide4.results[this.props.index].map((item, i) => {
      let arrCount = 0;
      let arr2Count = 0;
      let d =
        window.slide4.data[this.props.index].steps[item.index].options[
          item.option
        ];
      d.risk.map(dt => {
        arrCount++;
        arr.push(
          <div
            className="d-flex flex-row align-items-center px-3 my-3 w-100 text-left position-relative"
            key={i + 10}>
            <img src={Carret} className="mr-2" alt="" />
            <div className="timeline-text">
              <small className="">{dt.title}</small>
            </div>
          </div>
        );
      });

      d.reward.map(dt => {
        arr2Count++;
        arr2.push(
          <div className=" d-flex flex-row align-items-center px-3 my-3 w-100 text-right text-gold position-relative">
            <div className="timeline-text">
              <small className="w-100">{dt.title}</small>
            </div>
            <img src={Carret} className="ml-2 flip" alt="" />
          </div>
        );
      });

      if (arrCount)
        arr.push(
          <>
            <div>
              <div className="bg-white align-self-center d-flex align-items-center justify-content-center step-indicator">
                <img
                  src={Ring}
                  className="position-absolute ring rotate"
                  alt=""
                />
                <h5 className="text-gold font-weight-bold m-0">{count}</h5>
              </div>
            </div>
            <div className="set-b position-relative" />
          </>
        );
      if (arr2Count) arr2.push(<div className="set-b" />);

      if (arr2Count || arrCount) count++;
    });

    return (
      <div className="d-flex flex-row w-100 m-0 ">
        {/* {arr.length !== 0 && ( */}
        <div className="py-1 w-50 position-relative timeline-section d-flex flex-column optw2">
          {arr}

          {arr.length !== 0 && <div className="set-end" />}
        </div>
        {/* )} */}
        {/* {arr2.length !== 0 && ( */}
        <div className="py-1 w-50 timeline-section position-relative d-flex flex-column">
          {arr2}
          {arr2.length !== 0 && <div className="set-end" />}
        </div>
        {/* )} */}
      </div>
    );
  };
}

export default S6;
