import React, { Component } from "react";
// images
import article1 from "../../../img/articles/slide3_article2.jpg";
import article2 from "../../../img/articles/slide3_article1_small.jpg";
import { Link } from "react-router-dom";
import Nav from "../../components/nav";

import twitter_icon from "../../../img/twitter_icon.png";
import facebook_icon from "../../../img/facebook_icon.png";
import Footer from "../../components/Footer";
import logo from "../../../img/logo.png";

class Article2 extends Component {
  state = {};
  render() {
    window.scrollTo(0, 0);
    return (
      <>
        <Nav />
        <div className="article-parent">
          <div className="article-header position-relative">
            <img
              src={article1}
              alt=""
              className="position-absolute h-100 w-100 article-header-image"
            />
            <div className="container d-flex flex-column flex-fill justify-content-between h-100 text-white">
              <div className="w-100 text-center mt-5">
                <img src={logo} alt="" className="main-logo" />
              </div>

              <div>
                <h1 className="font-weight-bold">
                  The 7 Rules of choosing your savings options
                </h1>

                <div className="text-white align-items-center mb-5">
                  <div className="font-weight-normal d-flex align-items-center flex-row">
                    <small className="font-weight-normal">23 March 2019</small>
                    <div className="d-inline-block mx-2">|</div>
                    <small className="font-weight-normal">Share</small>
                    <div className="d-flex flex-row ml-2">
                      <a
                        href="https://twitter.com/AmanaCapitalKE"
                        className="mr-3">
                        <img
                          src={twitter_icon}
                          alt=""
                          className="social-icon"
                        />
                      </a>

                      <a
                        href="https://www.facebook.com/amanacapital2003/"
                        className="mr-3">
                        <img
                          src={facebook_icon}
                          alt=""
                          className="social-icon"
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="container pb-5">
            <div className="text-muted mt-3 mb-5">
              <small>
                Home / Blog / The 7 Rules of choosing your savings options
              </small>
            </div>

            <div className="row">
              <div className="col-md-9">
                <div className="text-dark article-text">
                  <b className="mb-2 d-block">1. Beware Inflation</b>
                  <p>
                    No matter which savings options that you choose, remember
                    that your real rate of return is whatever you are earning
                    minus the rate of inflation. In Kenya, per official
                    statistics, annual inflation averages around 6%. This means
                    that if you are earning 10% on your savings, your actual
                    (real) returns are 4%. This is why you need to seek a rate
                    of return that is significantly above the rate of inflation,
                    or rather that protects your savings against or from the
                    destructive power of inflation. Any rate of return below 6%
                    in Kenya means that your money is actually loosing value
                    with each passing year – you can buy less and less with it
                    as the years pass. Remember also that inflation can and does
                    rise, especially in the context of an uncertain economy.
                    Plan accordingly.
                  </p>
                  <b className="mb-2 d-block">2. It’s too good to be true</b>
                  <p>
                    If it sounds too good to be true, then the rule is that it
                    almost certainly is. Beware get rich quick schemes that
                    promise astronomical rates of return. Remember that if it
                    was that easy to make money, everybody would be rich. There
                    are no short cuts to building up your savings. Anyone
                    offering you 20 or 30% returns must have a very unusual
                    reason for sharing their supposed “magic formula” with the
                    wider public. Kenya is very prone to pyramid schemes, which
                    endlessly re-package themselves in various guises, and which
                    are by their very nature designed to eventually implode.
                    Don’t be blinded by false promises or supposed investment
                    secrets – the truth is that there is no secret to wealth
                    other than steady saving.
                  </p>
                  <b className="mb-2 d-block">3. Don’t follow the herd</b>
                  <p>
                    We all remember well the craze for quail farming that swept
                    Kenya some years ago. Rumors spread like wildfire that quail
                    eggs were the new oil, and that the farming of quails was
                    the guaranteed way to make quick big money. Predictably huge
                    numbers of Kenyans rushed to invest their hard earned
                    savings into this scheme, fuelled on by the fear of missing
                    out and pacified by the knowledge that “everyone is doing
                    it”, and yet it was the very fact that “everyone was doing
                    it” that led to the inevitable over-supply in the market
                    that caused prices to collapse and far too many people to
                    loose way too much money. Following the herd is almost
                    always the worst possible reasons to put your money
                    somewhere. Make your own decisions based upon your own
                    reality and your own goals. That is the only way to put
                    yourself on the right path to wealth.
                  </p>
                  <b className="mb-2 d-block">4. Seek safety</b>
                  <p>
                    The word savings comes from the word safety. Your savings
                    are your money that has been put in a safe place, earning a
                    healthy rate of return, for the future. It is fundamentally
                    important that you put your money into savings vehicles that
                    are first and foremost safe homes for that money. This
                    safety comes from institutional strength and stability, as
                    well as from legal and regulatory protection. Many
                    institutions or people who seek to “keep your money safe”
                    are no safer than the old technique of keeping it under your
                    mattress. Do your homework and make sure that your money is
                    somewhere that is safe for the long run, come what may.
                  </p>
                  <b className="mb-2 d-block">5. Take your time</b>
                  <p>
                    Having a realistic timeline is the key to the execution of a
                    successful savings plan. At it’s heart savings is about
                    interest earned upon interest earned upon interest. Thus
                    there are only two key factors to the equation – are you
                    earning a sufficiently healthy return on your savings each
                    year, and are you giving your savings enough time, enough
                    years, for the interest to do it’s work. Setting yourself up
                    for the future is about having a realistic definition of the
                    future – if you are seeking to turn 1 million shillings into
                    10 in one year then you are probably seeking witchcraft.
                    Doing it in 10 years with a proper rate of return is,
                    however, much more achievable than you would think.
                  </p>
                  <b className="mb-2 d-block">6. Spread your risk</b>
                  <p>
                    This is the key rule to live by when making your savings
                    decisions. Every savings option has within it a different
                    balance between risk and reward, and always the rule is the
                    same – the higher the potential reward, the higher the risk
                    that you will loose everything. Seek therefore a savings
                    vehicle that gives you the best possible balance between
                    seeking higher rewards r returns and minimizing your risk.
                    The only way to do this is to seek a savings option that
                    spreads your money across a variety of different assets,
                    thus ensuring that some of your money is low risk, whilst
                    some of it is high reward. This is the only balance that wil
                    ultimately work in the long run.
                  </p>
                  <b className="mb-2 d-block">7. Ride the bumps</b>
                  <p>
                    Nothing in life is a given. Things happen, prices go up and
                    down, economies boom and bust, companies grow and collapse.
                    Remember that savings is a long term activity. Do not get
                    too caught up in the short term ups and downs that are the
                    inevitable consequence of economic life. Sometimes things
                    will be going well, sometimes they won’t. Don’t panic, the
                    fundamentals remain the same. A good healthy rate of return
                    over interest, multiplied by enough years, and you will
                    eventually complete your personal journey to wealth.
                  </p>
                </div>
              </div>
              <div className="col-md-3">
                <b>Related Articles</b>

                <Link to="/slide4/article1">
                  <div className="bg-light my-5 position-relative">
                    <img src={article2} alt="" className="w-100" />
                    <div className="red-text p-3">
                      <small>Weighing your savings options</small>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

export default Article2;
