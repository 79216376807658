import React, { Component } from "react";
import Input from "../../components/input";
import StepperItem from "../../components/stepperItem";
import Button from "../../components/button";
import logo from "../../../img/logo.png";

import leaf from "../../../img/leaf.png";
import HoverCard from "../../components/hoverCard";
import arrow_right from "../../../img/arrow_right.png";
import ButtonSm from "../../components/buttonSm";
import Input2 from "../../components/input2";

class Step7 extends Component {
 state = {
  needs: window.calc.needs,
  savings: window.calc.savings,
  wants: window.calc.wants,
  options: {
   ...window.store
  },
  limits: {
   needs: window.calc.earnings * 0.5,
   wants: window.calc.earnings * 0.3,
   savings: window.calc.earnings * 0.2
  }
 };
 render() {
  let mut =
   //  this.calculatePercentage(this.state.needs) > 50 ||
   this.calculatePercentage(this.state.wants) > 30 ||
   this.calculatePercentage(this.state.savings) > 20
    ? "mute-buttons"
    : "";

  return (
   <>
    <div className="slide d-flex flex-fill align-items-center justify-content-center text-white default-bg flex-column py-4">
     <HoverCard />
     <img src={logo} className="main-logo d-xsm" />
     <div className="mt-3">
      <div className="stepper-text-section text-center px-3 mt-5">
       <span className="desc-text font-weight-bold d-inline-block">
        Plan your living expenses. Try and keep them under 50%.
       </span>
      </div>
     </div>

     <div className="container ">
      <div className="row">
       <div
        className={
         "col-md-4 d-flex flex-column align-items-center text-center " +
         (this.calculatePercentage(this.state.needs) === 50 ? " limit " : "") +
         (this.calculatePercentage(this.state.needs) > 50 ? "error" : "")
        }
       >
        <div className="mt-5 position-relative">
         <img src={leaf} alt="" className="position-absolute leaf-icon-image" />
         <h5 className="text-gold text-error">
          {this.calculatePercentage(this.state.needs)}%{/* 50% */}
         </h5>
         <small className="font-weight-bold text-error">Needs</small>
        </div>
        <small className="text-gold font-weight-bold mb-3 mt-4 pt-1">
         TARGET : {this.state.limits.needs.toLocaleString()}{" "}
        </small>
        <div className="inputContainer my-0">
         <Input2
          value={"Kes " + this.state.needs.toLocaleString()}
          className="input-error text-error"
         />
        </div>
        <div className="my-4 d-inline-block">{this.getStepper("needs")}</div>
       </div>
      </div>
     </div>
    </div>
    <ButtonSm to="/slide1/step8" text="wants" onClick={this.persist} />
   </>
  );
 }

 update(type, amount, d, t) {
  let state = { ...this.state };
  state[type] += amount;
  state.options[type][d] += amount;
  this.setState({ ...state });
 }

 calculatePercentage(i) {
  return parseInt((i / window.calc.earnings) * 100);
 }

 getStepper(el) {
  return Object.keys(this.state.options[el]).map((d, i) => {
   return (
    <StepperItem
     key={i}
     title={d}
     onChange={(val, t) => this.update(el, val, d, t)}
     amount={this.state.options[el][d]}
    />
   );
  });
 }
 persist = () => {
  let { needs } = this.state;
  let { wants } = this.state;
  let { savings } = this.state;
  window.store = this.state.options;
  window.calc = { ...window.calc, needs, wants, savings };
  console.log(window.store, window.calc);
  localStorage.step1Options = JSON.stringify(window.store);
  localStorage.calc = JSON.stringify(window.calc);
  window.scrollTo(0, 0);
 };
}

export default Step7;
