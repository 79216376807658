import React, { Component } from "react";
import Input from "../../components/input";
import StepperItem from "../../components/stepperItem";
import Button from "../../components/button";
import logo from "../../../img/logo.png";
import ButtonSm from "../../components/buttonSm";

import { Link } from "react-router-dom";
import hexagon_fill from "../../../img/hexagon_fill.png";
import hexagon from "../../../img/hexagon.png";
import Modal from "../../components/modal";
import HoverCard from "../../components/hoverCard";

class Step10 extends Component {
 state = { modalVisible: false };
 render() {
  return (
   <>
    <div className="slide d-flex flex-fill align-items-center justify-content-center text-white default-bg flex-column pt-4">
     {/* <HoverCard /> */}
     <Modal show={this.state.modalVisible}>
      <div className=" hover-card-center position-relative">
       <div className="layer2 px-4 py-5">
        <i
         class="fas fa-times text-white position-absolute hover-card-clear"
         onClick={() => this.setState({ modalVisible: false })}
        />
        <small className="d-block">
         <b className="d-block">
          BOOK A FREE CONSULTATION WITH A WEALTH EXPERT
         </b>
         No sales pitch , no strings attached, just good and honest advice.
        </small>

        <input
         type="text"
         className="py-1 my-3 text-center text-bronze card-input d-flex flex-fill w-100 "
         placeholder="Enter your phone number"
        />

        <button className="btn w-100 text-center w-100 btn-primary">
         <small className="font-weight-bold text-white">Let's talk</small>
        </button>
       </div>
      </div>
     </Modal>

     <img src={logo} className="main-logo " />
     <div className="mt-3">
      <div className="stepper-text-section text-center px-0">
       <span className="desc-text font-weight-bold d-inline-block brxtyy">
        Great you are done with the first step!! Download your plan or book a
        free consultation to make this a reality.
       </span>
      </div>

      <div className="mt-3 text-center">
       <div
        to=""
        className={"d-inline-block m-2 text-white hexagon-link "}
        onClick={() => this.setState({ modalVisible: true })}
       >
        <div className="hexagon-fill d-flex justify-content-center align-items-center position-relative my-5">
         <img
          src={hexagon_fill}
          className="w-100 position-absolute hx-fill"
          alt=""
         />
         <img
          src={hexagon}
          className="w-100 position-absolute hx-outline"
          alt=""
         />
         <div className="p-3 hexagon-text text-center">
          <small className="text-s font-weight-bold">
           Book a free consultation
          </small>
          <div className="text-center mt-3">
           {/* <div className="checkbox_indicator bg-white d-inline-block" /> */}
          </div>
         </div>
        </div>
       </div>
      </div>
     </div>
     {/* buttons */}
    </div>

    <div class="navigation-buttons d-md-none d-flex flex-row">
     <div class="">
      <a
       download
       href="/php/7 STEPS TO WEALTH - BUDGET PLANNER V2.xlsx"
       class="btn bg-white "
      >
       <small class="text-gold text-sm text-uppercase">your budget</small>
      </a>
     </div>
     <div class="">
      <button class="btn bg-gold ">
       <small class="text-white text-sm text-uppercase">
        <b>DOWNLOAD YOUR PLAN</b>
       </small>
      </button>
     </div>
    </div>
   </>
  );
 }
}

export default Step10;
