import React, { Component } from "react";
import { Route, BrowserRouter as Router } from "react-router-dom";
import Nav from "../components/nav";

import consolation_spender from "../../img/icons/Results/consolation_spender.svg";
import Deal_hunter from "../../img/icons/Results/Deal_hunter.svg";
import Focused from "../../img/icons/Results/Focused.svg";
import Impulse_buyer from "../../img/icons/Results/Impulse_buyer.svg";
import keeping_itreal from "../../img/icons/Results/keeping_itreal.svg";
import list_maker from "../../img/icons/Results/list_maker.svg";
import livingitup from "../../img/icons/Results/livingitup.svg";
import long_arrow from "../../img/icons/Results/long_arrow.svg";
import necessity_spender from "../../img/icons/Results/necessity_spender.svg";

import back from "../../img/step7/back.svg";
import bulb from "../../img/step7/bulb.svg";
import fruit from "../../img/step7/fruit.svg";
import Fuse from "../../img/step7/Fuse.svg";
import magnifying from "../../img/step7/magnifying.svg";
import seed from "../../img/step7/seed.svg";
import thumbsup from "../../img/step7/thumbsup.svg";
import time from "../../img/step7/time.svg";
import wallet from "../../img/step7/wallet.svg";

import warren from "../../img/warren.png";
import peter from "../../img/peter.png";
import george from "../../img/George.png";
import dangote from "../../img/Dangote.png";

import S1 from "./s1";
import S2 from "./s2";
import S3 from "./s3";
import S5 from "./s5";
import Article1 from "./articles/article1";
import Article2 from "./articles/article2";
import S4 from "./s4";

window.slide7 = {
  scenarios: [
    {
      desc:
        "An investment you made is finally stabilising after years of being low. Everyone is cashing out while the rate is still high. What would your next step be?",
      year: "year=years*0.2",
      options: [
        {
          icon: wallet,
          title:
            "Follow the trend and invest in another company which is doing better.",
          year: "year+=1",
          outcome: {
            header: "1. Take your time",
            subHeader: "Investment is a long term game",
            quote: (
              <>
                <div>“The rich invest in time, the poor invest in money.”</div>
                Warren Buffet
              </>
            ),
            desc:
              "In 1989 Warren Buffet invested $600mn in  Gilette while it was struggling. While everyone sold shares when times were better . He held on till he could sell them for $5 billion to P&G",
            img: warren
          },
          award: {
            icon: consolation_spender,
            title: "DON’T PANIC",
            desc:
              "Unit trusts may fluctuate over months but over the course of the years they will generally rise. Stay the course reap the rewards"
          }
        },
        {
          icon: time,
          title:
            "Keep your investment after analyzing the reason for the improvement.",
          year: "year+=1",
          outcome: {
            header: "1. Take your time",
            subHeader: "Investment is a long term game",
            quote: (
              <>
                <div>“The rich invest in time, the poor invest in money.”</div>
                Warren Buffet
              </>
            ),
            desc:
              "In 1989 Warren Buffet invested $600mn in  Gilette while it was struggling. While everyone sold shares when times were better . He held on till he could sell them for $5 billion to P&G",
            img: warren
          },
          award: {
            icon: Focused,
            title: "KEEP CALM AND PROSPER",
            desc:
              "You aren’t swayed by fluctuations and that’s good. Keep your level head and you should weather any storms."
          }
        }
      ]
    },
    {
      desc:
        "There is a buzz around a new investment opportunity.  Analysts say you need to take the opportunity before it gets sold out.",
      year: "year=years*0.2",
      options: [
        {
          icon: Fuse,
          title:
            "See that the recommendations are positive and get your stake in before its too late",
          year: "year+=1",
          outcome: {
            header: "2. Know what you own",
            subHeader: "Understand the companies you invest with.",
            quote: (
              <>
                <div>“Know what you own and why you own it.”</div> Peter Lynch
              </>
            ),
            desc: (
              <>
                <div>
                  How did Peter Lynch get an amazing 2700% return on his
                  investments over 23 years?
                </div>
                <div>
                  By never investing with a company without spending time
                  understanding how they worked and if they could deliver.{" "}
                </div>
              </>
            ),
            img: peter
          },
          award: {
            icon: consolation_spender,
            title: "DON’T PANIC",
            desc:
              "Unit trusts may fluctuate over months but over the course of the years they will generally rise. Stay the course reap the rewards"
          }
        },
        {
          icon: magnifying,
          title:
            "Act cautiously and wait for more research and the experiences of others",
          year: "year+=1",
          outcome: {
            header: "2. Know what you own",
            subHeader: "Understand the companies you invest with.",
            quote: (
              <>
                <div>“Know what you own and why you own it.”</div> Peter Lynch
              </>
            ),
            desc: (
              <>
                <div>
                  How did Peter Lynch get an amazing 2700% return on his
                  investments over 23 years?
                </div>
                <div>
                  By never investing with a company without spending time
                  understanding how they worked and if they could deliver.{" "}
                </div>
              </>
            ),
            img: peter
          },
          award: {
            icon: Focused,
            title: "KEEP CALM AND PROSPER",
            desc:
              "You aren’t swayed by fluctuations and that’s good. Keep your level head and you should weather any storms."
          }
        }
      ]
    },
    {
      desc:
        "The government  has imposed a new tax on investments that looks like it will cut significantly into your returns . . ",
      year: "year=years*0.2",
      options: [
        {
          icon: thumbsup,
          title: "Accept it and pay the tax when you cash in on your returns",
          year: "year+=1",
          outcome: {
            header: "3. Watch the Taxman",
            subHeader: "Don’t evade tax, avoid it.",
            quote: (
              <>
                <div>
                  “The avoidances of taxes is the only intellectual pursuit that
                  carries any reward.”
                </div>
                J.M. Keynes
              </>
            ),
            desc: (
              <>
                <div>
                  One of the most important economists of our time is advocating
                  for all investors to spend time legally avoiding tax
                </div>
                <div>
                  Don’t get in trouble evading the tax you owe. Work with
                  experts to avoid paying too much tax.
                </div>
              </>
            ),
            img: george
          },
          award: {
            icon: consolation_spender,
            title: "DON’T PANIC",
            desc:
              "Unit trusts may fluctuate over months but over the course of the years they will generally rise. Stay the course reap the rewards"
          }
        },
        {
          icon: back,
          title:
            "Invest time with legal tax experts to see how you can reduce this",
          year: "year+=1",
          outcome: {
            header: "3. Watch the Taxman",
            subHeader: "Don’t evade tax, avoid it.",
            quote: (
              <>
                <div>
                  ““The avoidances of taxes is the only intellectual pursuit
                  that carries any reward.”
                </div>
                J.M. Keynes
              </>
            ),
            desc: (
              <>
                <div>
                  One of the most important economists of our time is advocating
                  for all investors to spend time legally avoiding tax
                </div>
                <div>
                  Don’t get in trouble evading the tax you owe. Work with
                  experts to avoid paying too much tax.
                </div>
              </>
            ),
            img: george
          },
          award: {
            icon: Focused,
            title: "KEEP CALM AND PROSPER",
            desc:
              "You aren’t swayed by fluctuations and that’s good. Keep your level head and you should weather any storms."
          }
        }
      ]
    },
    {
      desc:
        "You have an investment that is generating significant returns at a time when there increasing demands for capital from your other businesses",
      year: "year=years*0.2",
      options: [
        {
          icon: thumbsup,
          title:
            "Reinvest the money back into the investment to increase your returns",
          year: "year+=1",
          outcome: {
            header: "4. Compound your interest",
            subHeader: "Reinvest your earnings to earn exponentially",
            quote: (
              <>
                <div>
                  “My wealth has come from … lucky genes and compound interest”
                </div>
                Warren Buffet
              </>
            ),
            desc: (
              <>
                <div>
                  Warren Buffets empire is worth almost $500 billion. He made
                  more money in the last 10 years than in the last 70 by
                  compounding his interest
                </div>
                <div>
                  That’s the reason why Albert Einstein called compound interest
                  the 8th wonder of the world.
                </div>
              </>
            ),
            img: warren
          },
          award: {
            icon: consolation_spender,
            title: "DON’T PANIC",
            desc:
              "Unit trusts may fluctuate over months but over the course of the years they will generally rise. Stay the course reap the rewards"
          }
        },
        {
          icon: back,
          title: "Use the excess to sort out your other pressing concerns.",
          year: "year+=1",
          outcome: {
            header: "4. Compound your interest",
            subHeader: "Reinvest your earnings to earn exponentially",
            quote: (
              <>
                <div>
                  “My wealth has come from … lucky genes and compound interest”
                </div>
                Warren Buffet
              </>
            ),
            desc: (
              <>
                <div>
                  Warren Buffets empire is worth almost $500 billion. He made
                  more money in the last 10 years than in the last 70 by
                  compounding his interest
                </div>
                <div>
                  That’s the reason why Albert Einstein called compound interest
                  the 8th wonder of the world.
                </div>
              </>
            ),
            img: warren
          },
          award: {
            icon: Focused,
            title: "KEEP CALM AND PROSPER",
            desc:
              "You aren’t swayed by fluctuations and that’s good. Keep your level head and you should weather any storms."
          }
        }
      ]
    },
    {
      desc:
        "You have an investment that is generating significant returns at a time when there increasing demands for capital from your other businesses",
      year: "year=years*0.2",
      options: [
        {
          icon: seed,
          title:
            "Keep it in one place since there is no reason to change the formula",
          year: "year+=1",
          outcome: {
            header: "5. Diversify your investments",
            subHeader: "Spread your risk to protect yourself and grow",
            quote: (
              <>
                <div>
                  “We don’t keep money…We fully invest … and keep investing into
                  new areas”
                </div>
                Aliko Dangote
              </>
            ),
            desc: (
              <>
                <div>
                  The Dangote group is one of the largest conglomerates in
                  Africa worth $9 billion
                </div>
                <div>
                  By diversifying his investments in different industries.
                  Dangote has managed to stay afloat of changes in any one
                  particular industry
                </div>
              </>
            ),
            img: dangote
          },
          award: {
            icon: consolation_spender,
            title: "DON’T PANIC",
            desc:
              "Unit trusts may fluctuate over months but over the course of the years they will generally rise. Stay the course reap the rewards"
          }
        },
        {
          icon: fruit,
          title: "Open up new investment options to spread your risk.",
          year: "year+=1",
          outcome: {
            header: "5. Diversify your investments",
            subHeader: "Spread your risk to protect yourself and grow",
            quote: (
              <>
                <div>
                  “We don’t keep money…We fully invest … and keep investing into
                  new areas”
                </div>
                Aliko Dangote
              </>
            ),
            desc: (
              <>
                <div>
                  The Dangote group is one of the largest conglomerates in
                  Africa worth $9 billion
                </div>
                <div>
                  By diversifying his investments in different industries.
                  Dangote has managed to stay afloat of changes in any one
                  particular industry
                </div>
              </>
            ),
            img: dangote
          },
          award: {
            icon: Focused,
            title: "KEEP CALM AND PROSPER",
            desc:
              "You aren’t swayed by fluctuations and that’s good. Keep your level head and you should weather any storms."
          }
        }
      ]
    }
  ],
  results: [],
  total: 0,
  totals: {},
  years: 5,
  totalYears: 0
};

class Slide7Home extends Component {
  state = {};
  render() {
    return (
      <div>
        <Nav />
        <main className="main-page d-flex bg-dark flex-column">
          <div className="cover">
            <Route path="/(slide7|slide7/video)/" exact component={S1} />
            <Route path="/slide7/step2" component={S2} />
            <Route path="/slide7/step3" component={S3} />
            <Route path="/slide7/step4" component={S4} />
            <Route path="/slide7/step5" component={S5} />
            <Route path="/slide7/article1" component={Article1} />
            <Route path="/slide7/article2" component={Article2} />
          </div>
        </main>
      </div>
    );
  }
}

export default Slide7Home;
