import React, { Component } from "react";
import bg_ring from "../../img/icons/Results/bg_ring.svg";

class Range extends Component {
  state = {
    total: 0,
    percentage: this.props.percentage
  };
  render() {
    return (
      <div className="d-flex flex-row align-items-center mb-3 pb-1">
        <div className="d-flex justify-content-center flex-column align-items-center position-relative">
          <div className="d-flex justify-content-center flex-column align-items-center position-relative">
            <img src={bg_ring} alt="" className="align-self-center text-gold" />
            <img
              src={this.props.data.icon}
              alt=""
              className="position-absolute align-self-center icon text-dark"
            />
          </div>
          <div className=" habit-title text-center mt-1 text-white ln">
            <small className="goal-title">{this.props.data.title}</small>
          </div>
        </div>
        <div className="d-flex flex-fill ml-2 position-relative">
          <div className=" w-100">
            <div className="year-lowerLimit year-target">
              {this.props.data.lowerLimit.toLocaleString()}
            </div>
            <div className="year-upperLimit year-target">
              {this.props.data.upperLimit.toLocaleString()}
            </div>
            <input
              type="range"
              min="1"
              max="100"
              value={this.state.percentage}
              step="any"
              className="range-slider position-relative"
              style={{
                "background-image":
                  "-webkit-gradient(linear, left top, right top, " +
                  "color-stop(" +
                  this.state.percentage +
                  "%, #97a4b2 ), " +
                  "color-stop(" +
                  this.state.percentage +
                  "%, rgba(137, 142, 162, 0.3))" +
                  ")"
              }}
              onChange={e => {
                let value = parseInt(e.target.value);
                this.setState({
                  total: this.calculate(value),
                  percentage: value
                });
              }}
            />
            <div
              className="slider-indicator d-flex flex-column align-items-center"
              style={{
                left:
                  (this.state.percentage - 18 > 0
                    ? this.state.percentage + 18 > 100
                      ? 82
                      : this.state.percentage
                    : 18) + "%"
              }}>
              {/* <div className="pointer position-relative" /> */}
              <div className="pointer-text mt-1 text-gold font-weight-bold">
                {this.state.total.toLocaleString()}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  componentDidMount() {
    this.setState({ total: this.calculate(this.state.percentage) });
    console.log(this.state.percentage);
  }

  componentWillReceiveProps(props) {
    if (
      props.percentage !== undefined &&
      this.prevPercentage !== props.percentage
    ) {
      this.prevPercentage = props.percentage;
      let value = props.percentage / 100;
      let l = parseInt(this.props.data.lowerLimit);
      let u = parseInt(this.props.data.upperLimit);
      let total = (u - l) * value + l;
      this.setState({
        total,
        percentage: props.percentage
      });
    }
  }

  calculate(p) {
    let value = p / 100;
    let l = parseInt(this.props.data.lowerLimit);
    let u = parseInt(this.props.data.upperLimit);
    let total = (u - l) * value + l;
    this.props.updateTotal(total, p);
    return total;
  }
}

export default Range;
