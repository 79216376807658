import React, { Component } from "react";
import Input from "./input";
import badge from "../../img/icons/Ribbon.svg";

class Card extends Component {
 state = {};
 render() {
  return (
   <div className="card-fill text-center">
    <div className="text-gold mb-3 d-md-none">
     {this.props.current !== undefined && (
      <small className="font-weight-bold">
       {this.props.current}/{this.props.max}
      </small>
     )}
    </div>
    <div className="item pb-2 px-4 pt-0 text-white mx-0 mb-3  d-inline-block block-card position-relative mt-4 mx-md-2">
     <img src={badge} alt="" className="card-badge mb-3 d-inline-block" />
     <img src={this.props.icon} alt="" className="badge-icon-sm" />
     <small>
      <p className="card-title">
       <b> {this.props.title} </b>
      </p>
      <div className="card-subtitle">{this.props.subtitle}</div>
     </small>
     <div className="my-3">
      <Input
       getValue={value => {
        let a = {};
        value = parseInt(value);
        a[this.props.title] = value;
        this.props.getValue(a);
       }}
       value={this.calc()}
      />
     </div>
    </div>
   </div>
  );
 }
 componentDidMount() {
  // console.log(this.props.value);
 }
 calc = () => {
  let val = 0;
  if (this.props.value) {
   if (Object.keys(this.props.value).length > 0) {
    return parseInt(this.props.value[this.props.title]).toLocaleString();
   }
  }
  // console.log(val);
  return val;
 };
}

export default Card;
