import React, { Component } from "react";

class StepperItem extends Component {
 state = {
  amount: this.props.amount,
  value: this.props.amount
 };
 render() {
  return (
   <div className="d-flex flex-row align-items-center justify-content-end my-3">
    <span className="mr-3">
     <small className="text-sm ">
      <b>{this.props.title.toUpperCase()}</b>
     </small>{" "}
     <span className="text-gold ml-2 ">
      <input
       type="text"
       value={this.state.value.toLocaleString()}
       onChange={evt => {
        let { value } = evt.target;
        let v1 = value.replace(/,/g, "");
        if (v1 === "" || v1 === "NaN") v1 = "0";
        let v2 = parseInt(v1);

        this.setState({ value: v2 });
        if (this.props.getValue) this.props.getValue(v1);
       }}
       onBlur={() => {
        let val = this.state.value - this.state.amount;
        console.log(this.state.value, this.state.amount, val);
        this.props.onChange(val, true);
       }}
       className="stepper-input text-sm font-weight-bold text-center"
      />
      {/* <b>{this.state.amount.toLocaleString()}</b> */}
     </span>
    </span>
    <div className="card d-flex flex-row align-items-center text-dark">
     <button
      className="px-2 btn-add py-1 btn"
      onClick={() => this.update(-1000)}
     >
      <h6 className="m-0">-</h6>
     </button>
     <button
      className="px-2 btn-add py-1 btn"
      onClick={() => this.update(1000)}
     >
      <h6 className="m-0 text-error-muted">+</h6>
     </button>
    </div>
   </div>
  );
 }

 componentWillReceiveProps(props) {
  // console.log(props.amount);
  this.setState({
   amount: props.amount,
   value: props.amount
  });
 }

 update = a => {
  if (this.state.amount + a < 0) {
   // a = a - this.state.amount;
   a = 0;
  }

  this.props.onChange(a);
 };
}

export default StepperItem;
